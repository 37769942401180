import React, { useEffect, ChangeEvent } from "react";
import { InvoiceProps } from "../../types/invoiceProps";
import Input from "../../common/input";
import Select from "../../common/select";

type Props = {
  data: InvoiceProps;
  detailsFields: any[];
  setDetailsFields: React.Dispatch<React.SetStateAction<any>>;
};

type ArrayProps = {
  id: string;
  name: string;
  [key: string]: string | undefined;
};

const UnitArray: ArrayProps[] = [
  {
    id: "0",
    name: "cts",
  },
  {
    id: "1",
    name: "pcs",
  },
];

function LotTable({ data, detailsFields, setDetailsFields }: Props) {
  const formatter: Intl.NumberFormat = new Intl.NumberFormat("hi", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const handleFieldChange = async (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    let newValue = value;
    const newFields = [...detailsFields];
    newFields[index] = {
      ...newFields[index],
      [name as keyof InvoiceProps]: newValue,
    };
    setDetailsFields(newFields);
  };

  const fetchData = async () => {
    if (data.article_list?.length !== 0) {
      let articles = data.article_list;
      setDetailsFields(articles);
    }
  };

  useEffect(() => {
    if (data?.article_list?.length) {
      fetchData();
    }
  }, [data?.article_list?.length]);

  const { length: count } = detailsFields;

  if (count === 0) return <p>There are no data in the database.</p>;

  return (
    <>
      <div className="form-sample invoice-table">
        {/* <p className="card-description">
          <div className="page-header">
            <p className="page-title">Article List</p>
          </div>
        </p> */}

        <div className="table-responsive">
          <table className="table table-select">
            <thead>
              <tr>
                <th>Certificate No.</th>
                <th>Service Name</th>
                <th>Article ID</th>
                <th>Article Name</th>
                <th style={{ width: "30px" }}>Quantity</th>
                <th>Unit</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              {detailsFields &&
                detailsFields.map((field, index) => (
                  <tr key={index}>
                    <td>{field.certificate_number}</td>
                    <td style={{ width: "350px", whiteSpace: "break-spaces" }}>
                      {field.sertype_name}
                    </td>
                    <td>{field.article_id}</td>
                    <td>{field.article_name}</td>
                    <td style={{ width: "30px" }}>
                      <Input
                        type=""
                        name="quantity"
                        value={field.quantity}
                        label=""
                        onChange={(event) => handleFieldChange(index, event)}
                        error=""
                      />
                    </td>
                    <td>
                      <Select
                        name="unit"
                        value={field.unit}
                        label=""
                        options={UnitArray}
                        onChange={(event) => handleFieldChange(index, event)}
                        error=""
                      />
                    </td>
                    <td>{field.subtotal}</td>
                  </tr>
                ))}

              <tr>
                <td colSpan={6}> </td>
                <td>
                  <strong>
                    SGST(9%) {formatter.format(Number(data?.sgst))}
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan={6}> </td>
                <td>
                  <strong>
                    CGST(9%) {formatter.format(Number(data?.cgst))}
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan={6}> </td>
                <td>
                  <strong>
                    IGST(18%) {formatter.format(Number(data?.igst))}
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan={6}> </td>
                <td>
                  <strong>
                    Total GST {formatter.format(Number(data.totalgst))}
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan={6}> </td>
                <td>
                  <strong>
                    Total {formatter.format(Number(data.total_amount))}
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan={6}> </td>
                <td>
                  <strong>
                    Advance Amount{" "}
                    {formatter.format(Number(data.advance_amount))}
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan={6}> </td>
                <td>
                  <strong>
                    Discount Amount{" "}
                    {formatter.format(Number(data.discount_amount))}
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan={6}> </td>
                <td>
                  <strong>
                    Grand Total(9%){" "}
                    {formatter.format(Number(data?.grand_total))}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default LotTable;
