import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { paginate } from "../../utils/paginate";
import Pagination from "../../common/pagination";
import EmployTable from "./EmployTable";
import { toast } from "react-toastify";
import {
  deleteEmploy,
  getEmploys,
  statusEmploy,
} from "../../services/employeeService";
import { EmployProps } from "../../types/employeeProps";
import SearchBox from "../../common/searchBox";
import PageSize from "../../common/pageSize";
import Select from "./../../common/select";
import Filter from "./Filter";
import { BranchProps } from "../../types/branchProps";
import { getBranches } from "../../services/branchService";
import { RoleProps } from "../../types/roleProps";
import { getRoles } from "../../services/roleService";
import { DepartmentProps } from "../../types/departmentProps";
import { useSelector } from "react-redux";
import { UserState } from "../../types/userTypes";

type Props = {};

type ArrayProps = {
  id: string;
  name: string;
};

const departments: ArrayProps[] = [
  {
    id: "0",
    name: "Front Office",
  },
  {
    id: "1",
    name: "Back Office",
  },
  {
    id: "2",
    name: "Laboratory",
  },
  {
    id: "3",
    name: "Accounts",
  },
  {
    id: "4",
    name: "Administrator",
  },
  {
    id: "4",
    name: "Engraving",
  },
];

interface RedirecData {
  branch_id: string;
}

function EmployList({}: Props) {
  const [employs, setEmploys] = useState<EmployProps[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const location = useLocation();
  const [branches, setBranches] = useState<BranchProps[]>([]);
  const [roles, setRoles] = useState<RoleProps[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [branchQuery, setBranchQuery] = useState<string>("");
  const [departmentQuery, setDepartmentQuery] = useState<string>("");
  const [roleQuery, setRoleQuery] = useState<string>("");
  const user = useSelector((state: UserState) => state.userReducer);
  const branch = useSelector((state: any) => state.branchReducer);
  const params = useParams();

  const fetchBranch = async () => {
    try {
      const response = await getBranches();
      const newBranch = response.data.branch_list.map(
        ({
          branch_name: name,
          ...rest
        }: {
          branch_name: string;
          [key: string]: any;
        }) => ({
          name,
          ...rest,
        })
      );
      let branch_list = newBranch.map((branch: any) => {
        return {
          id: branch.id,
          name: branch.name,
        };
      });
      branch_list.sort((a: any, b: any) => a.name.localeCompare(b.name));

      setBranches(branch_list ?? []);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  const fetchRole = async (department_name: string) => {
    try {
      const response = await getRoles();
      const newRoles = response.data.role_list.map(
        ({
          role_name: name,
          ...rest
        }: {
          role_name: string;
          [key: string]: any;
        }) => ({
          name,
          ...rest,
        })
      );
      const filtered = newRoles.filter(
        (item: DepartmentProps) => item.department_name === department_name
      );
      setRoles(filtered ?? []);
    } catch (error) {
      console.error("Error fetching role list:", error);
    }
  };

  const fetchData = async (branch?: any) => {
    try {
      let redirecData: RedirecData;

      redirecData = {
        branch_id: branch?.id || "",
      };

      const response = await getEmploys(redirecData);
      setEmploys(response.data.employee_list ?? []);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  useEffect(() => {
    if (branch) fetchData(branch);
  }, [fetchData, branch]);
  useEffect(() => {
    fetchBranch();
  }, []);

  useEffect(() => {
    if (departmentQuery) fetchRole(departmentQuery);
  }, [departmentQuery]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleBranch = (query: string) => {
    setBranchQuery(query);
    setCurrentPage(1);
  };

  const handleDepartment = (query: string) => {
    setDepartmentQuery(query);
    setCurrentPage(1);
  };

  const handleRole = (query: string) => {
    setRoleQuery(query);
    setCurrentPage(1);
  };

  const handleDelete = async (employ: EmployProps) => {
    const originalDdata = employs;
    const data = originalDdata.filter((u) => u.id !== employ.id);
    setEmploys(data);
    setCurrentPage(1);

    try {
      const response = await deleteEmploy(employ.id);
      toast.success(response.data.message);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleStatus = async (employ: EmployProps) => {
    let status: string = employ.status === "Active" ? "Inactive" : "Active";

    try {
      const response = await statusEmploy(employ.id, status);
      toast.success(response.data.message);
      fetchData();
      // setCurrentPage(1);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    let filtered = employs;

    if (branchQuery) {
      // Filter by branch if a branch query is provided
      filtered = filtered.filter((m) =>
        m.employee_branch?.some((f: any) => f.branch_name === branchQuery)
      );
    }

    if (departmentQuery) {
      // Filter by department if a department query is provided
      filtered = filtered?.filter((m) =>
        m.department_name
          .toLowerCase()
          .startsWith(departmentQuery?.toLowerCase())
      );
    }

    if (searchQuery) {
      // Filter by name if a search query is provided
      filtered = filtered?.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    }

    if (roleQuery) {
      // Filter by name if a search query is provided
      filtered = filtered?.filter((m) =>
        m.role_name.toLowerCase().startsWith(roleQuery.toLowerCase())
      );
    }

    const employ = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: employ };
  };

  const { length: count } = employs;

  if (count === 0) return <p>There are no data in the database.</p>;

  const { totalCount, data: employ } = getPagedData();

  return (
    <>
      <div className="row">
        <div className="col-md-3 entries">
          <div
            style={{
              display: "grid",
              justifyContent: "space-between",
              alignItems: "center",
              gridTemplateColumns: "auto auto auto",
            }}
          >
            Showing
            <PageSize pageSize={pageSize} setPageSize={setPageSize} /> entries
          </div>
        </div>
        <div className="col-md-6 offset-md-3 search">
          <SearchBox value={searchQuery} onChange={handleSearch} />
        </div>
        {/* <div className="col-md-3 filters">
          <Select
            name=""
            label="Filter By"
            value={selectedFilter}
            options={FilterArray}
            onChange={(e) => setSelectedFilter(e.target.value)}
          />
        </div> */}
      </div>

      {user && user?.department_name === "Admin" && (
        <Filter
          branchQuery={branchQuery}
          branches={branches}
          onBranch={handleBranch}
          departmentQuery={departmentQuery}
          departments={departments}
          onDepartment={handleDepartment}
          roleQuery={roleQuery}
          roles={roles}
          onRole={handleRole}
        />
      )}

      <EmployTable
        employs={employ}
        onDelete={handleDelete}
        onStatus={handleStatus}
      />
      <div className="row">
        <div className="col-md-9">
          <h3 className="page-title">
            Showing {totalCount} entries in the Database
          </h3>
        </div>
        <div className="col-md-3">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default EmployList;
