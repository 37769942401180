import React, { useState, ChangeEvent, useEffect } from "react";
import { LotProps } from "../../types/lotProps";
import { ArticleDetailsProps } from "../../types/articleDetailsProps";
import { getArticleSubTypes } from "../../services/articleSubtypeService";
import findItemByProperty from "../../utils/findItem";
import { Link, useParams } from "react-router-dom";
import { getShapes } from "../../services/shapeMetalService";
import { ShapeMetalProps } from "./../../types/ShapeMetalProps";
import StatusLot from "./../../common/statusLot";
import { toast } from "react-toastify";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";
import { getServiceTypes } from "../../services/serviceTypeService";
import { statusEngraved, statusLot } from "../../services/lotService";
import { initialFields } from "./InitialFields";
import Engraving from "./../../common/engraving";

type Props = {
  data: LotProps;
  detailsFields: any[];
  setDetailsFields: React.Dispatch<React.SetStateAction<any>>;
};

interface Option {
  id: string;
  name: string;
}

function ArticleDetails({ data, detailsFields, setDetailsFields }: Props) {
  const [array, setArray] = useState<any>([]);
  const user = useSelector((state: UserState) => state.userReducer);
  const branch = useSelector((state: any) => state.branchReducer);
  const [open, setOpen] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>();

  const handleOpen = (index: number) => {
    setOpen(!open);
    setCurrentIndex(index);
    setArray(detailsFields[index]);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      if (
        currentIndex !== undefined &&
        currentIndex < detailsFields.length - 1
      ) {
        setCurrentIndex(currentIndex + 1);
        handleOpen(currentIndex + 1);
      } else {
        console.log("Reached the end of the array");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [currentIndex]);

  const handleEngraving = async (lot: any) => {
    let data = {
      lot_id: lot.lot_id,
      article_id: lot.id,
    };

    try {
      const response = await statusEngraved(data);
      toast.success(response?.data.message);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (data.article_list) {
        const articles = data.article_list;
        const filter = articles.filter(
          (article: any) => article.assignto_laboratory.toLowerCase() === "yes"
        );
        const updatedArray = await Promise.all(
          filter.map((item: any) => ({
            ...item,
            article_list: [],
          }))
        );
        setDetailsFields(updatedArray);
      }
    };

    fetchData();
  }, [data, setDetailsFields]);
  return (
    <>
      {/* Article Details */}
      {data.article_name !== "" && (
        <div className="form-sample">
          <p className="card-description">
            <div className="page-header">
              <p className="page-title">Article List</p>
              {/* <div>
                <Modal
                  data={data}
                  articlesSubtype={articlesSubtype}
                  shapes={shapes}
                  detailsFields={detailsFields}
                  setDetailsFields={setDetailsFields}
                />
              </div> */}
            </div>
          </p>
          <div className="table-responsive">
            <table className="table table-select">
              <thead style={{ height: "100px" }}>
                <tr>
                  <th>Certificate Number</th>

                  {data.article_name === "Diamond Jewelry" ||
                  data.article_name === "Labgrown Jewelry" ||
                  data.article_name === "Jewelry Screening" ? (
                    <>
                      <th style={{ width: "130px" }}>Type of Goods</th>
                      <th>Color</th>
                    </>
                  ) : null}

                  {data.article_name === "Gemstones" ? (
                    <>
                      <th>Measurement</th>
                      <th>Weight</th>
                      <th>Color</th>
                    </>
                  ) : null}

                  {data.article_name === "Loose Diamond" ||
                  data.article_name === "Labgrown Diamond" ? (
                    <>
                      <th>Measurement</th>
                      <th>Weight</th>
                      <th>Color</th>
                    </>
                  ) : null}

                  {data.article_name === "Gemstone Jewelry" ? (
                    <>
                      <th>Gemstone</th>
                      <th>Color</th>
                    </>
                  ) : null}

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {detailsFields &&
                  detailsFields.map((field, index) => (
                    <tr key={index}>
                      <td>{field.certificate_number}</td>

                      {data.article_name === "Diamond Jewelry" ||
                      data.article_name === "Jewelry Screening" ||
                      data.article_name === "Labgrown Jewelry" ? (
                        <>
                          <td style={{ width: "130px" }}>
                            {field.article_subtype_name}
                          </td>
                          <td>{field.design_no}</td>
                          <td>{field.color}</td>
                          <td>{field.clarity}</td>
                          <td>{field.finish}</td>
                        </>
                      ) : null}

                      {data.article_name === "Gemstones" ? (
                        <>
                          <td>{field.measurements}</td>
                          <td>{field.weight}</td>
                          <td>{field.color}</td>
                        </>
                      ) : null}

                      {data.article_name === "Loose Diamond" ||
                      data.article_name === "Labgrown Diamond" ? (
                        <>
                          <td>{field.measurements}</td>
                          <td>{field.weight}</td>
                          <td>{field.color}</td>
                        </>
                      ) : null}

                      {data.article_name === "Gemstone Jewelry" ? (
                        <>
                          <td>{field.gemstone}</td>
                          <td>{field.weight}</td>
                          <td>{field.color}</td>
                        </>
                      ) : null}

                      <td>
                        <Engraving article={field} onClick={handleEngraving} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default ArticleDetails;
