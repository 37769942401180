import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Form from "./form";
import Joi from "joi-browser";
import { TrackLotProps } from "../types/trackLotProps";
import { ToastContent, toast } from "react-toastify";
import { getTrack } from "../services/trackLotService";

type Props = {
  item: any;
  onClick: (data: any) => void;
};

interface Errors {
  [key: string]: string | undefined;
}

function TrackStatus({ item, onClick }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const initialData: TrackLotProps = {
    bin_no: "",
    remarks: "",
  };
  const [tracks, setTracks] = useState<any[]>();
  const [data, setData] = useState<TrackLotProps>(initialData);
  const [errors, setErrors] = useState<Errors>({});

  const PopulateData = async (lots_number: string) => {
    try {
      const response = await getTrack(lots_number);
      setTracks(response.data);
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const handleBin = () => {
    // Call the server
    console.log("Submitted", data);
    let result = { ...item, ...data };
    onClick(result);
    setOpen(!open);
  };

  const schema = {
    bin_no: Joi.optional().allow(""),
    remarks: Joi.optional().allow(""),
  };

  const { renderInputArea, renderInput, renderButton } = Form<TrackLotProps>({
    data,
    setData,
    errors,
    setErrors,
    schema,
    onSubmit: handleBin,
  });

  useEffect(() => {
    if (item.lots_number && open) {
      PopulateData(item.lots_number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.lot_number, open]);

  return (
    <>
      <div className="track">
        <Link
          to="#"
          style={{ cursor: "pointer" }}
          className="btn-edt"
          onClick={handleOpen}
          data-toggle="tooltip"
          data-placement="top"
          title="Track"
        >
          <i className="fa fa-truck" aria-hidden="true" />
        </Link>

        <form onSubmit={handleBin}>
          <div
            className={open ? "modal fade show" : "modal fade"}
            style={{ display: open ? "block" : "none" }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" onClick={handleClose}>
                    ×
                  </button>
                  <h4 className="modal-title">
                    Track Lot No: #{item?.lots_number}
                  </h4>
                </div>
                <div className="modal-body d-flex">
                  <div className="main-timeline">
                    {tracks?.map((d, i) => (
                      <div
                        className={`timeline ${
                          i % 2 === 0 ? "" : "timeline-1"
                        }`}
                        key={i}
                      >
                        <h4>{d.department_name}</h4>
                        <a href="#" className="timeline-content">
                          <div className="inner-content">
                            <h3 className="title">
                              Bin No:{" "}
                              {d.bin_no === null ? "Not Updated" : d.bin_no}
                            </h3>
                            <label>Employee: {d.employee_name}</label>
                            <label>Status: {d.status ? d.status : "New"}</label>
                            <label>Date & Time: {d.created_date}</label>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Update Bin Number</h4>
                      <div className="row">
                        {renderInput(
                          "bin_no",
                          'Bin Number <span class="text-danger">*</span>'
                        )}
                        {renderInputArea(
                          "remarks",
                          'Remarks <span class="text-danger">*</span>'
                        )}
                        <button
                          type="button"
                          onClick={handleBin}
                          className="btn btn-gradient-primary mr-2"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className={open ? "modal-backdrop " : ""}></div>
      </div>
    </>
  );
}

export default TrackStatus;
