import { InvoiceDetailProps } from "../../types/invoiceDetailsProps";

export const initialFields: InvoiceDetailProps[] = [
  {
    sertype_id: "",
    sertype_name: "",
    sertype_price: "",
    article_id: "",
    article_name: "",
    article_subtype_name: "",
    quantity: "",
    unit: "",
    subtotal: "",
    certificate_number: "",
  },
];
