import http from "./httpService";
import { apiUrl } from "../config.js";
import { StateProps } from "../types/stateProps";

const stateEndpoint = apiUrl + "/create_update_state";

// Get the state data

export async function getStates(): Promise<any> {
  try {
    return await http.get(apiUrl + "/state_list");
  } catch (error) {
    // Handle error
    console.error("Error retrieving states data:", error);
    // throw error;
  }
}
export async function getState(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_state", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving state data:", error);
    // throw error;
  }
}

//  Create and Update State

export function saveState(state: StateProps) {
  return http.post(stateEndpoint, state);
}

// Update the status of the state data

export async function statusState(id: string, status: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/change_state_status", {
      id: id,
      status: status,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving state data:", error);
    // throw error;
  }
}

// Delete the state data

export async function deleteState(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/delete_state", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving state data:", error);
    // throw error;
  }
}
