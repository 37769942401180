import React, { ChangeEvent } from "react";
import Select from "./select";

type Props = {
  roles: any[];
  value: string;
  onChange: (value: string) => void;
};

function FilterRole({ roles, value, onChange }: Props) {
  const handleInputChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(e.currentTarget.value);
  };
  return (
    <Select
      name="Role_name"
      label={`<i class="fa fa-building"> </i> Role Name`}
      options={roles}
      value={value}
      onChange={handleInputChange}
      error=""
    />
  );
}

export default FilterRole;
