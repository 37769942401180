import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Error from "../pages/Error/Error";
import {
  Account,
  Article,
  ArticleSubtype,
  Authorized,
  Branch,
  Country,
  Customer,
  Department,
  Employ,
  Lot,
  Panel,
  Laboratory,
  Photography,
  Role,
  Screening,
  State,
  Service,
  Tax,
} from "../dashboard/AdminDashboard/pages";
import { AdminDashboard } from "../dashboard";
import Logout from "../common/logout";
import {
  AuthorizedForm,
  BranchForm,
  CustomerForm,
  EmployForm,
  LotForm,
  PhotographyForm,
  ScreeningForm,
  LaboratoryForm,
} from "../forms";
import { Barcode, Certificate, Invoice, Jobcard, Voucher } from "../components";
import { InvoiceDetailList } from "../tables";

type Props = {};

function AdminRoute({}: Props) {
  return (
    <>
      <>
        <Routes>
          <Route path="*" element={<Error />} />
          <Route path="/admin_panel" element={<AdminDashboard />}>
            <Route index element={<Panel />} />
            <Route path="article" element={<Article />} />
            <Route path="article_subtype" element={<ArticleSubtype />} />
            <Route path="branch" element={<Branch />} />
            <Route path="branch/:id" element={<BranchForm />} />
            <Route path="country" element={<Country />} />
            <Route path="customer" element={<Customer />} />
            <Route path="customer/:id" element={<CustomerForm />} />
            <Route
              path="customer/authorize_person_list/:id"
              element={<Authorized />}
            />
            <Route
              path="customer/authorize_person_list/:id/:id"
              element={<AuthorizedForm />}
            />
            <Route path="department" element={<Department />} />
            <Route path="employee" element={<Employ />} />
            <Route path="employee/:id" element={<EmployForm />} />
            <Route path="accounts/:id" element={<Account />} />
            <Route path="accounts/:id/:id" element={<InvoiceDetailList />} />
            <Route
              path="accounts/:id/invoice/:lots_number"
              element={<Invoice />}
            />

            <Route path="lot/:id" element={<Lot />} />
            <Route path="lot/:id/:id" element={<LotForm />} />
            <Route path="lot/:id/voucher/:enc_lot_id" element={<Voucher />} />
            <Route path="lot/:id/barcode/:lots_number" element={<Barcode />} />
            <Route path="lot/:id/jobcard/:lots_number" element={<Jobcard />} />
            <Route
              path="lot/:id/certificate/:lots_number"
              element={<Certificate />}
            />
            <Route path="lot/:id/invoice/:lots_number" element={<Invoice />} />
            <Route path="laboratory" element={<Laboratory />} />
            <Route path="laboratory/:id" element={<LaboratoryForm />} />
            <Route path="photography" element={<Photography />} />
            <Route path="photography/:id" element={<PhotographyForm />} />
            <Route
              path="photography/certificate/:lots_number"
              element={<Certificate />}
            />
            <Route path="screening" element={<Screening />} />
            <Route path="screening/:id" element={<ScreeningForm />} />
            <Route path="state" element={<State />} />
            <Route path="service" element={<Service />} />
            <Route path="tax" element={<Tax />} />
            <Route path="role" element={<Role />} />
            <Route path="*" element={<Error />} />
          </Route>
          <Route path="/" element={<Navigate to="/admin_panel" replace />} />
          <Route
            path="/admin_login"
            element={<Navigate to="/admin_panel" replace />}
          />
          <Route
            path="/employee_login"
            element={<Navigate to="/admin_panel" replace />}
          />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </>
    </>
  );
}

export default AdminRoute;
