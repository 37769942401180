import React from "react";
import Input from "./input";
import { toast } from "react-toastify";

type Props = {
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
};

function PageSize({ pageSize, setPageSize }: Props) {
  const handleFieldChange = (value: string) => {
    if (value === "0") {
      toast.error("Value cannot be zero");
      return;
    }
    setPageSize(parseInt(value, 10));
  };

  return (
    <>
      <Input
        label=""
        name="pageSize"
        type="number"
        onChange={(event) => handleFieldChange(event.target.value)}
        value={pageSize.toString()}
      />
    </>
  );
}

export default PageSize;
