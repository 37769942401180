import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Error from "./../pages/Error/Error";
import {
  Authorized,
  Customer,
  Lot,
  Panel,
} from "../dashboard/FrontDeskDashboard/pages";
import { FrontDeskDashboard } from "../dashboard";
import Logout from "../common/logout";
import { AuthorizedForm, LotForm, CustomerForm } from "../forms";
import { Barcode, Invoice, Jobcard, Voucher } from "../components";

function FrontDeskRoute() {
  return (
    <>
      <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/frontdesk_panel" element={<FrontDeskDashboard />}>
          <Route index element={<Panel />} />
          <Route path="customer" element={<Customer />} />
          <Route path="customer/:id" element={<CustomerForm />} />
          <Route
            path="customer/authorize_person_list/:id"
            element={<Authorized />}
          />
          <Route
            path="customer/authorize_person_list/:id/:id"
            element={<AuthorizedForm />}
          />
          <Route path="lot/:id" element={<Lot />} />
          <Route path="lot/:id/:id" element={<LotForm />} />
          <Route path="lot/:id/voucher/:enc_lot_id" element={<Voucher />} />
          <Route path="lot/:id/barcode/:lots_number" element={<Barcode />} />
          <Route path="lot/:id/jobcard/:lots_number" element={<Jobcard />} />
          <Route path="lot/:id/invoice/:lots_number" element={<Invoice />} />

          <Route path="*" element={<Error />} />
        </Route>
        <Route path="/" element={<Navigate to="/frontdesk_panel" replace />} />
        <Route
          path="/admin_login"
          element={<Navigate to="/frontdesk_panel" replace />}
        />
        <Route
          path="/employee_login"
          element={<Navigate to="/frontdesk_panel" replace />}
        />

        <Route path="/logout" element={<Logout />} />
      </Routes>
    </>
  );
}

export default FrontDeskRoute;
