import React from "react";
import { Link } from "react-router-dom";
import Table from "../../common/table";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";
import { AccountantProps } from "../../types/accountanProps";
import Amount from "../../common/amount";
import { AccountProps } from "../../types/accountProps";

type Props = {
  accounts: AccountantProps[];
  onAccounts: (account: AccountProps) => void;
};

function AccountTable({ accounts, onAccounts }: Props) {
  const user = useSelector((state: UserState) => state.userReducer);

  const columns = [
    { path: "lots_number", label: "Lot Number" },
    {
      path: "lotDetails",
      label: "Lot Details",
      content: (account: AccountantProps) => (
        <>
          <p>No. of Articles: {account.no_of_articles}</p>
          <p>Service Type: {account.service_type}</p>
        </>
      ),
    },

    {
      path: "status",
      label: "Status",
    },
    { path: "created_date", label: "Created On" },
    {
      key: "Action",
      label: "Actions",
      content: (account: AccountantProps) => (
        <>
          <div className="d-flex justify-content-center align-items-center">
            {account.status === "In account" && (
              <>
                <Link
                  className="btn-edt"
                  to={account.id}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Edit"
                >
                  {user && user.department_name === "Admin" ? (
                    <i className="fa fa-edit" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-eye" aria-hidden="true"></i>
                  )}
                </Link>
              </>
            )}
            {account.status === "Invoice Generated" && (
              <>
                <Link
                  className="btn-edt"
                  to={`invoice/${account.lots_number}`}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="View Invoice"
                  state={{ type: "after" }}
                >
                  <i className="fa fa-info" aria-hidden="true"></i>
                </Link>
              </>
            )}
            {account.status !== "Invoice Generated" && (
              <>
                <Amount item={account} onAccounts={onAccounts} />
              </>
            )}
          </div>
        </>
      ),
    },
  ];

  return <Table data={accounts} columns={columns} />;
}

export default AccountTable;
