import React from "react";
import FilterDepartment from "../../common/filterDepartment";
import FilterBranch from "../../common/filterBranch";
import FilterLocation from "./../../common/filterLocation";
import { BranchProps } from "../../types/branchProps";
import FilterRole from "./../../common/filterRole";
import { RoleProps } from "../../types/roleProps";

type Props = {
  branches: BranchProps[];
  branchQuery: string;
  onBranch: (value: string) => void;
  departments: any[];
  departmentQuery: string;
  onDepartment: (value: string) => void;
  roles: RoleProps[];
  roleQuery: string;
  onRole: (value: string) => void;
};

function Filter({
  branchQuery,
  departmentQuery,
  roleQuery,
  branches,
  departments,
  roles,
  onBranch,
  onDepartment,
  onRole,
}: Props) {
  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <FilterBranch
            value={branchQuery}
            branches={branches}
            onChange={onBranch}
          />
        </div>
        {/* <div className="col-md-3">
          <FilterLocation />
        </div> */}
        <div className="col-md-3">
          <FilterDepartment
            value={departmentQuery}
            departments={departments}
            onChange={onDepartment}
          />{" "}
        </div>
        <div className="col-md-3">
          <FilterRole value={roleQuery} roles={roles} onChange={onRole} />
        </div>
      </div>
    </>
  );
}

export default Filter;
