import React, { useState, useEffect, useRef } from "react";
import { VoucherProps } from "../../types/VoucherProps";
import { Link, useParams } from "react-router-dom";
import { ToastContent, toast } from "react-toastify";
import { getVoucher } from "../../services/voucherServices";
import "./Voucher.css";
import { useReactToPrint } from "react-to-print";
import logo2 from "../../assets/images/logo-2.png";

type Props = {};
type InvoiceDocumentRef = React.MutableRefObject<HTMLDivElement | null>;
type HandlePrintFunction = () => void;

function Voucher({}: Props) {
  const [data, setData] = useState<VoucherProps>();
  const [display, setDisplay] = useState<string>("none");
  const params = useParams();

  const PopulateData = async (enc_lot_id: string) => {
    try {
      const response = await getVoucher(enc_lot_id);
      setData(response.data.vouchor);
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };
  const invoiceDocument: InvoiceDocumentRef = useRef<HTMLDivElement>(null);

  const handlePrintImvoice: HandlePrintFunction = useReactToPrint({
    content: () => invoiceDocument.current,
  });

  const handlePrint = async () => {
    await setDisplay("block");
    handlePrintImvoice();
    await setDisplay("none");
  };

  useEffect(() => {
    if (params.enc_lot_id) {
      PopulateData(params.enc_lot_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.enc_lot_id]);

  return (
    <>
      <div ref={invoiceDocument}>
        <div className="container-fluid page-body-wrapper">
          <header className="head-section">
            <div className="container">
              <div className="row">
                {/* Header */}

                <div className="head-main-box" style={{ width: "100%" }}>
                  <div className="in-head-box">
                    <img src={logo2} alt="logo" />
                  </div>
                  <div className="in-head-box">
                    <h3>Branch Copy</h3>
                  </div>
                  <div className="in-head-box text-center">
                    <img
                      src={data?.auth_profile_pic}
                      alt=""
                      style={{
                        width: "3rem",
                        borderRadius: "50%",
                        height: "3rem",
                      }}
                    />{" "}
                    <br />
                    <Link to="#">idtworldwide.com</Link>{" "}
                  </div>
                </div>

                {/* Customer Info */}
                <div className="table-box">
                  <table className="table table-1">
                    <thead>
                      <tr>
                        <th scope="col">
                          <div className="d-flex justify-content-between">
                            FRESH
                            {/* <input type="checkbox" name="" id="" /> */}
                          </div>
                        </th>
                        <th scope="col">RECHECK</th>
                        <th scope="col" colSpan={2}>
                          {data?.lots_number}
                        </th>
                        <th scope="col">CRITERIA</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Customer <br />
                          Dealer Type <br />
                          Telephone <br />
                          Contact Person
                        </td>
                        <td>
                          {data?.customer_name} <br />
                          {data?.customer_type} <br />
                          {data?.customer_mobile} <br />
                          {data?.employee_name}
                        </td>

                        <td colSpan={2}>
                          Dated -{" "}
                          {data?.created_date
                            ? new Date(data.created_date).toDateString()
                            : ""}
                          <br />
                          Customer Id- {data?.customer_id} <br />
                          Delivery Date -{" "}
                          {data?.delivery_date
                            ? new Date(data.delivery_date).toDateString()
                            : ""}
                          <br />
                          Service Type- {data?.service_type}
                        </td>
                        <td rowSpan={3}>{data?.criteria}</td>
                      </tr>
                    </tbody>
                  </table>
                  {/* Signature */}

                  <div className="row-2 d-flex justify-content-between">
                    <div className="row-1">
                      <h5> Received Items</h5>
                    </div>
                    <div className="row-1">
                      <label htmlFor="input" className="form-label">
                        Signature
                      </label>
                      <input type="text" className="form-control" id="input" />
                    </div>
                    <div className="row-1">
                      <label htmlFor="input" className="form-label">
                        Receive
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="input"
                        // value={data?.no_of_articles}
                      />
                    </div>

                    <div className="row-1">
                      <label htmlFor="input" className="form-label">
                        {data?.delivery_type}
                      </label>
                      <input type="text" className="form-control" id="input" />
                    </div>
                  </div>

                  {/* Item */}
                  <table className="table table-2">
                    <tbody>
                      <tr>
                        <th>#Item</th>
                        <td colSpan={3} style={{ width: "6rem" }}>
                          {data?.no_of_articles}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <b>Total Gold weight</b>-{" "}
                          {Math.round(Number(data?.total_gold_weight))}
                        </td>
                        <td style={{ width: "11rem" }}>
                          {" "}
                          <b>Carat wt</b>-{" "}
                          {Math.round(Number(data?.total_carat_weight))}
                        </td>
                        <td>
                          {" "}
                          <b>Attachments</b>- {data?.no_of_articles}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {/* Disclaimer */}
                  {/* <div className="foot-description">
                    <p>
                      <b>
                        Disclaimer: The Jewellery/gemstonelLoose Diamonds
                        submitted are not checked for any missing or damaged
                        stones at the front desk. Any such event will be
                        communicated to the concerned authority, once confirmed
                        by the Laboratory.
                      </b>{" "}
                      <br />
                      This Report is accepted by the individual/jeweller on the
                      following terms and conditions: This report contains the
                      characteristics of the gem/jewellery described hereinafter
                      it has been graded, tested, examined and analyzed by IDT
                      Gemological Laboratories Worldwide, carefully with
                      specialized instruments, under 10 magnification, including
                      fully corrected Triplet Loupe and Binocular Microscope,
                      Colour Master Stone Comparison, Electronic Carat Balance
                      &amp; ancillary instruments as necessary. This report is
                      considered null and void if any alteration(s) is/are made
                      to the gems/jewellery or to the report itself. In the
                      event of any claim by the client or any person for the
                      damage to the gems/jewellery, or damage resulting directly
                      or indirectly from the report, the liability of IDT shall
                      be limited to the amount it receives as the fee, in
                      connection with this report. Stone Weight and counts as
                      specified by the client. The parties recognize that the
                      opinions may reasonably vary regarding the characteristics
                      covered in this report. And hence IDT, or any member of
                      its staff, at any time, may not be held responsible for
                      any discrepancy which may result from the application of
                      the grading methods. This report shall be governed by
                      Indian judicial systems and all matters, disputes or
                      references to the arbitration shall be subject to
                      jurisdiction within India only. ID operates as an
                      independent laboratory and has no finances in the sale of
                      diamonds and/or jewellery items. This report has been
                      issued for the Retail Jewellery market.
                    </p>
                  </div> */}

                  {/* Font Box */}
                  <div className="foot-box">
                    For Terms and Conditions: Visit on{" "}
                    <p onClick={handlePrint}>
                      <Link to="#">idtworldwide.com</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div
          className="container-fluid page-body-wrapper"
          style={{ display: display }}
        >
          <header className="head-section">
            <div className="container">
              <div className="row">
                {/* Header */}

                <div className="head-main-box" style={{ width: "100%" }}>
                  <div className="in-head-box">
                    <img src={logo2} alt="logo" />
                  </div>
                  <div className="in-head-box">
                    <h3>Customer Copy</h3>
                  </div>
                  <div className="in-head-box text-center">
                    <img
                      src={data?.auth_profile_pic}
                      alt=""
                      style={{
                        width: "3rem",
                        borderRadius: "50%",
                        height: "3rem",
                      }}
                    />{" "}
                    <br />
                    <Link to="#">idtworldwide.com</Link>{" "}
                  </div>
                </div>

                {/* Customer Info */}
                <div className="table-box">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">FRESH</th>
                        <th scope="col">RECHECK</th>
                        <th scope="col" colSpan={2}>
                          {data?.lots_number}
                        </th>
                        <th scope="col">CRITERIA</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Customer <br />
                          Dealer Type <br />
                          Telephone <br />
                          Contact Person
                        </td>
                        <td>
                          {data?.customer_name} <br />
                          {data?.customer_type} <br />
                          {data?.customer_mobile} <br />
                          {data?.employee_name}
                        </td>

                        <td colSpan={2}>
                          Dated -{" "}
                          {data?.created_date
                            ? new Date(data.created_date).toDateString()
                            : ""}
                          <br />
                          Customer Id- {data?.customer_id} <br />
                          Delivery Date -{" "}
                          {data?.delivery_date
                            ? new Date(data.delivery_date).toDateString()
                            : ""}
                          <br />
                          Service Type- {data?.service_type}
                        </td>
                        <td rowSpan={3}>{data?.criteria}</td>
                      </tr>
                    </tbody>
                  </table>
                  {/* Signature */}

                  <div className="row-2 d-flex justify-content-between">
                    <div className="row-1">
                      <h5> Received Items</h5>
                    </div>
                    <div className="row-1">
                      <label htmlFor="input" className="form-label">
                        Signature
                      </label>
                      <input type="text" className="form-control" id="input" />
                    </div>
                    <div className="row-1">
                      <label htmlFor="input" className="form-label">
                        Receive
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="input"
                        // value={data?.no_of_articles}
                      />
                    </div>

                    <div className="row-1">
                      <label htmlFor="input" className="form-label">
                        {data?.delivery_type}
                      </label>
                      <input type="text" className="form-control" id="input" />
                    </div>
                  </div>

                  {/* Item */}
                  <table className="table table-2">
                    <tbody>
                      <tr>
                        <th>#Item</th>
                        <td colSpan={3} style={{ width: "6rem" }}>
                          {data?.no_of_articles}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <b>Total Gold weight</b>-{" "}
                          {Math.round(Number(data?.total_gold_weight))}
                        </td>
                        <td style={{ width: "11rem" }}>
                          {" "}
                          <b>Carat wt</b>-{" "}
                          {Math.round(Number(data?.total_carat_weight))}
                        </td>
                        <td>
                          {" "}
                          <b>Attachments</b>- {data?.no_of_articles}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {/* Disclaimer */}
                  {/* <div className="foot-description">
                    <p>
                      <b>
                        Disclaimer: The Jewellery/gemstonelLoose Diamonds
                        submitted are not checked for any missing or damaged
                        stones at the front desk. Any such event will be
                        communicated to the concerned authority, once confirmed
                        by the Laboratory.
                      </b>{" "}
                      <br />
                      This Report is accepted by the individual/jeweller on the
                      following terms and conditions: This report contains the
                      characteristics of the gem/jewellery described hereinafter
                      it has been graded, tested, examined and analyzed by IDT
                      Gemological Laboratories Worldwide, carefully with
                      specialized instruments, under 10 magnification, including
                      fully corrected Triplet Loupe and Binocular Microscope,
                      Colour Master Stone Comparison, Electronic Carat Balance
                      &amp; ancillary instruments as necessary. This report is
                      considered null and void if any alteration(s) is/are made
                      to the gems/jewellery or to the report itself. In the
                      event of any claim by the client or any person for the
                      damage to the gems/jewellery, or damage resulting directly
                      or indirectly from the report, the liability of IDT shall
                      be limited to the amount it receives as the fee, in
                      connection with this report. Stone Weight and counts as
                      specified by the client. The parties recognize that the
                      opinions may reasonably vary regarding the characteristics
                      covered in this report. And hence IDT, or any member of
                      its staff, at any time, may not be held responsible for
                      any discrepancy which may result from the application of
                      the grading methods. This report shall be governed by
                      Indian judicial systems and all matters, disputes or
                      references to the arbitration shall be subject to
                      jurisdiction within India only. ID operates as an
                      independent laboratory and has no finances in the sale of
                      diamonds and/or jewellery items. This report has been
                      issued for the Retail Jewellery market.
                    </p>
                  </div> */}

                  {/* Font Box */}
                  <div className="foot-box">
                    For Terms and Conditions: Visit on{" "}
                    <p onClick={handlePrint}>
                      <Link to="#">idtworldwide.com</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </div>
    </>
  );
}

export default Voucher;
