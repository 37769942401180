import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { paginate } from "../../utils/paginate";
import Pagination from "../../common/pagination";
import CustomerTable from "./CustomerTable";
import { toast } from "react-toastify";
import {
  deleteCustomer,
  getCustomers,
  statusCustomer,
} from "../../services/customerService";
import { CustomerProps } from "../../types/customerProps";
import auth from "../../services/authServices";
import SearchBox from "../../common/searchBox";
import PageSize from "./../../common/pageSize";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";

type Props = {};

interface RedirecData {
  employee_id: string;
  branch_id: string;
}

function CustomerList({}: Props) {
  const [customers, setCustomers] = useState<CustomerProps[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const user = useSelector((state: UserState) => state.userReducer);
  const branch = useSelector((state: any) => state.branchReducer);

  const fetchData = async (
    user: { [key: string]: string } | null,
    branch?: any
  ): Promise<void> => {
    try {
      let redirecData: RedirecData = {
        employee_id:
          user?.department_name === "Admin" ||
          user?.department_name === "Administrator"
            ? ""
            : user?.id || "",
        branch_id: user?.department_name === "Admin" ? "" : branch.id || "",
      };

      if (redirecData) {
        const response = await getCustomers(redirecData);
        setCustomers(response.data.customer_list ?? []);
      }
    } catch (error: any) {
      console.error("Error fetching customer list:", error);
    }
  };

  useEffect(() => {
    if (user && branch) {
      fetchData(user, branch);
    }
  }, [user, branch]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleDelete = async (customer: CustomerProps) => {
    const originalDdata = customers;
    const data = originalDdata.filter((u) => u.id !== customer.id);
    setCustomers(data);
    setCurrentPage(1);

    try {
      const response = await deleteCustomer(customer.id);
      toast.success(response.data.message);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleStatus = async (customer: CustomerProps) => {
    let status: string = customer.status === "Active" ? "Inactive" : "Active";

    try {
      const response = await statusCustomer(customer.id, status);
      toast.success(response.data.message);
      fetchData(user);
      // setCurrentPage(1);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    let filtered = customers;
    if (searchQuery)
      filtered = customers.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    const customer = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: customer };
  };

  const { length: count } = customers ?? [];

  if (count === 0) {
    return <p>There is no data in the database.</p>;
  }

  const { totalCount, data: customer } = getPagedData();

  return (
    <>
      <div className="row">
        <div className="col-md-3 entries">
          <div
            style={{
              display: "grid",
              justifyContent: "space-between",
              alignItems: "center",
              gridTemplateColumns: "auto auto auto",
            }}
          >
            Showing
            <PageSize pageSize={pageSize} setPageSize={setPageSize} /> entries
          </div>
        </div>
        <div className="col-md-6 offset-md-3 search">
          <SearchBox value={searchQuery} onChange={handleSearch} />
        </div>
      </div>

      <CustomerTable
        customers={customer}
        onDelete={handleDelete}
        onStatus={handleStatus}
      />
      <div className="row">
        <div className="col-md-9">
          <h3 className="page-title">
            Showing {totalCount} entries in the Database
          </h3>
        </div>
        <div className="col-md-3">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default CustomerList;
