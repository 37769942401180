import React, { useState, useEffect } from "react";
import { AccountantProps } from "../types/accountanProps";
import { AccountProps } from "../types/accountProps";
import Joi from "joi-browser";
import Form from "./form";
import { toast } from "react-toastify";
import { saveAccounts } from "../services/accountsService";

type Props = {
  item: AccountantProps;
  onAccounts: (account: AccountProps) => void;
};

interface Errors {
  [key: string]: string | undefined;
}

function Amount({ item, onAccounts }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const initialData: AccountProps = {
    lot_id: "",
    advance_amount: "",
    discount_amount: "",
  };
  const [accounts, setAccounts] = useState<AccountProps>();
  const [data, setData] = useState<AccountProps>(initialData);
  const [errors, setErrors] = useState<Errors>({});

  const handleOpen = (item?: AccountantProps) => {
    setAccounts({
      lot_id: item?.id,
      advance_amount: item?.advance_amount === null ? "" : item?.advance_amount,
      discount_amount:
        item?.discount_amount === null ? "" : item?.discount_amount,
    });
    setOpen(!open);
  };

  const handleClose = () => {
    setAccounts({
      lot_id: "",
      advance_amount: "",
      discount_amount: "",
    });
    setOpen(!open);
  };

  const handleAccounts = async (): Promise<void> => {
    // Call the server
    console.log("Submitted", data);
    try {
      onAccounts(data);
      let resultedData: AccountProps = Object.keys(data).reduce((obj, key) => {
        obj[key as keyof AccountProps] = "";
        return obj;
      }, {} as AccountProps);
      setData(resultedData);
      setOpen(!open);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    lot_id: Joi.optional().allow(""),
    advance_amount: Joi.optional().allow(""),
    discount_amount: Joi.optional().allow(""),
  };

  const { renderInput } = Form<AccountProps>({
    data,
    setData,
    errors,
    setErrors,
    schema,
    onSubmit: handleAccounts,
  });

  useEffect(() => {
    if (accounts) {
      setData(accounts);
    }
  }, [accounts]);

  return (
    <>
      <span
        style={{ cursor: "pointer" }}
        className="btn-edt"
        onClick={() => handleOpen(item)}
        data-toggle="tooltip"
        data-placement="top"
        title="Account Update"
      >
        <i className="fa fa-money" aria-hidden="true" />
      </span>

      <form onSubmit={handleAccounts}>
        <div
          className={open ? "modal fade show" : "modal fade"}
          style={{ display: open ? "block" : "none" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Update Accounts
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  {renderInput(
                    "advance_amount",
                    'Advance Amount <span class="text-danger">*</span>'
                  )}
                  {renderInput(
                    "discount_amount",
                    'Discount Amount <span class="text-danger">*</span>'
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={handleAccounts}
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className={open ? "modal-backdrop " : ""}></div>
    </>
  );
}

export default Amount;
