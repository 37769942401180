import http from "./httpService";
import { apiUrl } from "../config.js";

// Get the voucher data

export async function getVoucher(enc_lot_id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_vouchors", {
      enc_lot_id: enc_lot_id,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving voucher data:", error);
    // throw error;
  }
}
