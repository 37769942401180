// src/store/reducers/branchReducer.ts

const storedBranch = localStorage.getItem("branch");
const initialState: { id: string; name: string } =
  storedBranch !== null ? JSON.parse(storedBranch) : { id: "", name: "" };

const branchReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_BRANCH":
      localStorage.setItem("branch", JSON.stringify(action.payload));
      return action.payload;
    default:
      return state;
  }
};

export default branchReducer;
