import React, { useState, ChangeEvent, useEffect } from "react";
import { ArticleDetailsProps } from "../../types/articleDetailsProps";
import handleMultipleUpload from "../../utils/handleMultipleUpload";
import convertFilesToBase64 from "../../utils/convertFilesToBase64";
import Modal from "./Modal";
import { PhotographyProps } from "../../types/photographyProps";
import BulkModal from "./BulkModal";
import StatusLot from "../../common/statusLot";
import { toast } from "react-toastify";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";
import { statusPhotography } from "../../services/photographyService";
import NoImageAvailable from "../../assets/images/NoImageAvailable.jpg";
type Props = {
  data: PhotographyProps;
  detailsFields: any[];
  setDetailsFields: React.Dispatch<React.SetStateAction<any>>;
};

const MAX_COUNT: number = 1;

function ArticleDetails({ data, detailsFields, setDetailsFields }: Props) {
  const [array, setArray] = useState<any>([]);
  const user = useSelector((state: UserState) => state.userReducer);
  const branch = useSelector((state: any) => state.branchReducer);
  const [showImg, setShowImg] = useState(false);

  const handleStatus = async (lot: any) => {
    let data = {
      lot_id: lot.lot_id,
      lot_number: lot.lot_number,
      article_id: lot.id,
      employee_id: user?.department_name === "Admin" ? "" : user?.id,
      employee_name: user?.department_name === "Admin" ? "" : user?.name,
      employee_role: user?.department_name === "Admin" ? "" : user?.role_name,
      branch_id: user?.department_name === "Admin" ? "" : branch?.id,
      branch_name: user?.department_name === "Admin" ? "" : branch?.name,
      department_id:
        user?.department_name === "Admin" ? "" : user?.department_id,
      department_name:
        user?.department_name === "Admin" ? "" : user?.department_name,
      status: lot.status,
      remarks: lot.remarks,
    };

    try {
      const response = await statusPhotography(data);
      toast.success(response?.data.message);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleFileChange = async (
    i: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { name, files } = event.target;
    if (files) {
      let uploaded = handleMultipleUpload(files, MAX_COUNT);
      if (uploaded[1].status) {
        let imageFiles = uploaded[0];
        try {
          let result = await convertFilesToBase64(imageFiles);

          const updated = imageFiles.map((item, index) => ({
            name: item.name,
            pic: result[index],
          }));

          const newFields = [...detailsFields];
          try {
            newFields[i] = {
              ...newFields[i],
              [name as keyof ArticleDetailsProps]: updated,
            };
            setDetailsFields(newFields);
          } catch (error) {
            console.error(error);
          }
        } catch (error) {}
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (data.article_list) {
        const articles = data.article_list;
        const filter = articles.filter(
          (article: any) => article.assignto_photography.toLowerCase() === "yes"
        );
        const updatedArray = await Promise.all(
          filter.map((item: any) => ({
            ...item,
            article_pic: [],
          }))
        );
        setDetailsFields(filter);
      }
    };

    fetchData();
  }, [data, setDetailsFields]);

  useEffect(() => {
    // Check if any element in data has non-empty article_pic array
    const hasImages = detailsFields.some((item) =>
      item?.article_pic?.[0]?.pic?.includes(".jpg")
    );
    setShowImg(hasImages);
  }, [detailsFields]);

  return (
    <>
      {/* Article Details */}
      <div className="form-sample">
        <p className="card-description">
          <div className="page-header">
            <p className="page-title">Article List</p>
            <BulkModal
              detailsFields={detailsFields}
              setDetailsFields={setDetailsFields}
            />
          </div>
        </p>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Certificate Number</th>

                {data.article_name === "Diamond Jewelry" ||
                data.article_name === "Labgrown Jewelry" ||
                data.article_name === "Jewelry Screening" ? (
                  <>
                    <th style={{ width: "130px" }}>Type of Goods</th>
                    <th>Color</th>
                  </>
                ) : null}

                {data.article_name === "Gemstones" ? (
                  <>
                    <th>Measurement</th>
                    <th>Weight</th>
                    <th>Color</th>
                  </>
                ) : null}

                {data.article_name === "Loose Diamond" ||
                data.article_name === "Labgrown Diamond" ? (
                  <>
                    <th>Measurement</th>
                    <th>Weight</th>
                    <th>Color</th>
                  </>
                ) : null}

                {data.article_name === "Gemstone Jewelry" ? (
                  <>
                    <th>Gemstone</th>
                    <th>Color</th>
                  </>
                ) : null}

                <th>Image Name </th>
                {!showImg && <th>Upload Photo </th>}
                <th>View Photo </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {detailsFields &&
                detailsFields.map((field, index) => (
                  <tr key={index}>
                    <td>{field.certificate_number}</td>

                    {data.article_name === "Diamond Jewelry" ||
                    data.article_name === "Jewelry Screening" ||
                    data.article_name === "Labgrown Jewelry" ? (
                      <>
                        <td style={{ width: "130px" }}>
                          {field.article_subtype_name}
                        </td>
                        <td>{field.color}</td>
                      </>
                    ) : null}

                    {data.article_name === "Gemstones" ? (
                      <>
                        <td>{field.measurements}</td>
                        <td>{field.weight}</td>
                        <td>{field.color}</td>
                      </>
                    ) : null}

                    {data.article_name === "Loose Diamond" ||
                    data.article_name === "Labgrown Diamond" ? (
                      <>
                        <td>{field.measurements}</td>
                        <td>{field.weight}</td>
                        <td>{field.color}</td>
                      </>
                    ) : null}

                    {data.article_name === "Gemstone Jewelry" ? (
                      <>
                        <td>{field.gemstone}</td>
                        <td>{field.color}</td>
                      </>
                    ) : null}

                    <td>{field.article_image_name}</td>
                    {!showImg && (
                      <>
                        <td>
                          <div>
                            <div className="wrap">
                              <div className="file">
                                <div className="file__input" id="file__input">
                                  <input
                                    className="file__input--file"
                                    id="article_pic"
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    name="article_pic"
                                    multiple
                                    onChange={(event) =>
                                      handleFileChange(index, event)
                                    }
                                  />

                                  <label
                                    className="file__input--label"
                                    htmlFor="article_pic"
                                    data-text-btn="Upload"
                                  ></label>
                                </div>

                                {/* Article List Pic */}

                                {field.article_pic &&
                                  field.article_pic.map((item: any) => (
                                    <>
                                      <div className="file__value">
                                        <div className="file__value--text">
                                          {item.name}
                                        </div>
                                        <div
                                          className="file__value--remove"
                                          data-id={item.name}
                                        ></div>
                                      </div>
                                    </>
                                  ))}
                              </div>
                            </div>
                            {/* <Modal
                              field={field}
                              array={array}
                              setArray={setArray}
                            /> */}
                          </div>
                        </td>
                      </>
                    )}

                    <td>
                      {field.article_pic?.length > 0 ? (
                        <img
                          src={
                            field.article_pic[0].pic != ""
                              ? field.article_pic[0].pic.includes(".jpg")
                                ? field.article_pic[0].pic
                                : `data:image/jpeg;base64,${field.article_pic[0].pic} `
                              : NoImageAvailable
                          }
                          style={{ width: "80px", height: "80px" }}
                        />
                      ) : (
                        <img
                          src={NoImageAvailable}
                          style={{ width: "80px", height: "80px" }}
                        />
                      )}
                    </td>
                    <td>
                      <StatusLot
                        item={field}
                        onClick={handleStatus}
                        department="Photography"
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default ArticleDetails;
