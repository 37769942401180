import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../../../../common/form";
import "./RoleForm.css";
import { ToastContent, toast } from "react-toastify";
import { getRole, saveRole } from "../../../../services/roleService";
import { RoleProps } from "../../../../types/roleProps";
import { DepartmentType } from "../../../../data/Department";

type Props = {
  id: string;
  showSideNav: boolean;
  setShowSideNav: (show: boolean) => void;
};

interface Errors {
  [key: string]: string | undefined;
}
function RoleForm({ id, showSideNav, setShowSideNav }: Props) {
  const initialData: RoleProps = {
    id: "",
    department_id: "",
    department_name: "",
    status: "",
    role_name: "",
    created_date: "",
    updated_date: "",
  };
  const [data, setData] = useState<RoleProps>(initialData);
  const [errors, setErrors] = useState<Errors>({});

  const PopulateData = async (id: string) => {
    try {
      const dataId = id;
      if (dataId === "new") {
        let resultedData: RoleProps = Object.keys(data).reduce((obj, key) => {
          obj[key as keyof RoleProps] = "";
          return obj;
        }, {} as RoleProps);
        setData(resultedData);
      } else {
        const response = await getRole(dataId);
        setData(mapToViewModel(response.data));
      }
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  const mapToViewModel = ({ ...rest }: any): any => ({ ...rest });

  useEffect(() => {
    if (id) {
      PopulateData(id);
    }
  }, [id]);

  const doSubmit = async (): Promise<void> => {
    // Call the server
    console.log("Submitted", data);
    try {
      const response = await saveRole(data);
      if (response) {
        toast.success(response.data.message);
        let resultedData: RoleProps = Object.keys(data).reduce((obj, key) => {
          obj[key as keyof RoleProps] = "";
          return obj;
        }, {} as RoleProps);
        setData(resultedData);
        setShowSideNav(!showSideNav);
      }
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    id: Joi.optional().allow(""),
    department_id: Joi.optional().allow(""),
    department_name: Joi.string().required().label("Department Name"),
    role_name: Joi.string().required().label("Role Name"),
    status: Joi.optional().allow(""),
    created_date: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
  };

  const { renderInput, renderSelect, renderButton, handleSubmit } =
    Form<RoleProps>({
      data,
      setData,
      errors,
      setErrors,
      schema,
      onSubmit: doSubmit,
    });

  useEffect(() => {
    const buttonElement = document.querySelector(".forms-sample .btn");
    if (buttonElement) {
      buttonElement.classList.add("btn-gradient-primary");
    }
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit} className="forms-sample">
        {renderSelect(
          "department_name",
          'Department Name <span class="text-danger">*</span>',
          DepartmentType
        )}
        {renderInput(
          "role_name",
          'Role Name <span class="text-danger">*</span>'
        )}
        {renderButton("Save")}
        <p>&nbsp;</p>
      </form>
    </>
  );
}

export default RoleForm;
