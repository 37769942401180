import React from "react";
import { MdHome } from "react-icons/md";

type Props = {};

function Header({}: Props) {
  return (
    <>
      <div className="page-header ">
        <h3 className="page-title ">
          <span className="page-title-icon bg-gradient-primary text-white mr-2 ">
            <MdHome size={25} />
          </span>{" "}
          Add New Lot
        </h3>
        <nav aria-label="breadcrumb ">
          <ul className="breadcrumb "></ul>
        </nav>
      </div>
    </>
  );
}

export default Header;
