import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { StatusLotProps } from "../types/statusLotType";
import Form from "./form";
import Joi from "joi-browser";
import { UserState } from "../types/userTypes";
import { useSelector } from "react-redux";

type Props = {
  item: any;
  onClick: (data: any) => void;
  department?: string;
};

interface Errors {
  [key: string]: string | undefined;
}

type ArrayProps = {
  id: number;
  name: string;
  department?: string;
};

const StatusArray: ArrayProps[] = [
  {
    id: 0,
    name: "Taken Back",
    department: "Front Office Manager",
  },
  {
    id: 1,
    name: "Delivered",
    department: "Front Office Manager",
  },
  {
    id: 2,
    name: "Photography Completed",
    department: "Photography",
  },
  {
    id: 3,
    name: "Screening Completed",
    department: "Screening",
  },
  {
    id: 3,
    name: "Approved",
    department: "Lab Testing",
  },
  {
    id: 3,
    name: "Rejected",
    department: "Lab Testing",
  },
  {
    id: 3,
    name: "Takenback Photography",
    department: "Lab Testing",
  },
  {
    id: 3,
    name: "Takenback Screening",
    department: "Lab Testing",
  },
];

function StatusLot({ item, onClick, department }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const initialData: StatusLotProps = {
    status: "",
    remarks: "",
  };
  const [data, setData] = useState<StatusLotProps>(initialData);
  const [errors, setErrors] = useState<Errors>({});
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const user = useSelector((state: UserState) => state.userReducer);

  const badgeClass = (status: string | string[]) => {
    if (status === "New") {
      return "badge-gradient-success";
    } else {
      return "badge-gradient-warning";
    }
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const handleStatus = () => {
    // Call the server
    console.log("Submitted", data);
    let result = { ...item, ...data };
    if (result) {
      onClick(result);
      setOpen(!open);
    }
  };

  const schema = {
    status: Joi.optional().allow(""),
    remarks: Joi.optional().allow(""),
  };

  const { renderInputArea, renderSelect } = Form<StatusLotProps>({
    data,
    setData,
    errors,
    setErrors,
    schema,
    onSubmit: handleStatus,
  });

  useEffect(() => {
    if (department === "Front Office Manager") {
      setShowConfirmation(false);
    } else {
      if (
        item?.status === "New" ||
        item?.status === "Photography Completed" ||
        item?.status === "Screening Completed" ||
        item?.status === "Approved" ||
        item?.status === "Takenback Screening" ||
        item?.status === "Takenback Photography"
      ) {
        setShowConfirmation(true);
      }
    }
  }, [item, setShowConfirmation, department]);

  return (
    <>
      <span
        style={{ cursor: "pointer" }}
        className="btn-edt"
        onClick={handleOpen}
        data-toggle="tooltip"
        data-placement="top"
        title="Status Update"
      >
        {department === "Front Office Manager" ? (
          <i className="fa fa-hourglass-start " aria-hidden="true" />
        ) : (
          ((item.status === "New" ||
            item.status === "Photography Completed" ||
            item.status === "Screening Completed" ||
            item.status === "Approved" ||
            item.status === "Takenback Screening" ||
            item.status === "Takenback Photography") && (
            <i className="fa fa-check-circle  " aria-hidden="true" />
          )) || <i className="fa fa-hourglass-start " aria-hidden="true" />
        )}
      </span>

      <form onSubmit={handleStatus}>
        <div
          className={open ? "modal fade show" : "modal fade"}
          style={{ display: open ? "block" : "none" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Status
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  {showConfirmation && (
                    <div className="col-md-12">
                      <p>
                        {department === "Accounts Manager"
                          ? "Are you sure to create the invoice for this Lot ??"
                          : "Are you sure you want to accept this record ??"}
                      </p>
                    </div>
                  )}
                  {!showConfirmation && (
                    <>
                      <div className="col-md-12">
                        {" "}
                        {renderSelect(
                          "status",
                          'Change Status <span class="text-danger">*</span>',
                          StatusArray.filter(
                            (item) => item.department === department
                          )
                        )}
                      </div>
                      <div className="col-md-12">
                        {renderInputArea(
                          "remarks",
                          'Remarks <span class="text-danger">*</span>'
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={handleStatus}
                  className="btn btn-primary"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className={open ? "modal-backdrop " : ""}></div>
    </>
  );
}

export default StatusLot;
