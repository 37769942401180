import React from "react";
import { Link, useLocation } from "react-router-dom";
import Table from "../../common/table";
import Delete from "../../common/delete";
import Status from "../../common/status";
import { RoleProps } from "../../types/roleProps";

type Props = {
  roles: RoleProps[];
  onDelete: (role: RoleProps) => void;
  onForm: (role: RoleProps) => void;
  onStatus: (role: RoleProps) => void;
};

function RoleTable({ roles, onDelete, onForm, onStatus }: Props) {
  const location = useLocation();
  const columns = [
    {
      path: "role_name",
      label: "Role Name",
    },
    {
      path: "department_name",
      label: "Department Name",
    },
    {
      path: "created_date",
      label: "Created Date",
    },
    {
      path: "status",
      label: "Status",
      content: (role: RoleProps) => (
        <>
          <Status status={role.status} onClick={() => onStatus(role)} />
        </>
      ),
    },
    {
      key: "Action",
      label: "Action",
      content: (role: RoleProps) => (
        <>
          <Link className="btn-edt" to="#" onClick={() => onForm(role)}>
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </Link>
          <Delete onClick={() => onDelete(role)} />
        </>
      ),
    },
  ];

  return <Table data={roles} columns={columns} />;
}

export default RoleTable;
