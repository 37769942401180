type ArrayProps = {
  id: string;
  name: string;
};

export const DepartmentType: ArrayProps[] = [
  {
    id: "0",
    name: "Accounts",
  },
  {
    id: "1",
    name: "Admin",
  },
  {
    id: "2",
    name: "Back Office",
  },
  {
    id: "3",
    name: "Front Office",
  },
  {
    id: "4",
    name: "Laboratory",
  },
  {
    id: "5",
    name: "Photography",
  },
  {
    id: "6",
    name: "Sales",
  },
  {
    id: "7",
    name: "Screening",
  },
  {
    id: "8",
    name: "Administrator",
  },
  {
    id: "9",
    name: "Engraving",
  },
];
