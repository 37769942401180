import React from "react";
import { Link, useLocation } from "react-router-dom";
import Table from "../../common/table";
import Delete from "../../common/delete";
import Status from "../../common/status";
import { CustomerProps } from "../../types/customerProps";
import { useSelector } from "react-redux";
import { UserState } from "../../types/userTypes";

type Props = {
  customers: CustomerProps[];
  onDelete: (customer: CustomerProps) => void;
  onStatus: (customer: CustomerProps) => void;
};

function CustomerTable({ customers, onDelete, onStatus }: Props) {
  const location = useLocation();
  const user = useSelector((state: UserState) => state.userReducer);

  const columns = [
    {
      path: "customerDetails",
      label: "Customer Details",
      content: (customer: CustomerProps) => (
        <>
          <p>Name: {customer.name}</p>
          <p>Email: {customer.email}</p>
          <p>Mobile: {customer.mobile}</p>
          <p>Branch Name: {customer.branch_name}</p>
        </>
      ),
    },
    {
      key: "Stats",
      label: "Statistics",
      content: (customer: CustomerProps) => (
        <>
          <p>
            Authorized Person :{" "}
            <Link
              className="text text-info text-sm"
              to={`authorize_person_list/${customer.id}`}
            >
              {" "}
              {customer.no_of_auth}{" "}
            </Link>
          </p>
          <p>
            No. of Lots :{" "}
            <Link
              className="text text-info text-sm"
              to={`${
                process.env.NODE_ENV === "development"
                  ? `${
                      process.env.REACT_APP_localURL
                    }${location.pathname.substring(
                      0,
                      location.pathname.lastIndexOf("/")
                    )}/lot/${customer.id}`
                  : `${
                      process.env.REACT_APP_liveURL
                    }${location.pathname.substring(
                      0,
                      location.pathname.lastIndexOf("/")
                    )}/lot/${customer.id}`
              }`}
            >
              {" "}
              {customer.no_of_lots}{" "}
            </Link>
          </p>
        </>
      ),
    },

    {
      path: "status",
      label: "Status",
      content: (customer: CustomerProps) => (
        <>
          <Status status={customer.status} onClick={() => onStatus(customer)} />
        </>
      ),
    },
    { path: "created_date", label: "Created On" },
    {
      key: "Action",
      label: "Actions",
      content: (customer: CustomerProps) => (
        <>
          <Link className="btn-edt" to={customer.id}>
            {user && user.department_name === "Admin" ? (
              <i className="fa fa-edit" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-eye" aria-hidden="true"></i>
            )}
          </Link>
          {user && user.department_name === "Admin" && (
            <Delete onClick={() => onDelete(customer)} />
          )}
        </>
      ),
    },
  ];

  return <Table data={customers} columns={columns} />;
}

export default CustomerTable;
