import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { paginate } from "../../utils/paginate";
import Pagination from "../../common/pagination";
import RoleTable from "./RoleTable";
import { toast } from "react-toastify";
import { deleteRole, getRoles, statusRole } from "../../services/roleService";
import { RoleProps } from "./../../types/roleProps";
import SearchBox from "../../common/searchBox";
import PageSize from "../../common/pageSize";

type Props = {
  setId: React.Dispatch<React.SetStateAction<string>>;
  showSideNav: boolean;
  setShowSideNav: (show: boolean) => void;
};

function RoleList({ setId, showSideNav, setShowSideNav }: Props) {
  const [roles, setRoles] = useState<RoleProps[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const fetchData = async () => {
    try {
      const response = await getRoles();
      setRoles(response.data.role_list ?? []);
    } catch (error) {
      console.error("Error fetching role list:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleForm = (role: RoleProps) => {
    setId(String(role.id));
    setShowSideNav(!showSideNav);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleDelete = async (role: RoleProps) => {
    const originalDdata = roles;
    const data = originalDdata.filter((u) => u.id !== role.id);
    setRoles(data);
    setCurrentPage(1);

    try {
      const response = await deleteRole(role.id);
      toast.success(response.data.message);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleStatus = async (role: RoleProps) => {
    let status: string = role.status === "Active" ? "Inactive" : "Active";

    try {
      const response = await statusRole(role.id, status);
      toast.success(response.data.message);
      fetchData();
      // setCurrentPage(1);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    let filtered = roles;
    if (searchQuery)
      filtered = roles.filter((m) =>
        m.role_name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const role = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: role };
  };

  useEffect(() => {
    if (!showSideNav) {
      fetchData();
    }
  }, [showSideNav]);

  const { length: count } = roles;

  if (count === 0) return <p>There are no data in the database.</p>;

  const { totalCount, data: role } = getPagedData();

  return (
    <>
      <div className="row">
        <div className="col-md-3 entries">
          <div
            style={{
              display: "grid",
              justifyContent: "space-between",
              alignItems: "center",
              gridTemplateColumns: "auto auto auto",
            }}
          >
            Showing
            <PageSize pageSize={pageSize} setPageSize={setPageSize} /> entries
          </div>
        </div>
        <div className="col-md-6 offset-md-3 search">
          <SearchBox value={searchQuery} onChange={handleSearch} />
        </div>
      </div>

      <RoleTable
        roles={role}
        onDelete={handleDelete}
        onForm={handleForm}
        onStatus={handleStatus}
      />
      <div className="row">
        <div className="col-md-9">
          <h3 className="page-title">
            Showing {totalCount} entries in the Database
          </h3>
        </div>
        <div className="col-md-3">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default RoleList;
