import React from "react";
import { Link } from "react-router-dom";
import { MdHome, MdFilePresent } from "react-icons/md";
import { HiOutlineClipboardList } from "react-icons/hi";
import { SiNintendogamecube, SiWebpack } from "react-icons/si";
import { FaUserCog } from "react-icons/fa";
import { VscPersonAdd } from "react-icons/vsc";

type Props = {
  classes: string;
};

function BottomNavbar({ classes }: Props) {
  return (
    <>
      <nav className={classes}>
        <div className="container">
          <ul className="nav page-navigation">
            <li className="nav-item active">
              <Link className="nav-link" to="#">
                <MdHome className="menu-icon" size={25} />
                <span className="menu-title">Dashboard</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="#">
                <MdFilePresent className="menu-icon" size={25} />
                <span className="menu-title">Customers</span>
              </Link>
              <div className="submenu">
                <ul className="submenu-item">
                  <li className="nav-item">
                    <Link className="nav-link" to="customer">
                      Add New Customer
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="#">
                <HiOutlineClipboardList className="menu-icon" size={25} />
                <span className="menu-title">Lots</span>
              </Link>
              <div className="submenu">
                <ul className="submenu-item">
                  <li className="nav-item">
                    <Link className="nav-link" to="#">
                      Add New Lots
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="#">
                      Articles
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="#">
                      Photography
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="#">
                      Screening
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="#">
                      Grading Laboratory
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="#">
                      Generate Certificate
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="#">
                      Accounts
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            <li className="nav-item">
              <Link to="#" className="nav-link">
                <SiNintendogamecube className="menu-icon" size={25} />
                <span className="menu-title">Bin</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="#" className="nav-link">
                <SiWebpack className="menu-icon" size={25} />
                <span className="menu-title">Reports</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link to="employ" className="nav-link">
                <VscPersonAdd className="menu-icon" size={25} />
                <span className="menu-title">Empluyee</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="#">
                <FaUserCog className="menu-icon" size={25} />
                <span className="menu-title">Master</span>
              </Link>
              <div className="submenu">
                <ul className="submenu-item">
                  <li className="nav-item">
                    <Link className="nav-link" to="state">
                      Manage State
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="country">
                      Manage Country
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="branch">
                      Manage Branch
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="department">
                      Manage Department
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="#">
                      Branch Services
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default BottomNavbar;
