import http from "./httpService";
import { apiUrl } from "../config.js";
import { BranchProps } from "../types/branchProps";

const branchEndpoint = apiUrl + "/create_update_branch";

// Get the Branch Data

export async function getBranches(): Promise<any> {
  try {
    return await http.get(apiUrl + "/branch_list");
  } catch (error) {
    // Handle error
    console.error("Error retrieving branches data:", error);
    // throw error;
  }
}
export async function getBranch(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_branch", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving branch data:", error);
    // throw error;
  }
}

//  Create and Update Branch

export function saveBranch(branch: BranchProps) {
  return http.post(branchEndpoint, branch);
}

// Update the status of the Branch Data

export async function statusBranch(id: string, status: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/change_branch_status", {
      id: id,
      status: status,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving branch data:", error);
    // throw error;
  }
}

// Delete the Branch Data

export async function deleteBranch(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/delete_branch", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving branch data:", error);
    // throw error;
  }
}
