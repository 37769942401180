import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { paginate } from "../../utils/paginate";
import Pagination from "../../common/pagination";
import AdminLotTable from "./AdminLotTable";
import { toast } from "react-toastify";
import {
  getLots,
  statusAccept,
  statusLot,
  trackLot,
} from "../../services/lotService";
import { LotProps } from "../../types/lotProps";
import SearchBox from "../../common/searchBox";
import PageSize from "../../common/pageSize";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";
import EmptyLotsMessage from "../../common/emptyLotsMessage";
import NoLotMessage from "../../common/noLotMessage";

interface RedirecData {
  employee_id: string;
  branch_id: string;
  customer_id: string;
}

type ArrayProps = {
  id: string;
  name: string;
};

const TabPanelArray: ArrayProps[] = [
  {
    id: "1",
    name: "Request Lots",
  },
  {
    id: "2",
    name: "All Lots",
  },
];

function AdminLotList() {
  const [lots, setLots] = useState<LotProps[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const params = useParams();
  const user = useSelector((state: UserState) => state.userReducer);
  const branch = useSelector((state: any) => state.branchReducer);

  const fetchLots = useCallback(
    async (
      user: { [key: string]: string } | null,
      branch?: any
    ): Promise<void> => {
      try {
        let redirecData: RedirecData;

        redirecData = {
          employee_id:
            (user?.department_name === "Administrator" ||
            "Engraving" ||
            "Back Office"
              ? ""
              : user?.id) || "",
          branch_id: branch?.id || "",
          customer_id: params.id === "all" ? "" : params.id || "",
        };

        if (user?.department_name === "Admin") {
          redirecData = {
            employee_id: "",
            branch_id: "",
            customer_id: params.id === "all" ? "" : params.id || "",
          };
        }

        const response = await getLots(redirecData);
        setLots(response.data.lots_list ?? []);
      } catch (error: any) {
        console.error("Error fetching lot list:", error);
      }
    },
    [params.id]
  );

  useEffect(() => {
    if (user && branch) fetchLots(user, branch);
  }, [user, fetchLots, branch]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleBin = async (lot: LotProps) => {
    let data = {
      lot_id: lot?.id,
      lot_number: lot.lots_number,
      bin_number: lot.bin_no,
      article_id: "",
      employee_id: user?.department_name === "Admin" ? "" : user?.id,
      employee_name: user?.department_name === "Admin" ? "" : user?.name,
      employee_role: user?.department_name === "Admin" ? "" : user?.role_name,
      branch_id: user?.department_name === "Admin" ? "" : branch?.id,
      branch_name: user?.department_name === "Admin" ? "" : branch?.name,
      department_id:
        user?.department_name === "Admin" ? "" : user?.department_id,
      department_name:
        user?.department_name === "Admin" ? "" : user?.department_name,
      status: lot.status,
      remarks: lot.remarks,
    };

    try {
      const response = await trackLot(data);
      toast.success(response?.data.message);
      fetchLots(user, branch);
      setCurrentPage(1);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleDelete = async (lot: LotProps) => {
    // const originalDdata = lots;
    // const data = originalDdata.filter((u) => u.id !== lot.id);
    // setLots(data);
    // setCurrentPage(1);

    try {
      // const response = await deletelot(lot.id);
      // toast.success(response.data.message);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleStatus = async (lot: LotProps) => {
    console.log("lot", lot);
    let data = {
      lot_id: lot?.id,
      lot_number: lot.lots_number,
      article_id: "",
      employee_id: user?.department_name === "Admin" ? "" : user?.id,
      employee_name: user?.department_name === "Admin" ? "" : user?.name,
      employee_role: user?.department_name === "Admin" ? "" : user?.role_name,
      branch_id: user?.department_name === "Admin" ? "" : branch?.id,
      branch_name: user?.department_name === "Admin" ? "" : branch?.name,
      department_id:
        user?.department_name === "Admin" ? "" : user?.department_id,
      department_name:
        user?.department_name === "Admin" ? "" : user?.department_name,
      status: lot.status,
      remarks: lot.remarks,
    };

    try {
      const response = await statusLot(data);
      toast.success(response?.data.message);
      fetchLots(user, branch);
      setCurrentPage(1);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };
  const handleAccept = async (lot: LotProps) => {
    console.log("lot", lot);
    let data = {
      lot_id: lot?.id,
      status:
        lot.status === "Photography Manager Approval"
          ? "In Photography"
          : lot.status === "Laboratory Manager Approval"
          ? "Ïn Laboratory"
          : "In Screening",
    };

    try {
      const response = await statusAccept(data);
      toast.success(response?.data.message);
      fetchLots(user, branch);
      setCurrentPage(1);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    let filtered = lots;

    if (currentIndex === 0) {
      filtered = lots.filter(
        (lot) =>
          lot.status === "Photography Manager Approval" ||
          lot.status === "Laboratory Manager Approval" ||
          lot.status === "Screening Manager Approval"
      );
    }

    if (currentIndex === 1) {
      filtered = lots.filter(
        (lot) =>
          lot.status !== "Photography Manager Approval" &&
          lot.status !== "Laboratory Manager Approval" &&
          lot.status !== "Screening Manager Approval"
      );
    }

    if (searchQuery) {
      filtered = lots.filter((lot) =>
        lot.lots_number.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    }

    const lot = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: lot };
  };
  const { length: count } = lots ?? [];
  const { totalCount, data: lot } = getPagedData();

  const HandlePage = () => (
    <div className="row">
      <div className="col-md-9">
        <h3 className="page-title">
          Showing {totalCount} entries in the Database
        </h3>
      </div>
      <div className="col-md-3">
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );

  const TabContent = () => (
    <div className="tab-content" id="myTabContent">
      {TabPanelArray.map((tab, index) => (
        <div
          key={tab.id}
          className={`tab-pane fade lot ${
            currentIndex === index ? "show active" : ""
          }`}
          id={`#${tab.name}-tab-pane`}
          role="tabpanel"
          aria-labelledby={`#${tab.name}-tab`}
          tabIndex={0}
        >
          <AdminLotTable
            currentIndex={currentIndex}
            lots={lot}
            onAccept={handleAccept}
            onBin={handleBin}
            onDelete={handleDelete}
            onStatus={handleStatus}
          />
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    if (count === 0) {
      currentIndex === 0 ? setCurrentIndex(0) : setCurrentIndex(1);
    }
  }, [currentIndex, setCurrentIndex]);

  return (
    <>
      <div className="row">
        <div className="col-md-3 entries">
          <div
            style={{
              display: "grid",
              justifyContent: "space-between",
              alignItems: "center",
              gridTemplateColumns: "auto auto auto",
            }}
          >
            Showing
            <PageSize pageSize={pageSize} setPageSize={setPageSize} /> entries
          </div>
        </div>

        <div className="col-md-6 search">
          <SearchBox value={searchQuery} onChange={handleSearch} />
        </div>

        <div
          className="col-md-3 tabList"
          style={{ display: user?.department_name === "Admin" ? "none" : "" }}
        >
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            {TabPanelArray.map((tab, index) => (
              <>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      currentIndex === index ? "active" : ""
                    } `}
                    id={`${tab.name}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${tab.name}-tab-pane`}
                    type="button"
                    role="tab"
                    aria-controls={`${tab.name}-tab-pane`}
                    aria-selected="true"
                    // disabled={photography.length === 0 ? true : true}
                    onClick={() => setCurrentIndex(currentIndex === 0 ? 1 : 0)}
                  >
                    {tab.name}
                  </button>
                </li>
              </>
            ))}
          </ul>
        </div>
      </div>

      {currentIndex === 0 &&
        lots.filter(
          (lot) =>
            lot.status === "Photography Manager Approval" ||
            lot.status === "Laboratory Manager Approval" ||
            lot.status === "Screening Manager Approval"
        ).length === 0 && <EmptyLotsMessage />}
      {currentIndex === 1 &&
        lots.filter(
          (lot) =>
            lot.status !== "Photography Manager Approval" &&
            lot.status !== "Laboratory Manager Approval" &&
            lot.status !== "Screening Manager Approval"
        ).length === 0 && <NoLotMessage />}
      {currentIndex === 0 &&
        lots.filter(
          (lot) =>
            lot.status === "Photography Manager Approval" ||
            lot.status === "Laboratory Manager Approval" ||
            lot.status === "Screening Manager Approval"
        ).length !== 0 && (
          <>
            {" "}
            <TabContent /> <HandlePage />
          </>
        )}
      {currentIndex === 1 &&
        lots.filter(
          (lot) =>
            lot.status !== "Photography Manager Approval" &&
            lot.status !== "Laboratory Manager Approval" &&
            lot.status !== "Screening Manager Approval"
        ).length !== 0 && (
          <>
            {" "}
            <TabContent /> <HandlePage />
          </>
        )}
    </>
  );
}

export default AdminLotList;
