import React from "react";
import { MdHome, MdInfoOutline } from "react-icons/md";

type Props = {
  showSideNav: boolean;
  setShowSideNav: (show: boolean) => void;
  setId: React.Dispatch<React.SetStateAction<string>>;
};

function Header({ showSideNav, setShowSideNav, setId }: Props) {
  const addNew = () => {
    setShowSideNav(!showSideNav);
    setId("new");
  };
  return (
    <>
      <div className="page-header">
        <h3 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white mr-2">
            <MdHome size={25} />
          </span>
          Manage Tax
        </h3>
        <span className="add-btns" onClick={addNew}>
          Add
        </span>
      </div>
    </>
  );
}

export default Header;
