import React from "react";
import { MdAdd } from "react-icons/md";
import { useParams } from "react-router-dom";

type Props = {};

function Header({}: Props) {
  const params = useParams();

  return (
    <>
      <div className="page-header ">
        <h3 className="page-title ">
          <span className="page-title-icon bg-gradient-primary text-white mr-2 ">
            <MdAdd size={25} />
          </span>{" "}
          {params.id === "new" ? "Add New Invoice" : "View Invoice"}
        </h3>
        <nav aria-label="breadcrumb ">
          <ul className="breadcrumb "></ul>
        </nav>
      </div>
    </>
  );
}

export default Header;
