import React, { useState } from "react";
import logo2 from "../../assets/images/logo-2.png";
import {
  MdSearch,
  MdPowerSettingsNew,
  MdFormatLineSpacing,
  MdMenu,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserState } from "../../types/userTypes";

type Props = {
  showNavbar: boolean;
  setShowNavbar: (show: boolean) => void;
};

function Navbar({ showNavbar, setShowNavbar }: Props) {
  const [dropDown, setDropDown] = useState<Boolean>(false);
  let classes: string = "dropdown-menu navbar-dropdown";
  if (dropDown) classes += " show";

  const user = useSelector((state: UserState) => state.userReducer);

  return (
    <>
      <nav className="navbar top-navbar col-lg-12 col-12 p-0">
        <div className="container">
          <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <Link className="navbar-brand brand-logo" to="#">
              <img src={logo2} alt="logo" />
            </Link>
            <Link className="navbar-brand brand-logo-mini" to="#">
              <img src={logo2} alt="logo" />
            </Link>
          </div>
          <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <ul className="navbar-nav mr-lg-2">
              <li className="nav-item nav-search d-none d-lg-block">
                <div className="input-group">
                  <div
                    className="input-group-prepend hover-cursor"
                    id="navbar-search-icon"
                  >
                    <span className="input-group-text" id="search">
                      <MdSearch size={25} />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="navbar-search-input"
                    placeholder="Search "
                    aria-label="search"
                    aria-describedby="search"
                  />
                </div>
              </li>
            </ul>
            <ul className="navbar-nav navbar-nav-right">
              <li
                className="nav-item nav-profile dropdown"
                onClick={() => setDropDown(!dropDown)}
              >
                <Link
                  className="nav-link dropdown-toggle"
                  id="profileDropdown"
                  to="#"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="nav-profile-img">
                    {/* <img src="../assets/images/faces/face1.jpg" alt="image">  */}
                    <h5>{user.full_name.charAt(0)}</h5>
                    {/* <span className="availability-status online"></span> */}
                  </div>
                  <div className="nav-profile-text">
                    <p className="text-black">{user.full_name}</p>
                  </div>
                </Link>
                <div className={classes} aria-labelledby="profileDropdown">
                  <Link className="dropdown-item" to="#">
                    <i className="mdi mdi-cached mr-2 text-success"></i>{" "}
                    Activity Log{" "}
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item" to="/logout">
                    <i className="mdi mdi-logout mr-2 text-primary"></i> Signout{" "}
                  </Link>
                </div>
              </li>
              <div className="form-group tb-slct">
                {/* <select
                  className="form-control form-control-sm"
                  id="exampleFormControlSelect3"
                >
                  <option>Branch</option>
                  <option>Dwarka</option>
                  <option>Karolbag</option>
                  <option>Janakpuri</option>
                </select> */}
              </div>

              <li className="nav-item nav-settings d-none d-lg-flex">
                <Link className="nav-link" to="#">
                  <MdFormatLineSpacing size={25} />
                </Link>
              </li>

              <li className="nav-item nav-logout d-none d-lg-flex">
                <Link className="nav-link" to="/logout">
                  <MdPowerSettingsNew size={25} />
                </Link>
              </li>
            </ul>
            <button
              className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
              type="button"
              data-toggle="horizontal-menu-toggle"
              onClick={() => setShowNavbar(!showNavbar)}
            >
              <MdMenu size={25} />
            </button>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
