import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { paginate } from "../../utils/paginate";
import Pagination from "../../common/pagination";
import CountryTable from "./CountryTable";
import { toast } from "react-toastify";
import {
  deleteCountry,
  getCountries,
  statusCountry,
} from "../../services/countryService";
import { CountryProps } from "../../types/countryProps";
import SearchBox from "../../common/searchBox";
import PageSize from "../../common/pageSize";

type Props = {
  setId: React.Dispatch<React.SetStateAction<string>>;
  showSideNav: boolean;
  setShowSideNav: (show: boolean) => void;
};

function CountryList({ setId, showSideNav, setShowSideNav }: Props) {
  const [countries, setCountries] = useState<CountryProps[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const fetchData = async () => {
    try {
      const response = await getCountries();
      setCountries(response.data.country_list ?? []);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleForm = (country: CountryProps) => {
    setId(String(country.id));
    setShowSideNav(!showSideNav);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleDelete = async (country: CountryProps) => {
    const originalDdata = countries;
    const data = originalDdata.filter((u) => u.id !== country.id);
    setCountries(data);
    setCurrentPage(1);
    try {
      const response = await deleteCountry(country.id);
      toast.success(response.data.message);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleStatus = async (country: CountryProps) => {
    let status: string = country.status === "Active" ? "Inactive" : "Active";

    try {
      const response = await statusCountry(country.id, status);
      toast.success(response.data.message);
      fetchData();
      // setCurrentPage(1);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    let filtered = countries;
    if (searchQuery)
      filtered = countries.filter((m) =>
        m.country_name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const country = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: country };
  };

  useEffect(() => {
    if (!showSideNav) {
      fetchData();
    }
  }, [showSideNav]);

  const { length: count } = countries;

  if (count === 0) return <p>There are no data in the database.</p>;

  const { totalCount, data: country } = getPagedData();

  return (
    <>
      <div className="row">
        <div className="col-md-3 entries">
          <div
            style={{
              display: "grid",
              justifyContent: "space-between",
              alignItems: "center",
              gridTemplateColumns: "auto auto auto",
            }}
          >
            Showing
            <PageSize pageSize={pageSize} setPageSize={setPageSize} /> entries
          </div>
        </div>
        <div className="col-md-6 offset-md-3 search">
          <SearchBox value={searchQuery} onChange={handleSearch} />
        </div>
      </div>

      <CountryTable
        countries={country}
        onDelete={handleDelete}
        onForm={handleForm}
        onStatus={handleStatus}
      />
      <div className="row">
        <div className="col-md-9">
          <h3 className="page-title">
            Showing {totalCount} entries in the Database
          </h3>
        </div>
        <div className="col-md-3">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default CountryList;
