import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { paginate } from "../../utils/paginate";
import Pagination from "../../common/pagination";
import InvoiceTable from "./InvoiceTable";
import { toast } from "react-toastify";
import SearchBox from "../../common/searchBox";
import PageSize from "../../common/pageSize";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";
import { getInvoices } from "../../services/invoiceService";
import { InvoiceProps } from "../../types/invoiceProps";
import { CSVLink } from "react-csv";

interface RedirecData {
  employee_id: string;
  branch_id: string;
}

function InvoiceList() {
  const [invoices, setInvoices] = useState<InvoiceProps[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const params = useParams();
  const user = useSelector((state: UserState) => state.userReducer);
  const branch = useSelector((state: any) => state.branchReducer);

  const fetchInvoices = useCallback(
    async (
      user: { [key: string]: string } | null,
      branch?: any
    ): Promise<void> => {
      try {
        let redirecData: RedirecData;

        redirecData = {
          employee_id: "",
          branch_id: branch?.id || "",
          // customer_id: params.id === "all" ? "" : params.id || "",
        };

        if (user?.department_name === "Admin") {
          redirecData = {
            employee_id: "",
            branch_id: "",
            // customer_id: params.id === "all" ? "" : params.id || "",
          };
        }

        const response = await getInvoices(redirecData);
        setInvoices(response.data.invoice_list ?? []);
      } catch (error: any) {
        console.error("Error fetching invoice list:", error);
      }
    },
    [params.id]
  );

  useEffect(() => {
    if (user && branch) fetchInvoices(user, branch);
  }, [user, fetchInvoices, branch]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    let filtered = invoices;
    if (searchQuery)
      filtered = invoices.filter((m) =>
        m.invoice_id.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const invoice = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: invoice };
  };

  const { length: count } = invoices ?? [];

  if (count === 0) {
    return <p>There is no data in the database.</p>;
  }

  const flattenedData = invoices
    .map((invoice) => {
      const flattenedItems = invoice.item_list.map((item: any) => {
        return {
          ...invoice,
          ...item,
        };
      });

      return flattenedItems;
    })
    .flat();

  const { totalCount, data: invoice } = getPagedData();

  return (
    <>
      <div className="row">
        <div className="col-md-3 entries">
          <div
            style={{
              display: "grid",
              justifyContent: "space-between",
              alignItems: "center",
              gridTemplateColumns: "auto auto auto",
            }}
          >
            Showing
            <PageSize pageSize={pageSize} setPageSize={setPageSize} /> entries
          </div>
        </div>
        <div className="col-md-6 search">
          <SearchBox value={searchQuery} onChange={handleSearch} />
        </div>
        <div className="col-md-3 my-3">
          <CSVLink
            data={flattenedData}
            filename={"invoices.csv"}
            className="badge badge-outline-success"
          >
            Download Invoices
          </CSVLink>
        </div>
      </div>

      <div className="invoice">
        <InvoiceTable invoices={invoice} />
      </div>

      <div className="row">
        <div className="col-md-9">
          <h3 className="page-title">
            Showing {totalCount} entries in the Database
          </h3>
        </div>
        <div className="col-md-3">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default InvoiceList;
