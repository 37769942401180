import React, { useState } from "react";
import Joi from "joi-browser";
import "./AdminForm.css";
import Form from "../../common/form";
import auth from "../../services/authServices";
import { toast } from "react-toastify";
import { AdminFormProps } from "../../types/adminFormProps";
import { apiUrl } from "../../config";

type Props = {};

interface Errors {
  [key: string]: string | undefined;
}

function AdminForm({}: Props) {
  const initialData: AdminFormProps = { username: "", password: "" };
  const [data, setData] = useState<AdminFormProps>(initialData);
  const [errors, setErrors] = useState<Errors>({});
  const adminEndpoint = apiUrl + "/signin";

  const doSubmit = async () => {
    // Call the server
    console.log("Submitted", data);
    try {
      await auth.login(
        adminEndpoint,
        {
          username: data.username,
          password: data.password,
        },
        "username",
        "password"
      );
      let redirectUrl = process.env.REACT_APP_liveURL as string;
      if (process.env.NODE_ENV === "development") {
        redirectUrl = process.env.REACT_APP_localURL as string;
      }
      window.location.href = redirectUrl; // No need for type assertion
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
  };

  const { renderInput, renderButton, handleSubmit } = Form<AdminFormProps>({
    data,
    setData,
    errors,
    setErrors,
    schema,
    onSubmit: doSubmit,
  });

  return (
    <>
      {/* Login Form */}
      <form className="logine" onSubmit={handleSubmit}>
        {renderInput("username", "Username")}
        {renderInput("password", "Password", "password")}
        {renderButton("Login")}
        <p>&nbsp;</p>
      </form>
    </>
  );
}

export default AdminForm;
