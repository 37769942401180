import React from "react";
import Header from "./Header";
import Body from "./Body";
import Customer from "./Customer";

type Props = {};

function Panel({}: Props) {
  return (
    <>
      <div className="content-wrapper">
        <Header />
        {/* <Body />
        <Customer /> */}
        <div className="d-flex justify-content-center align-items-center flex-column vh-100">
          <h1>Welcome you on Sales </h1>
        </div>
      </div>
    </>
  );
}

export default Panel;
