import React, { ChangeEvent } from "react";
import { BranchProps } from "../types/branchProps";
import Select from "./select";

type Props = {
  departments: any[];
  value: string;
  onChange: (value: string) => void;
};

function FilterDepartment({ departments, value, onChange }: Props) {
  const handleInputChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(e.currentTarget.value);
  };
  return (
    <Select
      name="department_name"
      label={`<i class="fa fa-sitemap"> </i> Department Name`}
      options={departments}
      value={value}
      onChange={handleInputChange}
      error=""
    />
  );
}

export default FilterDepartment;
