import React, { ChangeEvent } from "react";
import Select from "./select";

type Props = {
  branches: any[];
  value: string;
  onChange: (value: string) => void;
};

function FilterBranch({ branches, value, onChange }: Props) {
  const handleInputChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(e.currentTarget.value);
  };
  return (
    <Select
      name="branch_name"
      label={`<i class="fa fa-building"> </i> Branch Name`}
      options={branches}
      value={value}
      onChange={handleInputChange}
      error=""
    />
  );
}

export default FilterBranch;
