import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { LotProps } from "../../types/lotProps";
import { ArticleDetailsProps } from "../../types/articleDetailsProps";
import { getArticleSubTypes } from "../../services/articleSubtypeService";
import Input from "./../../common/input";
import Select from "../../common/select";
import findItemByProperty from "../../utils/findItem";
import Modal from "./Modal";
import { Link, useParams } from "react-router-dom";
import { getShapes } from "../../services/shapeMetalService";
import { ShapeMetalProps } from "./../../types/ShapeMetalProps";
import StatusLot from "./../../common/statusLot";
import { toast } from "react-toastify";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";
import MultipleSelect from "../../common/multipleSelect";
import { getServiceTypes } from "../../services/serviceTypeService";
import { statusLot } from "../../services/lotService";
import { initialFields } from "./InitialFields";
import InputCustom from "../../common/inputCustom";

type Props = {
  data: LotProps;
  detailsFields: any[];
  setDetailsFields: React.Dispatch<React.SetStateAction<any>>;
};

interface Option {
  id: string;
  name: string;
}

type ArrayProps = {
  id: string;
  name: string;
  [key: string]: string | undefined;
};

const ServiceArray: ArrayProps[] = [
  {
    id: "0",
    name: "Fresh",
  },
  {
    id: "1",
    name: "Recheck",
  },
  {
    id: "2",
    name: "First Recheck",
  },
  {
    id: "3",
    name: "Consultation",
  },
];

function ArticleDetails({ data, detailsFields, setDetailsFields }: Props) {
  const [articlesSubtype, setArticlesSubtype] = useState<
    { id: string; name: string }[]
  >([]);

  const [shapes, setShapes] = useState<{ id: string; name: string }[]>([]);
  const [services, setServices] = useState<{ id: string; name: string }[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<
    { id: string; name: string }[]
  >([]);

  const user = useSelector((state: UserState) => state.userReducer);
  let params = useParams();

  const handleRemoveField = (index: number) => {
    setDetailsFields(detailsFields.filter((field, i) => i !== index));
  };

  const handleFieldChange = async (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    let newValue = value;

    if (
      name === "assignto_photography" ||
      name === "assignto_screening" ||
      name === "assignto_laboratory"
    ) {
      newValue = value === "yes" ? "no" : "yes";
    }

    if (name === "article_subtype_name") {
      const article = await getArticleSubTypes();
      const foundArticle = findItemByProperty(
        article.data.article_sub_type_list,
        "article_subtype_name",
        value
      );
      const newFields = [...detailsFields];
      newFields[index] = {
        ...newFields[index],
        article_subtype_id: foundArticle?.id ? foundArticle?.id : "",
        [name as keyof ArticleDetailsProps]: newValue,
      };

      setDetailsFields(newFields);
      return;
    }
    if (name === "shape_title") {
      const article = await getShapes();
      const foundArticle = findItemByProperty(
        article.data.shape_list,
        "title",
        value
      );
      const newFields = [...detailsFields];
      newFields[index] = {
        ...newFields[index],
        shape_id: foundArticle.id,
        [name as keyof ShapeMetalProps]: newValue,
      };

      setDetailsFields(newFields);
      return;
    }

    const newFields = [...detailsFields];
    newFields[index] = {
      ...newFields[index],
      [name as keyof ArticleDetailsProps]: newValue,
    };

    setDetailsFields(newFields);
  };

  const fetchArticleSubTypes = async () => {
    try {
      const response = await getArticleSubTypes();
      const newArticles = response.data.article_sub_type_list.map(
        ({
          article_subtype_name: name,
          ...rest
        }: {
          article_subtype_name: string;
          [key: string]: any;
        }) => ({
          name,
          ...rest,
        })
      );
      setArticlesSubtype(newArticles);
    } catch (error) {
      console.error("Error fetching article subtype list:", error);
    }
  };

  const fetchShapes = async () => {
    try {
      const response = await getShapes();
      if (response?.data?.shape_list) {
        const newShapes = response.data.shape_list.map(
          ({
            title: name,
            ...rest
          }: {
            title: string;
            [key: string]: any;
          }) => ({
            name,
            ...rest,
          })
        );
        setShapes(newShapes);
      }
    } catch (error) {
      console.error("Error fetching shapes list:", error);
    }
  };

  const fetchServiceType = async () => {
    try {
      const response = await getServiceTypes();

      if (response?.data?.service_type_list) {
        const newServices = response.data.service_type_list
          .filter((item: any) => item.status === "Active")
          .map(
            ({
              title: name,
              ...rest
            }: {
              title: string;
              [key: string]: any;
            }) => ({
              name,
              ...rest,
            })
          );
        setServices(newServices);
      }
    } catch (error) {
      console.error("Error fetching service type list:", error);
    }
  };

  useEffect(() => {
    fetchArticleSubTypes();
  }, []);

  useEffect(() => {
    fetchShapes();
  }, []);

  useEffect(() => {
    fetchServiceType();
  }, []);

  const handleNumberChange = (no_of_articles: string) => {
    const newNumber = Number(no_of_articles); // Using parseInt
    if (newNumber && newNumber > detailsFields.length) {
      const diff = newNumber - detailsFields.length;
      const newFields = [...detailsFields];
      for (let i = 0; i < diff; i++) {
        newFields.push(...initialFields);
      }
      setDetailsFields(newFields);
    } else if (newNumber && newNumber < detailsFields.length) {
      // const diff = detailsFields.length - newNumber;
      const newFields = [...detailsFields].slice(0, newNumber);
      setDetailsFields(newFields);
    }
  };

  const fetchData = async () => {
    if (data.article_list.length !== 0) {
      let articles = data.article_list;
      let articlesFinal = articles?.map((arr: any) => {
        let d = {};
        if (d) {
          return {
            ...arr,
            servicetype: arr.servicetype?.map((item: any) => {
              let s = {};
              if (s) {
                return {
                  id: item.service_type_id,
                  name: item.service_type_name,
                };
              }
              return s;
            }),
          };
        }
        return d;
      });

      setDetailsFields(articlesFinal);
    }
  };

  const handleStatus = async (lot: LotProps) => {
    let data = {
      lot_id: lot?.lot_id,
      lot_number: lot?.lot_number,
      article_id: lot?.id,
      employee_id: user?.department_name === "Admin" ? "" : user?.id,
      employee_name: user?.department_name === "Admin" ? "" : user?.name,
      branch_id: user?.department_name === "Admin" ? "" : user?.branch_id,
      branch_name: user?.department_name === "Admin" ? "" : user?.branch_name,
      department_id:
        user?.department_name === "Admin" ? "" : user?.department_id,
      department_name:
        user?.department_name === "Admin" ? "" : user?.department_name,
      status: lot.status,
      remarks: lot.remarks,
    };
    console.log("data", data);

    try {
      const response = await statusLot(data);
      toast.success(response?.data.message);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleMultipleSelectChange = (
    name?: string,
    selectedOptions?: Option[]
  ) => {
    if (selectedOptions) {
      const matches = name?.match(/\d+/);
      let number = "";
      if (matches !== null && matches !== undefined) {
        number = matches[0];
      }

      const items = [...detailsFields];
      const index = items[Number(number)];
      index["servicetype"] = selectedOptions;
      console.log("items", index);
      setDetailsFields(items);
    }
  };

  useEffect(() => {
    if (data.no_of_articles) {
      handleNumberChange(data.no_of_articles);
    }
    if (params.id !== "new") {
      fetchData();
    }
  }, [data.no_of_articles, params.id]);

  const { length: count } = detailsFields;

  if (count === 0) return <p>There are no data in the database.</p>;

  return (
    <>
      {/* Article Details */}
      {data.article_name !== "" && (
        <div className="form-sample">
          <p className="card-description">
            <div className="page-header">
              <p className="page-title">Article List</p>
              <div>
                {(user?.department_name === "Admin" ||
                  user?.department_name === "Front Office" ||
                  user?.department_name === "Administrator") && (
                  <>
                    <button
                      className="btn btn-info "
                      style={{ width: "auto" }}
                      type="submit"
                    >
                      Print Voucher
                    </button>
                  </>
                )}

                <Modal
                  data={data}
                  articlesSubtype={articlesSubtype}
                  shapes={shapes}
                  detailsFields={detailsFields}
                  setDetailsFields={setDetailsFields}
                />
              </div>
            </div>
          </p>
          <div className="table-responsive">
            <table className="table table-select">
              <thead style={{ height: "100px" }}>
                <tr>
                  {data.status !== "Pending" && params.id !== "new" && (
                    <th>Status</th>
                  )}
                  {data.status !== "Pending" && params.id !== "new" && (
                    <th>Certificate Number</th>
                  )}

                  {data.article_name === "Diamond Jewelry" ||
                  data.article_name === "Labgrown Jewelry" ||
                  data.article_name === "Jewelry Screening" ? (
                    <>
                      <th style={{ width: "130px" }}>Type of Goods</th>
                      <th>Metal</th>
                      <th>Submission Type</th>
                      <th>Weight</th>
                      <th>Caret Weight</th>
                      <th>No. of Diamonds</th>
                      <th>Shape</th>
                      <th>Design No.</th>
                      <th>Color</th>
                      <th>Clarity</th>
                      <th>Finish</th>
                      <th>Comments</th>
                    </>
                  ) : null}

                  {data.article_name === "Gemstones" ? (
                    <>
                      <th>Weight</th>
                      <th>Metal</th>
                      <th>Shape</th>
                      <th>Measurement</th>
                      <th>Color</th>
                      <th>Optic Character</th>
                      <th>Refractive Index</th>
                      <th>Specific Gravity</th>
                      <th>Spectroscopy</th>
                      <th>Magnification</th>
                      <th>Comments</th>
                      <th>Conclusion</th>
                    </>
                  ) : null}

                  {data.article_name === "Loose Diamond" ||
                  data.article_name === "Labgrown Diamond" ? (
                    <>
                      <th>Measurement</th>
                      <th>Metal</th>
                      <th>Weight</th>
                      <th>Shape</th>
                      <th>Total Depth</th>
                      <th>Crown Angle</th>
                      <th>Pavilion Depth</th>
                      <th>Loose Table</th>
                      <th>Gridle Thickness</th>
                      <th>Cut</th>
                      <th>Symmetry</th>
                      <th>Polish</th>
                      <th>Fluorescence</th>
                      <th>Color</th>
                      <th>Clarity</th>
                      <th>Finish</th>
                      <th>Comments</th>
                    </>
                  ) : null}

                  {data.article_name === "Gemstone Jewelry" ? (
                    <>
                      <th>Metal</th>
                      <th>Gross Weight</th>
                      <th>Uncut Diamond</th>
                      <th>Gemstone</th>
                      <th>Shape</th>
                      <th>Color</th>
                      <th>Clarity</th>
                      <th>Design No.</th>
                      <th>Comments</th>
                    </>
                  ) : null}

                  <th>Article Image</th>
                  <th>Service</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {detailsFields &&
                  detailsFields.map((field, index) => (
                    <tr key={index}>
                      {field.status && (
                        <td>
                          <Input
                            type=""
                            name="status"
                            value={field.status}
                            label=""
                            onChange={(event) =>
                              handleFieldChange(index, event)
                            }
                            error=""
                            diabled={true}
                          />
                        </td>
                      )}

                      {field.certificate_number && (
                        <td>
                          <Input
                            type=""
                            name="certificate_number"
                            value={field.certificate_number}
                            label=""
                            onChange={(event) =>
                              handleFieldChange(index, event)
                            }
                            error=""
                            diabled={true}
                          />
                        </td>
                      )}
                      {data.article_name === "Diamond Jewelry" ||
                      data.article_name === "Jewelry Screening" ||
                      data.article_name === "Labgrown Jewelry" ? (
                        <>
                          <td style={{ width: "130px" }}>
                            <Select
                              name="article_subtype_name"
                              value={field.article_subtype_name}
                              label=""
                              options={articlesSubtype.filter(
                                (item: any) =>
                                  item.article_name === data.article_name
                              )}
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="metal"
                              value={field.metal}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Select
                              name="service_type"
                              value={field.service_type}
                              label=""
                              options={ServiceArray}
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="weight"
                              value={field.weight}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="carat_weight"
                              value={field.carat_weight}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="no_of_diamond"
                              value={field.no_of_diamond}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Select
                              name="shape_title"
                              value={field.shape_title || ""}
                              label=""
                              options={shapes}
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="design_no"
                              value={field.design_no}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="color"
                              value={field.color}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="clarity"
                              value={field.clarity}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>{" "}
                          <td>
                            <Input
                              type=""
                              name="finish"
                              value={field.finish}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="comments"
                              value={field.comments}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                        </>
                      ) : null}

                      {data.article_name === "Gemstones" ? (
                        <>
                          <td>
                            <Input
                              type=""
                              name="metal"
                              value={field.metal}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="weight"
                              value={field.weight}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Select
                              name="shape_title"
                              value={field.shape_title || ""}
                              label=""
                              options={shapes}
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="measurements"
                              value={field.measurements}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="color"
                              value={field.color}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>

                          <td>
                            <Input
                              type=""
                              name="optic_character"
                              value={field.optic_character}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="refractive_index"
                              value={field.refractive_index}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="spcific_gravity"
                              value={field.spcific_gravity}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="spectroscopy"
                              value={field.spectroscopy}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="magnification"
                              value={field.magnification}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="comments"
                              value={field.comments}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="conclusion"
                              value={field.conclusion}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                        </>
                      ) : null}

                      {data.article_name === "Loose Diamond" ||
                      data.article_name === "Labgrown Diamond" ? (
                        <>
                          <td>
                            <Input
                              type=""
                              name="measurements"
                              value={field.measurements}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="metal"
                              value={field.metal}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="weight"
                              value={field.weight}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Select
                              name="shape_title"
                              value={field.shape_title || ""}
                              label=""
                              options={shapes}
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="total_depth"
                              value={field.total_depth}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="crown_angle"
                              value={field.crown_angle}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="pavilion_depth"
                              value={field.pavilion_depth}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="loose_table"
                              value={field.loose_table}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="gridle_thikness"
                              value={field.gridle_thikness}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="cut"
                              value={field.cut}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="symmetry"
                              value={field.symmetry}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="symmetry"
                              value={field.symmetry}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="polish"
                              value={field.polish}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="fluorescence"
                              value={field.fluorescence}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="color"
                              value={field.color}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="clarity"
                              value={field.clarity}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>{" "}
                          <td>
                            <Input
                              type=""
                              name="finish"
                              value={field.finish}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="comments"
                              value={field.comments}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                        </>
                      ) : null}

                      {data.article_name === "Gemstone Jewelry" ? (
                        <>
                          <td>
                            <Input
                              type=""
                              name="metal"
                              value={field.metal}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="weight"
                              value={field.weight}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="uncut_diamond"
                              value={field.uncut_diamond}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="gemstone"
                              value={field.gemstone}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Select
                              name="shape_title"
                              value={field.shape_title || ""}
                              label=""
                              options={shapes}
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="color"
                              value={field.color}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="clarity"
                              value={field.clarity}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>{" "}
                          <td>
                            <Input
                              type=""
                              name="design_no"
                              value={field.design_no}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                          <td>
                            <Input
                              type=""
                              name="comments"
                              value={field.comments}
                              label=""
                              onChange={(event) =>
                                handleFieldChange(index, event)
                              }
                              error=""
                            />
                          </td>
                        </>
                      ) : null}

                      <td>
                        <Input
                          type=""
                          name="article_image_name"
                          value={field.article_image_name}
                          label=""
                          onChange={(event) => handleFieldChange(index, event)}
                          error=""
                        />
                      </td>
                      <td>
                        <MultipleSelect
                          selectedOptions={selectedOptions}
                          setSelectedOptions={setSelectedOptions}
                          name={`servicetype ${index}`}
                          value={field.servicetype}
                          label=""
                          options={services}
                          onChange={handleMultipleSelectChange}
                          error=""
                        />
                      </td>
                      <td>
                        {params.id !== "new" && (
                          <StatusLot
                            item={field}
                            onClick={handleStatus}
                            department="Front Office Manager"
                          />
                        )}
                        {params.id === "new" && (
                          <Link
                            to="#"
                            onClick={() => handleRemoveField(index)}
                            className="btn-edt"
                            style={{
                              display:
                                params.id === "new" ||
                                (user && user.department_name === "Admin")
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default ArticleDetails;
