import http from "./httpService";
import { apiUrl } from "../config.js";

// Get the track data

export async function getTrack(lots_number: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/track_lot", {
      lots_number: lots_number,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving track data:", error);
    // throw error;
  }
}
