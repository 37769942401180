import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../../../../common/form";
import "./DepartmentForm.css";
import { ToastContent, toast } from "react-toastify";
import { getBranches } from "../../../../services/branchService";
import {
  getDepartment,
  saveDepartment,
} from "../../../../services/departmentService";
import { DepartmentProps } from "../../../../types/departmentProps";
import { BranchProps } from "../../../../types/branchProps";
import findItemByProperty from "../../../../utils/findItem";

type Props = {
  id: string;
  showSideNav: boolean;
  setShowSideNav: (show: boolean) => void;
};

interface Errors {
  [key: string]: string | undefined;
}

type ArrayProps = {
  id: string;
  name: string;
};

const DepartmentType: ArrayProps[] = [
  {
    id: "0",
    name: "Accounts",
  },
  {
    id: "1",
    name: "Admin",
  },
  {
    id: "2",
    name: "Back Office",
  },
  {
    id: "3",
    name: "Front Office",
  },
  {
    id: "4",
    name: "Laboratory",
  },
  {
    id: "5",
    name: "Photography",
  },
  {
    id: "6",
    name: "Sales",
  },
  {
    id: "7",
    name: "Screening",
  },
];

function DepartmentForm({ id, showSideNav, setShowSideNav }: Props) {
  const initialData: DepartmentProps = {
    created_date: "",
    status: "",
    updated_date: "",
    id: "",
    branch_id: "",
    branch_name: "",
    department_name: "",
  };
  const [data, setData] = useState<DepartmentProps>(initialData);
  const [errors, setErrors] = useState<Errors>({});
  const [branches, setBranches] = useState<BranchProps[]>([]);
  const fetchBranch = async () => {
    try {
      const response = await getBranches();
      const newBranch = response.data.branch_list.map(
        ({
          branch_name: name,
          ...rest
        }: {
          branch_name: string;
          [key: string]: any;
        }) => ({
          name,
          ...rest,
        })
      );
      setBranches(newBranch);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  useEffect(() => {
    fetchBranch();
  }, []);

  const PopulateData = async (id: string) => {
    try {
      const dataId = id;
      if (dataId === "new") {
        let resultedData: DepartmentProps = Object.keys(data).reduce(
          (obj, key) => {
            obj[key as keyof DepartmentProps] = "";
            return obj;
          },
          {} as DepartmentProps
        );
        setData(resultedData);
      } else {
        const response = await getDepartment(dataId);
        setData(mapToViewModel(response.data));
      }
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  const mapToViewModel = ({ ...rest }: any): any => ({ ...rest });

  useEffect(() => {
    if (id) {
      PopulateData(id);
    }
  }, [id]);

  const doSubmit = async (): Promise<void> => {
    // Call the server
    try {
      const branch = await getBranches();
      const foundBranch = findItemByProperty(
        branch.data.branch_list,
        "branch_name",
        data.branch_name
      );
      const branchData = {
        branch_id: foundBranch?.id,
        branch_name: foundBranch?.branch_name,
      };

      let resultedData = { ...data, ...branchData };

      const response = await saveDepartment(resultedData);
      if (response) {
        toast.success(response.data.message);
        let resultedData: DepartmentProps = Object.keys(data).reduce(
          (obj, key) => {
            obj[key as keyof DepartmentProps] = "";
            return obj;
          },
          {} as DepartmentProps
        );
        setData(resultedData);
        setShowSideNav(!showSideNav);
      }
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    id: Joi.optional().allow(""),
    branch_id: Joi.optional().allow(""),
    branch_name: Joi.string().required().label("Branch Name"),
    created_date: Joi.optional().allow(""),
    department_name: Joi.string().required().label("Department Name"),
    status: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
  };

  const { renderSelect, renderButton, handleSubmit } = Form<DepartmentProps>({
    data,
    setData,
    errors,
    setErrors,
    schema,
    onSubmit: doSubmit,
  });

  useEffect(() => {
    const buttonElement = document.querySelector(".forms-sample .btn");
    if (buttonElement) {
      buttonElement.classList.add("btn-gradient-primary");
    }
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit} className="forms-sample">
        {renderSelect(
          "branch_name",
          'Branch Name <span class="text-danger">*</span>',
          branches
        )}
        {renderSelect(
          "department_name",
          'Department Name <span class="text-danger">*</span>',
          DepartmentType
        )}
        {renderButton("Save")}
        <p>&nbsp;</p>
      </form>
    </>
  );
}

export default DepartmentForm;
