import React from "react";
import { Link } from "react-router-dom";
import { MdHome, MdFilePresent } from "react-icons/md";
import { HiOutlineClipboardList } from "react-icons/hi";
import { SiNintendogamecube, SiWebpack } from "react-icons/si";
import { FaUserCog } from "react-icons/fa";
import { VscPersonAdd } from "react-icons/vsc";
import { MenuItem } from "./MenuItem";

type Props = {
  classes: string;
};

function BottomNavbar({ classes }: Props) {
  return (
    <>
      <nav className={classes}>
        <div className="container">
          <ul className="nav page-navigation">
            <li className="nav-item active">
              <Link className="nav-link" to="/admin_panel">
                <MdHome className="menu-icon" size={25} />
                <span className="menu-title">Dashboard</span>
              </Link>
            </li>
            {MenuItem.map((item: any, index: any) => (
              <li className="nav-item" key={index}>
                <Link className="nav-link" to={item.link}>
                  {item.icon}
                  <span className="menu-title">{item.title}</span>
                </Link>
                {item.submenuItems && (
                  <>
                    <div className="submenu">
                      <ul className="submenu-item">
                        {item.submenuItems.map(
                          (subitem: any, subindex: any) => (
                            <li className="nav-item" key={subindex}>
                              <Link className="nav-link" to={subitem.to}>
                                {subitem.text}
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
}

export default BottomNavbar;
