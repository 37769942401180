import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import "./PhotographyForm.css";
import { ToastContent, toast } from "react-toastify";
import { getLot } from "../../services/lotService";
import Header from "./Header";
import ArticleDetails from "./ArticleDetails";
import { initialFields } from "./InitialFields";
import { PhotographyProps } from "../../types/photographyProps";
import { savePhotography } from "../../services/photographyService";
import { useSelector } from "react-redux";
import { UserState } from "../../types/userTypes";

const PhotographyForm = () => {
  const initialData: PhotographyProps = {
    id: "",
    lots_number: "",
    article_list: [],
  };
  const [data, setData] = useState<PhotographyProps>(initialData);
  const params = useParams();
  const user = useSelector((state: UserState) => state.userReducer);

  const [detailsFields, setDetailsFields] = useState<any[]>(initialFields);
  const location = useLocation();

  const PopulateData = async (id: string) => {
    try {
      const dataId = id;
      const response = await getLot(dataId);
      setData(mapToViewModel(response.data));
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  const mapToViewModel = ({ ...rest }: any): any => ({ ...rest });

  useEffect(() => {
    if (params.id) {
      PopulateData(params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handlePrev = () => {
    // Programmatically navigate to a different route
    const lastSlashIndex = location.pathname.lastIndexOf("/");
    const newPathname = location.pathname.substring(0, lastSlashIndex + 1);

    let redirectUrl = (process.env.REACT_APP_liveURL + newPathname) as string;
    if (process.env.NODE_ENV === "development") {
      redirectUrl = (process.env.REACT_APP_localURL + newPathname) as string;
    }
    window.location.href = redirectUrl; // No need for type assertion
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    console.log("Submitted", data);
    try {
      let resultedData = { ...data, article_list: detailsFields };
      const response = await savePhotography(resultedData);
      toast.success(response.data.message);
      handlePrev();
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  console.log("data", data);

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper ">
          <div className="main-panel ">
            <div className="content-wrapper ">
              <Header />

              {/* Lot Details Info */}
              <form onSubmit={handleSubmit} className="form-horizontal">
                <div className="row ">
                  <div className="col-12 grid-margin ">
                    <div className="card ">
                      <div className="card-body">
                        <ArticleDetails
                          detailsFields={detailsFields}
                          data={data}
                          setDetailsFields={setDetailsFields}
                        />

                        <div
                          className="row "
                          style={{
                            width: "271px",
                            margin: "21px auto",
                            // display:
                            //   (user &&
                            //     user.department_name === "Admin" &&
                            //     data.status !== "") ||
                            //   data.status === ""
                            //     ? "block"
                            //     : data.status !== ""
                            //     ? "none"
                            //     : "block",
                          }}
                        >
                          <button type="submit" className="btn btn-svs">
                            Save
                          </button>
                          <button
                            type="button"
                            onClick={handlePrev}
                            className="btn btn-cnc"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhotographyForm;
