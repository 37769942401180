import React, { useState } from "react";
import { Link } from "react-router-dom";

type Props = {
  article: any;
  onClick: (data: any) => void;
};

function Engraving({ article, onClick }: Props) {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const handleEngraving = () => {
    onClick(article);
    setOpen(!open);
  };

  return (
    <>
      <Link
        to="#"
        className="btn-edt"
        onClick={handleOpen}
        data-toggle="tooltip"
        data-placement="top"
        title="Engraving"
      >
        <i className="fa fa-edge" aria-hidden="true"></i>
      </Link>

      <div
        className={open ? "modal fade show" : "modal fade"}
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Confirmation Engraving
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">Has the lot been engraved?</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="button"
                onClick={handleEngraving}
                className="btn btn-primary"
              >
                Understood
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={open ? "modal-backdrop " : ""}></div>
    </>
  );
}

export default Engraving;
