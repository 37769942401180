import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Error from "../pages/Error/Error";
import {
  Panel,
  Lot,
  Employ,
  Customer,
} from "../dashboard/AdministratorDashboard/pages/";
import { AdministratorDashboard } from "../dashboard";
import Logout from "../common/logout";
import { CustomerForm, EmployForm, LotForm } from "../forms";
import { Barcode, Certificate, Invoice, Jobcard, Voucher } from "../components";

function AdministratorRoute() {
  return (
    <>
      <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/administrator_panel" element={<AdministratorDashboard />}>
          <Route index element={<Panel />} />
          <Route path="lot/:id" element={<Lot />} />
          <Route path="lot/:id/:id" element={<LotForm />} />
          <Route path="lot/:id/voucher/:enc_lot_id" element={<Voucher />} />
          <Route path="lot/:id/barcode/:lots_number" element={<Barcode />} />
          <Route path="lot/:id/jobcard/:lots_number" element={<Jobcard />} />
          <Route path="lot/:id/invoice/:lots_number" element={<Invoice />} />

          <Route path="employee" element={<Employ />} />
          <Route path="employee/:id" element={<EmployForm />} />
          <Route path="customer" element={<Customer />} />
          <Route path="customer/:id" element={<CustomerForm />} />

          <Route path="*" element={<Error />} />
        </Route>
        <Route
          path="/"
          element={<Navigate to="/administrator_panel" replace />}
        />
        <Route
          path="/admin_login"
          element={<Navigate to="/administrator_panel" replace />}
        />
        <Route
          path="/employee_login"
          element={<Navigate to="/administrator_panel" replace />}
        />

        <Route path="/logout" element={<Logout />} />
      </Routes>
    </>
  );
}

export default AdministratorRoute;
