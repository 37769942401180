import React, { useState, useEffect } from "react";
import { LaboratoryProps } from "../../types/laboratoryProps";
import Modal from "./Modal";
import BulkModal from "./BulkModal";
import StatusLot from "../../common/statusLot";
import { toast } from "react-toastify";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";
import { statusLaboratory } from "../../services/lotLabService";

type Props = {
  data: LaboratoryProps;
  detailsFields: any[];
  setDetailsFields: React.Dispatch<React.SetStateAction<any>>;
};

function ArticleDetails({ data, detailsFields, setDetailsFields }: Props) {
  const [array, setArray] = useState<any>([]);
  const user = useSelector((state: UserState) => state.userReducer);
  const branch = useSelector((state: any) => state.branchReducer);
  const [open, setOpen] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>();

  const handleOpen = (index: number) => {
    setOpen(!open);
    setCurrentIndex(index);
    setArray(detailsFields[index]);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      if (
        currentIndex !== undefined &&
        currentIndex < detailsFields.length - 1
      ) {
        setCurrentIndex(currentIndex + 1);
        handleOpen(currentIndex + 1);
      } else {
        console.log("Reached the end of the array");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [currentIndex]);

  const handleStatus = async (lot: any) => {
    console.log("lot", lot);
    let data = {
      lot_id: lot.lot_id,
      lot_number: lot.lot_number,
      article_id: lot.id,
      employee_id: user?.department_name === "Admin" ? "" : user?.id,
      employee_name: user?.department_name === "Admin" ? "" : user?.name,
      branch_id: user?.department_name === "Admin" ? "" : branch?.id,
      branch_name: user?.department_name === "Admin" ? "" : branch?.name,
      department_id:
        user?.department_name === "Admin" ? "" : user?.department_id,
      department_name:
        user?.department_name === "Admin" ? "" : user?.department_name,
      status: lot.status,
      remarks: lot.remarks,
    };

    try {
      const response = await statusLaboratory(data);
      toast.success(response?.data.message);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (data.article_list) {
        const articles = data.article_list;
        const filter = articles.filter(
          (article: any) => article.assignto_laboratory.toLowerCase() === "yes"
        );
        const updatedArray = await Promise.all(
          filter.map((item: any) => ({
            ...item,
            article_list: [],
          }))
        );
        setDetailsFields(updatedArray);
      }
    };

    fetchData();
  }, [data, setDetailsFields]);

  return (
    <>
      {/* Article Details */}
      <div className="form-sample">
        <p className="card-description">
          <div className="page-header">
            <p className="page-title">Article List</p>
            {/* <BulkModal
              data={data}
              detailsFields={detailsFields}
              setDetailsFields={setDetailsFields}
            /> */}
          </div>
        </p>

        <div className="table-responsive">
          <table className="table table-lot">
            <thead>
              <tr>
                <th>Certificate Number</th>

                {data.article_name === "Diamond Jewelry" ||
                data.article_name === "Labgrown Jewelry" ||
                data.article_name === "Jewelry Screening" ? (
                  <>
                    <th style={{ width: "130px" }}>Type of Goods</th>
                    <th>Design No.</th>
                    <th>Color</th>
                    <th>Clarity</th>
                    <th>Finish</th>
                  </>
                ) : null}

                {data.article_name === "Gemstones" ? (
                  <>
                    <th>Measurement</th>
                    <th>Weight</th>
                    <th>Color</th>
                  </>
                ) : null}

                {data.article_name === "Loose Diamond" ||
                data.article_name === "Labgrown Diamond" ? (
                  <>
                    <th>Measurement</th>
                    <th>Weight</th>
                    <th>Color</th>
                  </>
                ) : null}

                {data.article_name === "Gemstone Jewelry" ? (
                  <>
                    <th>Gemstone</th>
                    <th>Gross Weight</th>
                    <th>Color</th>
                  </>
                ) : null}
                <th> Testing </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {detailsFields &&
                detailsFields.map((field, index) => (
                  <>
                    <tr key={index}>
                      <td>{field.certificate_number}</td>

                      {data.article_name === "Diamond Jewelry" ||
                      data.article_name === "Jewelry Screening" ||
                      data.article_name === "Labgrown Jewelry" ? (
                        <>
                          <td style={{ width: "130px" }}>
                            {field.article_subtype_name}
                          </td>
                          <td>{field.design_no}</td>
                          <td>{field.color}</td>
                          <td>{field.clarity}</td>
                          <td>{field.finish}</td>
                        </>
                      ) : null}

                      {data.article_name === "Gemstones" ? (
                        <>
                          <td>{field.measurements}</td>
                          <td>{field.weight}</td>
                          <td>{field.color}</td>
                        </>
                      ) : null}

                      {data.article_name === "Loose Diamond" ||
                      data.article_name === "Labgrown Diamond" ? (
                        <>
                          <td>{field.measurements}</td>
                          <td>{field.weight}</td>
                          <td>{field.color}</td>
                        </>
                      ) : null}

                      {data.article_name === "Gemstone Jewelry" ? (
                        <>
                          <td>{field.gemstone}</td>
                          <td>{field.weight}</td>
                          <td>{field.color}</td>
                        </>
                      ) : null}

                      <td>
                        {/* <Modal
                          field={field}
                          array={array}
                          setArray={setArray}
                        /> */}
                        <span
                          key={index}
                          tabIndex={index}
                          className="add-btns"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index}`}
                          aria-expanded="false"
                          aria-controls={`collapse${index}`}
                          onClick={() => handleOpen(index)}
                        >
                          Test item
                        </span>
                      </td>
                      <td>
                        <StatusLot
                          item={field}
                          onClick={handleStatus}
                          department="Lab Testing"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12}>
                        <div
                          className={` collapse ${
                            index === currentIndex ? "show" : ""
                          }`}
                          id={`collapse${index}`}
                        >
                          <div className="card card-body">
                            <Modal
                              array={array}
                              setArray={setArray}
                              currentIndex={currentIndex}
                              setCurrentIndex={setCurrentIndex}
                              detailsFields={detailsFields}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default ArticleDetails;
