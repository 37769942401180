import React from "react";

type Props = {};

function NoLotMessage({}: Props) {
  return (
    <h3 className="page-title">
      All Lots have been accepted or there is no lots in the databse. <br /> So
      wait for new lots...
    </h3>
  );
}

export default NoLotMessage;
