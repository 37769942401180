import http from "./httpService";
import { apiUrl } from "../config.js";

// Get the barcode data

export async function getBarcode(lot_number: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/barcode_list", {
      lot_number: lot_number,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving barcode data:", error);
    // throw error;
  }
}
