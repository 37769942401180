import http from "./httpService";
import { apiUrl } from "../config.js";
import { ServiceTypeProps } from "../types/serviceTypeProps";

const serviceTypeEndpoint = apiUrl + "/create_update_service_type";

// Get the service type data

export async function getServiceTypes(): Promise<any> {
  try {
    return await http.get(apiUrl + "/service_type_list");
  } catch (error) {
    // Handle error
    console.error("Error retrieving service types data:", error);
    // throw error;
  }
}
export async function getServiceType(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_service_type", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving service type data:", error);
    // throw error;
  }
}

//  Create and Update Service Type

export function saveServiceType(serviceType: ServiceTypeProps) {
  return http.post(serviceTypeEndpoint, serviceType);
}

// Update the status of the service type data

export async function statusServiceType(
  id: string,
  status: string
): Promise<any> {
  try {
    return await http.post(apiUrl + "/change_service_type_status", {
      id: id,
      status: status,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving service type data:", error);
    // throw error;
  }
}

// Delete the service type data

export async function deleteServiceType(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/delete_service_type", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving service type data:", error);
    // throw error;
  }
}
