import React, { useState, useEffect } from "react";
import { paginate } from "../../utils/paginate";
import Pagination from "../../common/pagination";
import AccountTable from "./AccountTable";
import { toast } from "react-toastify";
import SearchBox from "../../common/searchBox";
import PageSize from "../../common/pageSize";
import { useSelector } from "react-redux";
import { UserState } from "../../types/userTypes";
import { getAccountants, saveAccounts } from "../../services/accountsService";
import { AccountantProps } from "../../types/accountanProps";
import { AccountProps } from "../../types/accountProps";

interface RedirecData {
  branch_id: string;
  employee_id: string;
}

function AccountList() {
  const [accounts, setAccounts] = useState<AccountantProps[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const user = useSelector((state: UserState) => state.userReducer);
  const branch = useSelector((state: any) => state.branchReducer);

  const fetchData = async (
    user: { [key: string]: string } | null,
    branch?: any
  ): Promise<void> => {
    try {
      let redirecData: RedirecData = {
        branch_id: branch.id || "",
        employee_id: "",
      };

      if (user?.department_name === "Admin") {
        redirecData = {
          branch_id: "",
          employee_id: "",
        };
      }
      const response = await getAccountants(redirecData);
      response.data.error ? setAccounts([]) : setAccounts(response.data);
    } catch (error: any) {
      console.error("Error fetching lot list:", error);
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleAccountsButton = async (data: AccountProps): Promise<void> => {
    // Call the server

    try {
      const response = await saveAccounts(data);
      if (response) {
        toast.success(response.data.message);
      }
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleAccounts = async (data: AccountProps): Promise<void> => {
    handleAccountsButton(data)
      .then(() => {
        // After handling accounts, call fetchData with appropriate parameters
        if (user && branch) {
          fetchData(user, branch);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during handleAccounts
        console.error("Error handling accounts:", error);
      });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const getPagedData = () => {
    let filtered = accounts;
    if (searchQuery)
      filtered = accounts.filter((m) =>
        m.lots_number.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const account = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: account };
  };

  const { totalCount, data: account } = getPagedData();

  const HandlePage = () => (
    <div className="row">
      <div className="col-md-9">
        <h3 className="page-title">
          Showing {totalCount} entries in the Database
        </h3>
      </div>
      <div className="col-md-3">
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );

  const TabContent = () => (
    <AccountTable accounts={account} onAccounts={handleAccounts} />
  );

  useEffect(() => {
    if (user && branch) {
      fetchData(user, branch);
    }
  }, [user, branch]);

  return (
    <>
      <div className="row">
        <div className="col-md-3 entries">
          <div
            style={{
              display: "grid",
              justifyContent: "space-between",
              alignItems: "center",
              gridTemplateColumns: "auto auto auto",
            }}
          >
            Showing
            <PageSize pageSize={pageSize} setPageSize={setPageSize} /> entries
          </div>
        </div>
        <div className="col-md-6 offset-md-3 search">
          <SearchBox value={searchQuery} onChange={handleSearch} />
        </div>
      </div>

      {account.length === 0 && (
        <h3 className="page-title">There are no invoice in these branch.</h3>
      )}
      {account.length !== 0 && (
        <>
          {" "}
          <TabContent /> <HandlePage />
        </>
      )}
    </>
  );
}

export default AccountList;
