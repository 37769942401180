import React, { useState } from "react";
import { convertExcelToArray } from "../../utils/convertExcelToArray";
import { initialFields } from "./InitialFields";
import { CSVLink } from "react-csv";
import { LotProps } from "../../types/lotProps";

type Props = {
  data: LotProps;
  articlesSubtype: any[];
  shapes: any[];
  detailsFields: any[];
  setDetailsFields: React.Dispatch<React.SetStateAction<any>>;
};

function Modal({
  data,
  articlesSubtype,
  shapes,
  detailsFields,
  setDetailsFields,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [array, setArray] = useState<Record<string, any>[]>([]);
  const [excelError, setExcelError] = useState<string>("");

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  // Download Sampele Excel File

  const labDiaJewel = [
    {
      article_subtype_name: "Pair of Earrings",
      metal: "Gold",
      service_type: "Fresh",
      weight: "62.77",
      carat_weight: "14.23",
      no_of_diamond: "22",
      shape_title: "Round /Marquise/Pear Brilliant",
      design_no: "SAMP1234",
      color: "I",
      clarity: "VS1",
      finish: "Very Good",
      comments: "Natural Diamonds",
      servicetype: "Laser Inscription, Loose Diamond Grading",
      article_image_name: "Ear Rings.jpg",
    },
  ];
  const gen = [
    {
      weight: "62.77",
      metal: "Gold",
      shape_title: "Oval Mixed Cut",
      measurements: "NA",
      color: "Red",
      optic_character: "DR",
      refractive_index: "1.76-1.77",
      spcific_gravity: "3.99",
      spectroscopy: "NA",
      magnification: "Healed Fingerprint Inclusions",
      conclusion: "Natural Ruby",
      comments: "1",
      servicetype: "Laser Inscription, Loose Diamond Grading",
      article_image_name: "Ear Rings.jpg",
    },
  ];
  const looseLabDia = [
    {
      measurements: "6.81 x 7.08 x 4.20 mm",
      metal: "Gold",
      weight: "1.59 cts",
      shape_title: "Cushion Cut",
      total_depth: "NA",
      crown_angle: "NA",
      pavilion_depth: "NA",
      loose_table: "NA",
      gridle_thikness: "NA",
      cut: "Cushion Cut",
      symmetry: "NA",
      polish: "Very Good",
      fluorescence: "None",
      color: "I",
      clarity: "VS1",
      finish: "Very Good",
      comments: "Natural Diamonds",
      article_image_name: "Ear Rings.jpg",
      servicetype: "Laser Inscription, Loose Diamond Grading",
    },
  ];
  const gemJewel = [
    {
      metal: "Gold",
      weight: "NA",
      uncut_diamond: "0.82 Cts",
      gemstone: "10.01 Cts",
      shape: "Round Brilliant Cut",
      color: "F - G",
      clarity: "1.76-1.77",
      design_no: "3.99",
      comments: "Grading & Identification of origin as mounting permits.",
      servicetype: "Laser Inscription, Loose Diamond Grading",
      article_image_name: "Ear Rings.jpg",
    },
  ];

  const handleDownload = () => {
    if (
      data.article_name === "Diamond Jewelry" ||
      data.article_name === "Labgrown Jewelry" ||
      data.article_name === "Jewelry Screening"
    ) {
      return labDiaJewel;
    } else if (data.article_name === "Gemstones") {
      return gen;
    } else if (
      data.article_name === "Loose Diamond" ||
      data.article_name === "Labgrown Diamond"
    ) {
      return looseLabDia;
    } else if (data.article_name === "Gemstone Jewelry") {
      return gemJewel;
    } else {
      return [];
    }
  };
  // Handle Excel Upload

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target?.files?.[0];

    if (file) {
      try {
        const convertedData = await convertExcelToArray(file);
        setArray(convertedData);
        setExcelError("");
      } catch (error) {
        console.error(error);
      }
    }
  };

  //  Handle Upload

  const handleExcelUpload = () => {
    if (array.length === 0) {
      setExcelError("Excel file is required");
    } else {
      let articlesFinal = array.map((arr) => {
        const articleSubtypeData = articlesSubtype.find(
          (art) => art.name === arr.article_subtype_name
        );
        const shapeData = shapes.find((art) => art.name === arr.shape_title);

        let data = {};
        if (data) {
          return {
            ...initialFields[0],
            ...arr,
            article_subtype_id: articleSubtypeData?.id,
            shape_id: shapeData?.id,
            servicetype: arr.servicetype
              ?.split(",")
              .map((item: any) => ({ id: "", name: item.trim() })),
          };
        }
        return data;
      });
      setDetailsFields(articlesFinal);
      setOpen(!open);
    }
  };

  return (
    <>
      <span
        style={{ width: "auto" }}
        className="btn btn-success"
        onClick={handleOpen}
      >
        Bulk Upload
      </span>
      <div
        className={open ? "modal fade show" : "modal fade"}
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Bulk Upload
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="page-header">
                <h3 className="page-title">
                  <input
                    className="form-control"
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    onChange={handleFileUpload}
                  />
                </h3>
                <CSVLink
                  filename="sample.csv"
                  className="add-btns"
                  data={handleDownload()}
                >
                  Download Samples
                </CSVLink>

                {/* <span className="add-btns" onClick={handleDownload}>
                  Download Sample
                </span> */}
              </div>
              <div className="page-header">
                {excelError && <p className="text-danger">{excelError}</p>}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="button"
                onClick={handleExcelUpload}
                className="btn btn-primary"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={open ? "modal-backdrop " : ""}
        style={{ display: open ? "block" : "none" }}
      ></div>
    </>
  );
}

export default Modal;
