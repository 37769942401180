import http from "./httpService";
import { apiUrl } from "../config.js";
import { PhotographyProps } from "../types/photographyProps";

const photographyEndpoint = apiUrl + "/upload_article_photo";

// Get the Photographys Data

export async function getPhotographys(photography: {
  [key: string]: any;
}): Promise<any> {
  try {
    return await http.post(apiUrl + "/photography_lot_list", {
      branch_id: photography.branch_id,
      employee_id: photography.employee_id,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving photographys data:", error);
    // throw error;
  }
}

export async function getPhotography(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/article_photo_list", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving photography data:", error);
    // throw error;
  }
}

//  Create and Update Photography

export function savePhotography(photography: PhotographyProps) {
  return http.post(photographyEndpoint, photography);
}

// Update the status of the Photography Data

export async function statusPhotography(photography: any) {
  try {
    return await http.post(apiUrl + "/update_status", photography);
  } catch (error) {
    // Handle error
    console.error("Error retrieving article data:", error);
    // throw error;
  }
}
// Update the track status of the Photography Data

export async function trackPhotography(photography: any) {
  try {
    return await http.post(apiUrl + "/update_bin", photography);
  } catch (error) {
    // Handle error
    console.error("Error retrieving article data:", error);
    // throw error;
  }
}

// // Delete the Article Data

// export async function deleteArticle(id: string): Promise<any> {
//   try {
//     return await http.post(apiUrl + "/delete_article", { id: id });
//   } catch (error) {
//     // Handle error
//     console.error("Error retrieving article data:", error);
//     // throw error;
//   }
// }
