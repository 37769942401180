import React from "react";
import { Link } from "react-router-dom";
import { ArticleForm } from "./../../forms/";

type Props = {
  id: string;
  showSideNav: boolean;
  setShowSideNav: (show: boolean) => void;
  setId: React.Dispatch<React.SetStateAction<string>>;
};

function Sidenav({ id, showSideNav, setShowSideNav, setId }: Props) {
  const closeNav = () => {
    setShowSideNav(!showSideNav);
    setId("");
  };

  return (
    <>
      <div
        id="mySidenav"
        className="sidenav"
        style={{ width: showSideNav ? "650px" : "0px" }}
      >
        <Link to="#" className="closebtn" onClick={closeNav}>
          &times;
        </Link>
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Article Type Register Form </h4>
              <ArticleForm
                id={id}
                showSideNav={showSideNav}
                setShowSideNav={setShowSideNav}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidenav;
