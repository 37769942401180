import React, { useState } from "react";
import "./EngravingDashboard.css";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import BottomNavbar from "./BottomNavbar";

type Props = {};

function EngravingDashboard({}: Props) {
  const [showNavbar, setShowNavbar] = useState<boolean>(false);
  let classes: string = "bottom-navbar";
  if (showNavbar) classes += " header-toggled";
  return (
    <>
      <div className="container-scroller">
        <div className="horizontal-menu">
          <Navbar showNavbar={showNavbar} setShowNavbar={setShowNavbar} />
          <BottomNavbar classes={classes} />
        </div>
        <div className="container-fluid page-body-wrapper ">
          <div className="main-panel ">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default EngravingDashboard;
