import React from "react";
import { CustomerList } from "../../../../tables";

type Props = {};

function List({}: Props) {
  return (
    <>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Recent Customer</h4>
              {/* Ticket Table */}
              <CustomerList />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
