import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./LaboratoryForm.css";
import { ToastContent, toast } from "react-toastify";
import { getLot } from "../../services/lotService";
import Header from "./Header";
import ArticleDetails from "./ArticleDetails";
import { initialFields } from "./InitialFields";
import { LaboratoryProps } from "../../types/laboratoryProps";

const LaboratoryForm = () => {
  const initialData: LaboratoryProps = {
    id: "",
    lots_number: "",
    article_list: [],
  };
  const [data, setData] = useState<LaboratoryProps>(initialData);
  const params = useParams();
  const [detailsFields, setDetailsFields] = useState<any[]>(initialFields);

  const PopulateData = async (id: string) => {
    try {
      const dataId = id;
      const response = await getLot(dataId);
      setData(mapToViewModel(response.data));
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  const mapToViewModel = ({ ...rest }: any): any => ({ ...rest });

  useEffect(() => {
    if (params.id) {
      PopulateData(params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper ">
          <div className="main-panel ">
            <div className="content-wrapper ">
              <Header />

              <div className="row ">
                <div className="col-12 grid-margin ">
                  <div className="card ">
                    <div className="card-body">
                      {/* Lot Details Info */}

                      <ArticleDetails
                        detailsFields={detailsFields}
                        data={data}
                        setDetailsFields={setDetailsFields}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LaboratoryForm;
