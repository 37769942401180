import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../../../../common/form";
import "./TaxForm.css";
import { ToastContent, toast } from "react-toastify";
import { TaxProps } from "../../../../types/taxProps";
import { getTax, saveTax } from "../../../../services/taxService";

type Props = {
  id: string;
  showSideNav: boolean;
  setShowSideNav: (show: boolean) => void;
};

interface Errors {
  [key: string]: string | undefined;
}

function TaxForm({ id, showSideNav, setShowSideNav }: Props) {
  const initialData: TaxProps = {
    id: "",
    tax: "",
    title: "",
    created_date: "",
    status: "",
    updated_date: "",
  };
  const [data, setData] = useState<TaxProps>(initialData);
  const [errors, setErrors] = useState<Errors>({});

  const PopulateData = async (id: string) => {
    try {
      const dataId = id;
      if (dataId === "new") {
        let resultedData: TaxProps = Object.keys(data).reduce((obj, key) => {
          obj[key as keyof TaxProps] = "";
          return obj;
        }, {} as TaxProps);
        setData(resultedData);
      } else {
        const response = await getTax(dataId);
        setData(mapToViewModel(response.data));
      }
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  const mapToViewModel = ({ ...rest }: any): any => ({ ...rest });

  useEffect(() => {
    if (id) {
      PopulateData(id);
    }
  }, [id]);

  const doSubmit = async (): Promise<void> => {
    // Call the server
    console.log("Submitted", data);
    try {
      const response = await saveTax(data);
      if (response) {
        let resultedData: TaxProps = Object.keys(data).reduce((obj, key) => {
          obj[key as keyof TaxProps] = "";
          return obj;
        }, {} as TaxProps);
        setData(resultedData);
        toast.success(response.data.message);
        setShowSideNav(!showSideNav);
      }
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    id: Joi.optional().allow(""),
    created_date: Joi.optional().allow(""),
    tax: Joi.string().required().label("Tax"),
    title: Joi.string().required().label("Title"),
    status: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
  };

  const { renderInput, renderButton, handleSubmit } = Form<TaxProps>({
    data,
    setData,
    errors,
    setErrors,
    schema,
    onSubmit: doSubmit,
  });

  useEffect(() => {
    const buttonElement = document.querySelector(".forms-sample .btn");
    if (buttonElement) {
      buttonElement.classList.add("btn-gradient-primary");
    }
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit} className="forms-sample">
        {renderInput("title", 'Title <span class="text-danger">*</span>')}
        {renderInput("tax", 'Tax <span class="text-danger">*</span>')}

        {renderButton("Save")}
        <p>&nbsp;</p>
      </form>
    </>
  );
}

export default TaxForm;
