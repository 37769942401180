import React, { useState, useEffect } from "react";
import { AuthorizedProps } from "../../types/authorizedProps";
import { CountryProps } from "../../types/countryProps";
import { StateProps } from "../../types/stateProps";
import { getCustomers } from "../../services/customerService";
import Form from "../../common/form";
import Joi from "joi-browser";
import { getCountries } from "../../services/countryService";
import findItemByProperty from "../../utils/findItem";
import { getStates } from "../../services/stateService";
import { saveAuthorized } from "../../services/authorizedService";
import { toast } from "react-toastify";

type Props = {
  customer_name: string;
  fetchAuthorize: (customer_name: string) => Promise<void>;
};

interface Errors {
  [key: string]: string | undefined;
}

function AuthModal({ customer_name, fetchAuthorize }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const initialData: AuthorizedProps = {
    id: "",
    customer_id: "",
    customer_name: "",
    auth_name: "",
    auth_email: "",
    auth_mobile: "",
    auth_kyc: "",
    auth_profile_pic: "",
    designation: "",
    country_id: "",
    country_name: "",
    state_id: "",
    state_name: "",
    city_name: "",
    address: "",
    pincode: "",
    status: "",
    created_date: "",
    updated_date: "",
  };
  const [data, setData] = useState<AuthorizedProps>(initialData);
  const [errors, setErrors] = useState<Errors>({});
  const [countries, setCountries] = useState<CountryProps[]>([]);
  const [states, setStates] = useState<StateProps[]>([]);
  const [base64Image, setBase64Image] = useState<string>("");

  const handleOpen = () => {
    setOpen(!open);
  };

  const fetchCountry = async () => {
    try {
      const response = await getCountries();
      const newCountries = response.data.country_list.map(
        ({
          country_name: name,
          ...rest
        }: {
          country_name: string;
          [key: string]: any;
        }) => ({
          name,
          ...rest,
        })
      );
      setCountries(newCountries);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  const fetchStates = async (country_name: string) => {
    try {
      const response = await getStates();
      const newStates = response.data.state_list.map(
        ({
          state_name: name,
          ...rest
        }: {
          state_name: string;
          [key: string]: any;
        }) => ({
          name,
          ...rest,
        })
      );
      const filtered = newStates.filter(
        (item: StateProps) => item.country_name === country_name
      );

      setStates(filtered);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  const handleSetData = async (cutsomer_name: string) => {
    if (cutsomer_name) {
      let redirecData = {
        employee_id: "",
        branch_id: "",
      };
      const response = await getCustomers(redirecData);
      const foundCustomer = response.data.customer_list.find(
        (item: any) => item.name === customer_name
      );
      setData({
        ...data,
        customer_id: foundCustomer?.id,
        customer_name: foundCustomer?.name,
      });

      handleOpen();
    }
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const doSubmit = async (): Promise<void> => {
    // Call the server
    console.log("Submitted", data);
    try {
      // get Country Data
      const country = await getCountries();
      const foundCountry = findItemByProperty(
        country.data.country_list,
        "country_name",
        data.country_name
      );
      const countryData = {
        country_id: foundCountry?.id,
        country_name: foundCountry?.country_name,
      };

      // Get State Data
      const state = await getStates();
      const foundState = findItemByProperty(
        state.data.state_list,
        "state_name",
        data.state_name
      );
      const stateData = {
        state_id: foundState?.id,
        state_name: foundState?.state_name,
      };

      let resultedData = {
        ...data,
        ...countryData,
        ...stateData,
      };

      const response = await saveAuthorized(resultedData);
      if (response) {
        fetchAuthorize(data.customer_name);
        let emptyData: AuthorizedProps = Object.keys(data).reduce(
          (obj, key) => {
            obj[key as keyof AuthorizedProps] = "";
            return obj;
          },
          {} as AuthorizedProps
        );
        setData(emptyData);
        handleClose();
        toast.success(response.data.message);
      }
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleUplolad = () => {
    if (data.auth_name !== "") {
      doSubmit();
    } else {
      toast.error("Please Enter Authorize person name");
    }
  };

  const schema = {
    id: Joi.optional().allow(""),
    customer_id: Joi.optional().allow(""),
    customer_name: Joi.optional().allow(""),
    auth_name: Joi.string().required().label("Authorize Person Name"),
    auth_email: Joi.string().required().label("Authorize Person Email"),
    auth_mobile: Joi.string().required().label("Authorize Mobile No."),
    auth_kyc: Joi.string().required().label("Authorize KYC"),
    auth_profile_pic: Joi.string().required().label("Authorize Person Pic"),
    designation: Joi.optional().allow(""),
    country_id: Joi.optional().allow(""),
    country_name: Joi.string().required().label("Country Name"),
    state_id: Joi.optional().allow(""),
    state_name: Joi.string().required().label("State Name"),
    city_name: Joi.string().required().label("City Name"),
    address: Joi.string().required().label("Address"),
    pincode: Joi.string().required().label("Pincode"),
    status: Joi.optional().allow(""),
    created_date: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
  };

  const { renderInput, renderSelect, renderFile, handleSubmit } =
    Form<AuthorizedProps>({
      data,
      setData,
      errors,
      setErrors,
      schema,
      base64Image,
      setBase64Image,
      onSubmit: doSubmit,
    });

  useEffect(() => {
    fetchCountry();
  }, []);

  useEffect(() => {
    if (data.country_name) fetchStates(data.country_name);
  }, [data.country_name]);

  return (
    <>
      <span
        className="badge badge-primary badge-sm"
        onClick={() => handleSetData(customer_name)}
      >
        +
      </span>
      <div
        className={open ? "modal fade show" : "modal fade"}
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Add Authorize Person
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} className="form-horizontal">
                <div className="row">
                  <div className="col-md-4">
                    {renderInput(
                      "auth_name",
                      'Name <span class="text-danger">*</span>'
                    )}
                  </div>
                  <div className="col-md-4">
                    {renderInput(
                      "designation",
                      'Designation <span class="text-danger">*</span>'
                    )}
                  </div>
                  <div className="col-md-4">
                    {renderFile(
                      "auth_kyc",
                      'Upload KYC <span class="text-danger">*</span>',
                      "file"
                    )}
                  </div>
                  <div className="col-md-4">
                    {renderInput(
                      "auth_email",
                      'Email <span class="text-danger">*</span>'
                    )}
                  </div>
                  <div className="col-md-4">
                    {renderInput(
                      "auth_mobile",
                      'Phone <span class="text-danger">*</span>'
                    )}
                  </div>
                  <div className="col-md-4">
                    {renderFile(
                      "auth_profile_pic",
                      'Profile Image <span class="text-danger">*</span>',
                      "file"
                    )}
                  </div>
                  <div className="col-md-4">
                    {renderSelect(
                      "country_name",
                      'Country Name <span class="text-danger">*</span>',
                      countries
                    )}
                  </div>
                  <div className="col-md-4">
                    {renderSelect(
                      "state_name",
                      'State Name <span class="text-danger">*</span>',
                      states
                    )}
                  </div>
                  <div className="col-md-4">
                    {renderInput(
                      "city_name",
                      'City Name <span class="text-danger">*</span>'
                    )}
                  </div>

                  <div className="col-md-9">
                    {renderInput(
                      "address",
                      'Address <span class="text-danger">*</span>'
                    )}
                  </div>
                  <div className="col-md-3">
                    {renderInput(
                      "pincode",
                      'Pincode <span class="text-danger">*</span>'
                    )}
                  </div>
                </div>
                <button
                  type="button"
                  onClick={handleUplolad}
                  className="btn btn-primary"
                >
                  Upload
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className={open ? "modal-backdrop " : ""}
        style={{ display: open ? "block" : "none" }}
      ></div>
    </>
  );
}

export default AuthModal;
