import React from "react";
import QRCode from "react-qr-code";
import dark from "../../assets/images/logo-2.png";

type Props = { data: any[] };

function A4Size({ data }: Props) {
  return (
    <>
      <section className="">
        {" "}
        {data?.map((item) => (
          <>
            <section className="certificate">
              <div className="topcrt-header">
                <img src={dark} alt="IDT" />
                <h4>IDT GEMOLOGICAL LABORATORIES WORLDWIDE</h4>
                <h5>
                  2396/11,2nd Floor Gurudwara Road | Karol Bagh | New Delhi |
                  11005{" "}
                </h5>
                <h6>
                  T +911145615555 | Helpline +919877199999 | E
                  info@idtworldwide.com | www.idtworldwide.com
                </h6>
                <h3>GEM TESTING REPORT</h3>
              </div>
              <div className="cert-box">
                <p>Certificate No: {item.certificate_number}</p>
                <p>{new Date(item.created_date).toDateString()}</p>
              </div>
              <div className="labrt-box">
                <h6>LABORATORY REPORT (ORIGINAL)</h6>
                <h6>TO WHOM IT MAY CONCERN</h6>
              </div>
              <div className="descrpt-box">
                <h6>{item.article_subtype_name}</h6>
                <h5>{item.description}</h5>
              </div>
              <div className="gross-wt">
                <ul>
                  <li>
                    <h6>Stone Weight</h6>
                  </li>
                  <li>
                    <p>{item.weight} Cts.</p>
                  </li>
                  {item.shape_title && (
                    <>
                      <li>
                        <h6>Shape</h6>
                      </li>
                      <li>
                        <p>{item.shape_title}</p>
                      </li>
                    </>
                  )}

                  {item.measurements && (
                    <>
                      <li>
                        <h6>Measurement</h6>
                      </li>
                      <li>
                        <p>{item.measurements}</p>
                      </li>
                    </>
                  )}

                  {item.color && (
                    <>
                      <li>
                        <h6>Color</h6>
                      </li>
                      <li>
                        <p>{item.color}</p>
                      </li>
                    </>
                  )}

                  {item.optic_character && (
                    <>
                      <li>
                        <h6>Optic Character</h6>
                      </li>
                      <li>
                        <p>{item.optic_character}</p>
                      </li>
                    </>
                  )}

                  {item.refractive_index && (
                    <>
                      <li>
                        <h6>Refractive Index</h6>
                      </li>
                      <li>
                        <p>{item.refractive_index}</p>
                      </li>
                    </>
                  )}

                  {item.spcific_gravity && (
                    <>
                      <li>
                        <h6>Specific Gravity</h6>
                      </li>
                      <li>
                        <p>{item.spcific_gravity}</p>
                      </li>
                    </>
                  )}

                  {item.magnification && (
                    <>
                      <li>
                        <h6>Magnifictaion</h6>
                      </li>
                      <li>
                        <p>{item.magnification}</p>
                      </li>
                    </>
                  )}

                  {item.comments && (
                    <>
                      <li>
                        <h6>Comments </h6>
                      </li>
                      <li>
                        <p>{item.comments}</p>
                      </li>
                    </>
                  )}
                </ul>

                <div className="gross-wt-right">
                  <img src={item.article_photo} />
                  <h5>Photo Approx.</h5>
                </div>
              </div>

              <div className="weightclc">
                <span>
                  The Gem Stone Report has Been conclude using standard
                  international Methods.Observations which are not applicable or
                  needded are not performed.
                </span>
                <h5>Gem Classifcation by Origin</h5>
                <ul>
                  <li>
                    <h6>Natural Gem </h6>
                  </li>
                  <li>
                    <p>
                      A gem material which is entirely the product of natuer,
                      eg.Diamond, Corundum, Beryl, Quartz, Garnet, Tourmaline,
                      Topaz, etc.
                    </p>
                  </li>
                  <li>
                    <h6>Enhaced or Treared gem</h6>
                  </li>
                  <li>
                    <p>
                      Any gem material (natural or otherwise), which has been
                      altered by humans. Beyond ordinary cutting and polishing,
                      eg. Fracture Filled Ruby etc.
                    </p>
                  </li>
                  <li>
                    <h6>Synthetic gem</h6>
                  </li>
                  <li>
                    <p>
                      Some as natural gem (in terms of composition,
                      Structure,properties and appearance),except made by humans
                      in laboratory, eg. Synthetic Emerald, Synthetic Spinel,
                      Synthetic Sapphire,Synthetic Cubic Zirconia, etc.
                    </p>
                  </li>
                  <li>
                    <h6>Assembled gem</h6>
                  </li>
                  <li>
                    <p>
                      A gem material (natural or otherwise) which has been
                      altered by humans.Beyond ordinary cutting and
                      polishing.eg. Fracture Filled Ruby etc.
                    </p>
                  </li>
                  <li>
                    <h6>Imitation</h6>
                  </li>
                  <li>
                    <p>
                      A material (natural or otherwise) Which has the same
                      appearance as Gem it imitates, eg. Glass as an imitation
                      of Diamond,Red Spinel as an imitation of Ruby etc.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="secuty-bx">
                <div className="secuty-bx-left">
                  <QRCode
                    size={128}
                    style={{
                      height: "auto",
                      maxWidth: "100%",
                      width: "45px",
                      marginBottom: "20px",
                      // position: "absolute",
                      // right: 0,
                    }}
                    value={item?.barcode_number}
                    viewBox={`0 0 256 256`}
                  />
                  <p>
                    Security Features included in this document are QR
                    Code,watermarked paper and additional features not
                    listed,that,as a Composite,exceed indsutry security
                    standards.
                  </p>
                </div>
                <div className="secuty-bx-center">
                  <h4>Vikram Thapar</h4>
                </div>
                <div className="secuty-bx-right">
                  <h4>Vikram Thapar</h4>
                  <h5>Gemologist</h5>
                </div>
              </div>
              <div className="footer-crt">
                <p>
                  The gemological analysis of diamonds, precious stone and other
                  minerals must be carried out by gemologist with many years
                  experience in this fields who have a keen sense of the
                  professional code of ethics governing their work as well as a
                  through knowledge of crystallographic,optical and physical
                  phenomenom. The identification of the various species and
                  varieties of stone, the distinction between natural and
                  synthetic material, as well as various treatment method
                  currently encountered are all very sensitive factors. More
                  specifically for diamonds, the laws of refraction and
                  dispersion of light the related geometric data as well as
                  knowledge of all aspect involved in the cutting process are
                  essential.
                </p>
                <p>
                  This gemological report is provided upon request of the
                  customer and/or the owner of the gem, By making this report
                  IDT does not agree to purchase or replace the artical neither
                  IDT not any member of its staff shall, at any time, be held
                  resonsible for any discreapancy which may result from the
                  application of other grading methods.Neither the client nor
                  any purchase of the gem shall regard this report as an
                  apporiasal nor as an appraisal nor as guranty or waranty.This
                  test report relates only to the item tested and is subject to
                  the terms and conditions set forth above and on the reverse.
                  <br />
                  @IDT2021 All rights reserved.No part of this report may be
                  reproduced transmitted in any form or by any means without
                  permission in writing from IDT Gemologicla Laboratories
                  Worldwide{" "}
                </p>
              </div>
            </section>
          </>
        ))}
      </section>
    </>
  );
}

export default A4Size;
