import http from "./httpService";
import { apiUrl } from "../config.js";
import { LaboratoryDetailsProps } from "./../types/laboratoryDetailsProps";

const lotLabEndpoint = apiUrl + "/create_update_lab";
const lotBulkLabEndpoint = apiUrl + "/create_update_bulk_lab";

// Get the Labs Lots Data

export async function getLabLots(lot: { [key: string]: any }): Promise<any> {
  try {
    return await http.post(apiUrl + "/lab_lot_list", {
      branch_id: lot.branch_id,
      employee_id: lot.employee_id,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving lab lots data:", error);
    // throw error;
  }
}

export async function getLabLot(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_article_lab", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving lab lot data:", error);
    // throw error;
  }
}

// //  Create and Update Lab Lot

export function saveLabLot(lot: LaboratoryDetailsProps) {
  return http.post(lotLabEndpoint, lot);
}

export function saveBulkLabLot(lot: any) {
  return http.post(lotBulkLabEndpoint, lot);
}

// Update the status of the Laboratory Data

export async function statusLaboratory(laboratory: any) {
  console.log("laboratory", laboratory);

  try {
    return await http.post(apiUrl + "/update_status", laboratory);
  } catch (error) {
    // Handle error
    console.error("Error retrieving article data:", error);
    // throw error;
  }
}
// Update the track status of the Laboratory Data

export async function trackLaboratory(laboratory: any) {
  try {
    return await http.post(apiUrl + "/update_bin", laboratory);
  } catch (error) {
    // Handle error
    console.error("Error retrieving article data:", error);
    // throw error;
  }
}

// // Delete the Article Data

// export async function deleteArticle(id: string): Promise<any> {
//   try {
//     return await http.post(apiUrl + "/delete_article", { id: id });
//   } catch (error) {
//     // Handle error
//     console.error("Error retrieving article data:", error);
//     // throw error;
//   }
// }
