import React, { useEffect, useState } from "react";
import { ToastContent, toast } from "react-toastify";
import Joi from "joi-browser";
import Form from "../../common/form";
import { LaboratoryDetailsProps } from "../../types/laboratoryDetailsProps";
import { getLabLot, saveLabLot } from "../../services/lotLabService";
import { ShapeMetalProps } from "../../types/ShapeMetalProps";
import { getMetals } from "../../services/shapeMetalService";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";

type Props = {
  array: any;
  setArray: React.Dispatch<React.SetStateAction<any>>;
  currentIndex: number | undefined;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  detailsFields: any[];
};

interface Errors {
  [key: string]: string | undefined;
}

type ArrayProps = {
  id: string;
  name: string;
};

const RhodiumTypeArray: ArrayProps[] = [
  {
    id: "0",
    name: "Rhodium Plated",
  },
  {
    id: "1",
    name: "Partly Rhodium Plated",
  },
];
const FinishTypeArray: ArrayProps[] = [
  {
    id: "0",
    name: "Good",
  },
  {
    id: "1",
    name: "Very Good",
  },
  {
    id: "2",
    name: "Excellent",
  },
];

const StatusArray: ArrayProps[] = [
  {
    id: "1",
    name: "Approved",
  },
  {
    id: "2",
    name: "Rejected by Lab Testing",
  },
  {
    id: "3",
    name: "Takenback Photography",
  },
  {
    id: "4",
    name: "Takenback Screening",
  },
];
const CommentArray1: ArrayProps[] = [
  {
    id: "1",
    name: "Grading & Identification of Origin as mounting permits.",
  },
];

const CommentArray2: ArrayProps[] = [
  {
    id: "1",
    name: "Screened for CVD/HPHT/ Synthetics",
  },
];

function Modal({
  array,
  setArray,
  detailsFields,
  currentIndex,
  setCurrentIndex,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const initialData: LaboratoryDetailsProps = {
    id: "",
    lot_id: "",
    lot_number: "",
    article_id: "",
    article_type: "",
    criteria: "",
    rhodium: "",
    gold_weight: "",
    carat_weight: "",
    dia_weight: "",
    no_dia: "",
    color: "",
    clarity: "",
    finish: "",
    design: "",
    metal: "",
    item: "",
    comment1: "Grading & Identification of Origin as mounting permits.",
    comment2: "Screened for CVD/HPHT/ Synthetics",
    status: "",
    created_date: "",
    updated_date: "",
    form_status: false,
  };

  const [data, setData] = useState<LaboratoryDetailsProps>(initialData);
  const [metals, setMetals] = useState<ShapeMetalProps[]>([]);
  const [errors, setErrors] = useState<Errors>({});
  const user = useSelector((state: UserState) => state.userReducer);

  const fetchMetals = async () => {
    try {
      const response = await getMetals();
      if (response?.data?.metal_type_list) {
        const newMetals = response.data.metal_type_list.map(
          ({
            title: name,
            ...rest
          }: {
            title: string;
            [key: string]: any;
          }) => ({
            name,
            ...rest,
          })
        );
        setMetals(newMetals);
      }
    } catch (error) {
      console.error("Error fetching metal type list:", error);
    }
  };

  useEffect(() => {
    fetchMetals();
  }, []);

  const PopulateData = async (id: string, data: LaboratoryDetailsProps) => {
    if (id) {
      try {
        const response = await getLabLot(id);
        console.log("response", response);

        if (response.status === 206) {
          let resultedData = response.data;
          setData(resultedData);
          return;
        } else {
          let resultedData: LaboratoryDetailsProps = Object.keys(data).reduce(
            (obj, key) => {
              obj[key as keyof LaboratoryDetailsProps] = "";
              return obj;
            },
            {} as LaboratoryDetailsProps
          );

          let updatedData = {
            ...resultedData,
            item: response.data.article_name,
            gold_weight: response.data.weight,
            no_dia: response.data.no_of_diamond,
            carat_weight: response.data.carat_weight,
            form_status: response.data.form_status,
            design: response.data.design_no,
            color: response.data.color,
            clarity: response.data.clarity,
            finish: response.data.finish,
            comment1: "Grading & Identification of Origin as mounting permits.",
            comment2: "Screened for CVD/HPHT/ Synthetics",
            status:
              response.data.status === "In Laboratory"
                ? ""
                : response.data.status,
          };
          console.log("updatedData", updatedData);
          setData(updatedData);
        }
      } catch (error) {
        const errorMessage: ToastContent<unknown> =
          error as ToastContent<unknown>;
        toast.error(errorMessage);
      }
    }
  };

  const mapToViewModel = ({ ...rest }: any): any => ({ ...rest });

  useEffect(() => {
    if (array) PopulateData(array.id, data);
  }, [array]);

  const handleClose = () => {
    setOpen(!open);
    setArray([]);
  };

  const doSubmit = async (): Promise<void> => {
    // Call the server
    console.log("Submitted", data);
    try {
      const articleData = {
        article_id: array?.id,
        article_type: array?.article_name,
        lot_id: array?.lot_id,
        lot_number: array?.lot_number,
      };

      let resultedData = { ...data, ...articleData };

      const response = await saveLabLot(resultedData);
      toast.success(response.data.message);
      setCurrentIndex((prevIndex: any) => {
        if (prevIndex !== undefined) {
          setArray(detailsFields[prevIndex + 1]);
          return prevIndex + 1;
        }
        return undefined;
      });
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    id: Joi.optional().allow(""),
    lot_id: Joi.optional().allow(""),
    lot_number: Joi.optional().allow(""),
    article_id: Joi.optional().allow(""),
    article_type: Joi.optional().allow(""),
    criteria: Joi.optional().allow(""),
    rhodium: Joi.optional().allow(""),
    gold_weight: Joi.optional().allow(""),
    carat_weight: Joi.optional().allow(""),
    dia_weight: Joi.optional().allow(""),
    no_dia: Joi.optional().allow(""),
    color: Joi.optional().allow(""),
    clarity: Joi.optional().allow(""),
    finish: Joi.optional().allow(""),
    design: Joi.optional().allow(""),
    metal: Joi.optional().allow(""),
    item: Joi.optional().allow(""),
    comment1: Joi.optional().allow(""),
    comment2: Joi.optional().allow(""),
    status: Joi.optional().allow(""),
    created_date: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
    form_status: Joi.optional().allow(""),
  };

  const { renderInput, renderSelect, handleSubmit } =
    Form<LaboratoryDetailsProps>({
      data,
      setData,
      errors,
      setErrors,
      schema,
      onSubmit: doSubmit,
    });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-3">
            {renderInput("item", 'Item <span class="text-danger">*</span>')}
          </div>
          <div className="col-md-3">
            {renderSelect(
              "metal",
              'Metal <span class="text-danger">*</span>',
              metals
            )}
          </div>
          <div className="col-md-3">
            {renderInput("design", 'Design <span class="text-danger">*</span>')}
          </div>
          <div className="col-md-3">
            {renderInput("color", 'Color <span class="text-danger">*</span>')}
          </div>

          <div className="col-md-4">
            {renderSelect(
              "rhodium",
              'Rhodium <span class="text-danger">*</span>',
              RhodiumTypeArray
            )}
          </div>
          <div className="col-md-4">
            {renderInput(
              "clarity",
              'Clarity <span class="text-danger">*</span>'
            )}
          </div>
          <div className="col-md-4">
            {renderSelect(
              "finish",
              'Finish <span class="text-danger">*</span>',
              FinishTypeArray
            )}
          </div>
          <div className="col-md-4">
            {renderInput(
              "gold_weight",
              'Gold Weight <span class="text-danger">*</span>'
            )}
          </div>
          <div className="col-md-4">
            {renderInput(
              "carat_weight",
              'Caret Weight <span class="text-danger">*</span>'
            )}
          </div>
          {/* <div className="col-md-3">
                  {renderInput(
                    "dia_weight",
                    'DIA Weight <span class="text-danger">*</span>'
                  )}
                </div> */}
          <div className="col-md-4">
            {renderInput(
              "no_dia",
              'No of DIA <span class="text-danger">*</span>'
            )}
          </div>
          <div className="col-md-4">
            {renderSelect("comment1", "Comment", CommentArray1)}
          </div>
          <div className="col-md-4">
            {renderSelect("comment2", "Comment", CommentArray2)}
          </div>
          <div className="col-md-4">
            {renderSelect(
              "status",
              'Status <span class="text-danger">*</span>',
              StatusArray
            )}
          </div>
        </div>
        <div className="modal-footer">
          {data && (
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                display:
                  (user && user.department_name === "Admin") ||
                  data.form_status === false
                    ? "block"
                    : "none",
              }}
            >
              Submit
            </button>
          )}
        </div>
      </form>
    </>
  );
}

export default Modal;
