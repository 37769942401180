import http from "./httpService";
import { apiUrl } from "../config.js";
import { CountryProps } from "../types/countryProps";

const countryEndpoint = apiUrl + "/create_update_country";

// Get the country data

export async function getCountries(): Promise<any> {
  try {
    return await http.get(apiUrl + "/country_list");
  } catch (error) {
    // Handle error
    console.error("Error retrieving countries data:", error);
    // throw error;
  }
}
export async function getCountry(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_country", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving country data:", error);
    throw error;
  }
}

//  Create and Update Country

export function saveCountry(country: CountryProps) {
  return http.post(countryEndpoint, country);
}

// Update the status of the country data

export async function statusCountry(id: string, status: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/change_country_status", {
      id: id,
      status: status,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving country data:", error);
    throw error;
  }
}

// Delete the country data

export async function deleteCountry(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/delete_country", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving country data:", error);
    throw error;
  }
}
