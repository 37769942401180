import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Joi from "joi-browser";
import "./EmployForm.css";
import { ToastContent, toast } from "react-toastify";
import { EmployProps } from "../../types/employeeProps";
import { getBranches } from "../../services/branchService";
import { RoleProps } from "../../types/roleProps";
import { BranchProps } from "../../types/branchProps";
import { DepartmentProps } from "../../types/departmentProps";
import { getDepartments } from "../../services/departmentService";
import { getRoles } from "../../services/roleService";
import { getEmploy, saveEmployee } from "../../services/employeeService";
import findItemByProperty from "../../utils/findItem";
import Form from "../../common/form";
import Header from "./Header";
import Input from "./../../common/input";

type Props = {};

interface Errors {
  [key: string]: string | undefined;
}

type ArrayProps = {
  id: string;
  name: string;
};

// const GenderArray: ArrayProps[] = [
//   {
//     id: "0",
//     name: "Male",
//   },
//   {
//     id: "1",
//     name: "Female",
//   },
//   {
//     id: "1",
//     name: "Other",
//   },
// ];

const DepartmentArray: ArrayProps[] = [
  {
    id: "0",
    name: "Front Office",
  },
  {
    id: "1",
    name: "Back Office",
  },
  {
    id: "2",
    name: "Laboratory",
  },
  {
    id: "3",
    name: "Accounts",
  },
  {
    id: "4",
    name: "Administrator",
  },
  {
    id: "5",
    name: "Engraving",
  },
];

function EmployForm({}: Props) {
  const initialData: EmployProps = {
    id: "",
    branch_id: "",
    branch_name: "",
    employee_branch: "",
    department_id: "",
    department_name: "",
    employee_id: "",
    name: "",
    email: "",
    mobile: "",
    role_name: "",
    password: "",
    confirm_password: "",
    dob: "",
    gender: "",
    address: "",
    profile_pic: "",
    aadhar_card: "",
    pan_card: "",
    status: "",
    created_by: "",
    updated_by: "",
    updated_date: "",
    created_date: "",
  };

  const [branches, setBranches] = useState<BranchProps[]>([]);
  // const [departments, setDepartments] = useState<DepartmentProps[]>([]);
  const [roles, setRoles] = useState<RoleProps[]>([]);
  const [errorPassword, setErrorPassword] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<
    { id: string; name: string }[]
  >([]);
  const location = useLocation();
  const params = useParams();

  const fetchBranch = async () => {
    try {
      const response = await getBranches();
      const newBranch = response.data.branch_list.map(
        ({
          branch_name: name,
          ...rest
        }: {
          branch_name: string;
          [key: string]: any;
        }) => ({
          name,
          ...rest,
        })
      );
      let branch_list = newBranch.map((branch: any) => {
        return {
          id: branch.id,
          name: branch.name,
        };
      });
      branch_list.sort((a: any, b: any) => a.name.localeCompare(b.name));

      setBranches(branch_list);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  // const fetchDepartment = async (branch_name: string) => {
  //   try {
  //     const response = await getDepartments();
  //     const newDepartment = response.data.department_list.map(
  //       ({
  //         department_name: name,
  //         ...rest
  //       }: {
  //         department_name: string;
  //         [key: string]: any;
  //       }) => ({
  //         name,
  //         ...rest,
  //       })
  //     );
  //     const filtered = newDepartment.filter(
  //       (item: BranchProps) => item.branch_name === branch_name
  //     );
  //     setDepartments(filtered);
  //   } catch (error) {
  //     console.error("Error fetching department list:", error);
  //   }
  // };

  const fetchRole = async (department_name: string) => {
    try {
      const response = await getRoles();
      const newRoles = response.data.role_list.map(
        ({
          role_name: name,
          ...rest
        }: {
          role_name: string;
          [key: string]: any;
        }) => ({
          name,
          ...rest,
        })
      );
      const filtered = newRoles.filter(
        (item: DepartmentProps) => item.department_name === department_name
      );
      setRoles(filtered);
    } catch (error) {
      console.error("Error fetching role list:", error);
    }
  };

  const [data, setData] = useState<EmployProps>(initialData);
  const [base64Image, setBase64Image] = useState<string>("");

  const [errors, setErrors] = useState<Errors>({});

  const PopulateData = useCallback(async (id: string, branches: any) => {
    try {
      const dataId = id;
      if (dataId === "new") {
        let resultedData: EmployProps = Object.keys(data).reduce((obj, key) => {
          obj[key as keyof EmployProps] = "";
          return obj;
        }, {} as EmployProps);
        setData(resultedData);
      } else {
        const { data: response } = await getEmploy(dataId);
        let employ_branch = response.employee_branch?.map((e_b: any) => {
          let b = branches.find((b: any) => b.name === e_b.branch_name);
          return {
            id: b?.id,
            name: b?.name,
          };
        });
        let result = await {
          ...response,
          confirm_password: response?.password,
          employee_branch: employ_branch,
        };
        setData(result);
      }
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  }, []);

  useEffect(() => {
    if (data.password != data.confirm_password) {
      setErrorPassword("Password and confirm password must be same");
    }
  }, [data.confirm_password]);

  useEffect(() => {
    fetchBranch();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (params.id) {
      // Delay the execution of PopulateData for 3 seconds
      const timeoutId = setTimeout(() => {
        PopulateData(params.id as string, branches);
      }, 2000);

      return () => {
        clearTimeout(timeoutId); // Clear the timeout if the component unmounts before 3 seconds
        controller.abort();
      };
    }
  }, [params.id, branches]);

  const handlePrev = () => {
    // Programmatically navigate to a different route
    const lastSlashIndex = location.pathname.lastIndexOf("/");
    const newPathname = location.pathname.substring(0, lastSlashIndex + 1);

    let redirectUrl = (process.env.REACT_APP_liveURL + newPathname) as string;
    if (process.env.NODE_ENV === "development") {
      redirectUrl = (process.env.REACT_APP_localURL + newPathname) as string;
    }
    window.location.href = redirectUrl; // No need for type assertion
  };

  const doSubmit = async (): Promise<void> => {
    // Call the server
    console.log("Submitted", data);
    try {
      let branchData = data.employee_branch.map((item: any) => {
        return { branch_id: item.id, branch_name: item.name };
      });

      let resultedData = {
        ...data,
        employee_branch: branchData,
      };
      if (
        resultedData.password !== resultedData.confirm_password ||
        resultedData.confirm_password === ""
      ) {
        setErrorPassword("Password and confirm password must be the same");
        return;
      }

      const response = await saveEmployee(resultedData);
      toast.success(response.data.message);
      handlePrev();
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    aadhar_card: Joi.optional().allow(""),
    address: Joi.string().required().label("Address"),
    branch_id: Joi.optional().allow(""),
    branch_name: Joi.optional().allow(""),
    employee_branch: Joi.array().label("Branch Name"),
    created_by: Joi.optional().allow(""),
    created_date: Joi.optional().allow(""),
    department_id: Joi.optional().allow(""),
    department_name: Joi.string().required().label("Department Name"),
    dob: Joi.optional().allow(""),
    employee_id: Joi.string().required().label("Employee ID"),
    email: Joi.string().required().label("Email"),
    role_name: Joi.string().required().label("Role"),
    gender: Joi.optional().allow(""),
    id: Joi.optional().allow(""),
    mobile: Joi.string().required().label("Phone"),
    name: Joi.string().required().label("Name"),
    pan_card: Joi.optional().allow(""),
    password: Joi.string().min(6).required().label("Password"),
    confirm_password: Joi.optional().allow(""),
    profile_pic: Joi.optional().allow(""),
    status: Joi.optional().allow(""),
    updated_by: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
  };

  const {
    renderFile,
    renderInput,
    renderInputArea,
    renderButton,
    renderSelect,
    renderMultipleSelect,
    handleSubmit,
  } = Form<EmployProps>({
    data,
    setData,
    errors,
    setErrors,
    schema,
    base64Image,
    setBase64Image,
    selectedOptions,
    setSelectedOptions,
    onSubmit: doSubmit,
  });

  const formSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (
      data.password !== data.confirm_password ||
      data.confirm_password === ""
    ) {
      setErrorPassword("Password and confirm password must be the same");
      handleSubmit(e);
    }
    handleSubmit(e);
  };
  useEffect(() => {
    const buttonElement = document.querySelector(".form-horizontal .btn");
    if (buttonElement) {
      buttonElement.classList.add("btn-svs");
    }
  }, []);

  // useEffect(() => {
  //   if (data.branch_name) fetchDepartment(data.branch_name);
  // }, [data.branch_name]);

  useEffect(() => {
    if (data.department_name) fetchRole(data.department_name);
  }, [data.department_name]);

  console.log("data", data);

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper ">
          <div className="main-panel ">
            <div className="content-wrapper ">
              <Header />

              <form onSubmit={formSubmit} className="form-horizontal">
                <div className="row ">
                  <div className="col-12 grid-margin ">
                    <div className="card ">
                      <div className="card-body">
                        <div className="form-sample">
                          <p className="card-description"> Employee Details</p>
                          <div className="row">
                            <div className="col-md-12">
                              {renderMultipleSelect(
                                "employee_branch",
                                'Branch Name <span class="text-danger">*</span>',
                                branches
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderSelect(
                                "department_name",
                                'Department Name <span class="text-danger">*</span>',
                                DepartmentArray
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderSelect(
                                "role_name",
                                'Employ Role <span class="text-danger">*</span>',
                                roles
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderInput(
                                "employee_id",
                                'Employee ID <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderInput(
                                "name",
                                'Name <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderInput(
                                "email",
                                'Email <span class="text-danger">*</span>',
                                "email"
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderInput(
                                "mobile",
                                'Phone Number <span class="text-danger">*</span>',
                                "tel"
                              )}
                            </div>
                            <div className="col-md-6">
                              {renderInput(
                                "password",
                                'Password <span class="text-danger">*</span>',
                                "password"
                              )}
                            </div>
                            <div className="col-md-6">
                              <Input
                                name="confirm_password"
                                label='Confirm Password <span class="text-danger">*</span>'
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    confirm_password: e.target.value,
                                  })
                                }
                                value={data.confirm_password}
                                type="password"
                                error={
                                  data.confirm_password == "" ||
                                  data.confirm_password != data.password
                                    ? errorPassword
                                    : ""
                                }
                              />
                            </div>
                            {/* <div className="col-md-6">
                              {renderInput(
                                "dob",
                                'Date of Birth <span class="text-danger">*</span>',
                                "date"
                              )}
                            </div> */}
                            {/* <div className="col-md-6">
                              {renderSelect(
                                "gender",
                                'Gender <span class="text-danger">*</span>',
                                GenderArray
                              )}
                            </div> */}
                            <div className="col-md-12">
                              {renderInputArea(
                                "address",
                                'Address <span class="text-danger">*</span>'
                              )}
                            </div>
                            {/* <div className="col-md-6">
                              {renderFile(
                                "profile_pic",
                                `Select Profile Pic :- `,
                                "file"
                              )}
                            </div> */}
                            {/* <div className="col-md-6">
                              {renderFile(
                                "aadhar_card",
                                `Select Aadhar Card :- `,
                                "file"
                              )}
                            </div> */}
                            {/* <div className="col-md-6">
                              {renderFile(
                                "pan_card",
                                `Select Pan Card :- `,
                                "file"
                              )}
                            </div> */}
                          </div>
                          <p>&nbsp;</p>
                        </div>
                        <div
                          className="row "
                          style={{ width: "271px", margin: "21px auto" }}
                        >
                          {renderButton("Save")}
                          <button
                            type="button"
                            onClick={handlePrev}
                            className="btn btn-cnc"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployForm;
