import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Joi from "joi-browser";
import "./LotForm.css";
import { ToastContent, toast } from "react-toastify";
import Form from "../../common/form";
import findItemByProperty from "../../utils/findItem";
import { LotProps } from "../../types/lotProps";
import { getLot, saveLot } from "../../services/lotService";
import Header from "./Header";
import { CustomerProps } from "../../types/customerProps";
import { getCustomer, getCustomers } from "../../services/customerService";
import ArticleDetails from "./ArticleDetails";
import { AuthorizedProps } from "../../types/authorizedProps";
import { getAuthorizedPersons } from "../../services/authorizedService";
import AuthModal from "./AuthModal";
import { useSelector } from "react-redux";
import { UserState } from "../../types/userTypes";
import { getServiceTypes } from "../../services/serviceTypeService";
import { getArticles } from "../../services/articleService";
import { initialFields } from "./InitialFields";
import InputCustom from "./../../common/inputCustom";
import Select from "../../common/select";

interface Errors {
  [key: string]: string | undefined;
}

type ArrayProps = {
  id: number;
  name: string;
};

const CertificationTypeArray: ArrayProps[] = [
  {
    id: 0,
    name: "Normal",
  },
  {
    id: 1,
    name: "A4 Size",
  },
  {
    id: 2,
    name: "Credit Card Size",
  },
  {
    id: 3,
    name: "Dossier",
  },
  {
    id: 4,
    name: "Seal",
  },
  {
    id: 5,
    name: "Stamp Size",
  },
];

const DeliveryTypeArray: ArrayProps[] = [
  {
    id: 0,
    name: "Normal",
  },
  {
    id: 1,
    name: "Tatkal",
  },
];

const ServiceTypeArray: ArrayProps[] = [
  {
    id: 0,
    name: "Walk In Service",
  },
  {
    id: 1,
    name: "Mobile Service",
  },
];

interface RedirecData {
  employee_id: string;
  branch_id: string;
}

const LotForm = () => {
  const initialData: LotProps = {
    branch_id: "",
    branch_name: "",
    article_id: "",
    article_name: "",
    certificate_type: "",
    created_date: "",
    criteria: "",
    customer_email: "",
    customer_id: "",
    customer_mobile: "",
    customer_name: "",
    auth_id: "",
    auth_name: "",
    customer_type: "",
    delivery_date: "",
    delivery_time: "",
    delivery_type: "",
    disclaimer: "",
    employee_id: "",
    employee_name: "",
    enc_lot_id: "",
    id: "",
    in_time: "",
    lots_number: "",
    total_carat_weight: "",
    total_gold_weight: "",
    no_of_articles: "",
    out_time: "",
    bin_no: "",
    advance_amount: "",
    discount_amount: "",
    remarks: "",
    service_type: "",
    status: "",
    updated_date: "",
    article_list: [],
  };
  const [data, setData] = useState<LotProps>(initialData);
  const [errors, setErrors] = useState<Errors>({});
  const params = useParams();
  const [customers, setCustomers] = useState<CustomerProps[]>([]);
  const [authorizeds, setAuthorizeds] = useState<AuthorizedProps[]>([]);
  const [detailsFields, setDetailsFields] = useState<any[]>(initialFields);
  const location = useLocation();
  const user = useSelector((state: UserState) => state.userReducer);
  const branch = useSelector((state: any) => state.branchReducer);
  const [services, setServices] = useState<
    { id: string; name: string; price: string }[]
  >([]);
  const [articles, setArticles] = useState<{ id: string; name: string }[]>([]);

  const fetchArticle = async () => {
    try {
      const response = await getArticles();
      if (response?.data?.article_list) {
        const newArticles = response.data.article_list.map(
          ({
            article_name: name,
            ...rest
          }: {
            article_name: string;
            [key: string]: any;
          }) => ({
            name,
            ...rest,
          })
        );
        setArticles(newArticles);
      }
    } catch (error) {
      console.error("Error fetching article list:", error);
    }
  };

  const fetchAuthorize = async (customer_id: string): Promise<void> => {
    try {
      let id = "";
      const response = await getAuthorizedPersons(id);
      if (response) {
        const foundCustomers = response.data.customer_authorize_list.filter(
          (item: any) => item.customer_id === customer_id
        );
        const newCustomer = foundCustomers.map(
          ({
            auth_name: name,
            ...rest
          }: {
            auth_name: string;
            [key: string]: any;
          }) => ({
            name,
            ...rest,
          })
        );
        setAuthorizeds(newCustomer);
      }
    } catch (error: any) {
      console.error("Error fetching authorized list:", error);
    }
  };

  const fetchServiceType = async () => {
    try {
      const response = await getServiceTypes();

      if (response?.data?.service_type_list) {
        const newServices = response.data.service_type_list.map(
          ({
            title: name,
            ...rest
          }: {
            title: string;
            [key: string]: any;
          }) => ({
            name,
            ...rest,
          })
        );
        setServices(newServices);
      }
    } catch (error) {
      console.error("Error fetching service type list:", error);
    }
  };

  const fetchCustomer = async (
    user: { [key: string]: string } | null,
    branch: any
  ): Promise<void> => {
    try {
      let redirecData: RedirecData;

      redirecData = {
        employee_id: user?.id || "",
        branch_id: branch?.id || "",
      };

      if (user?.department_name === "Admin") {
        redirecData = {
          employee_id: "",
          branch_id: "",
        };
      }
      if (user?.department_name === "Back Office") {
        redirecData = {
          employee_id: "",
          branch_id: branch?.id,
        };
      }

      const response = await getCustomers(redirecData);
      if (response.status === 202) {
        setCustomers([]);
      } else {
        setCustomers(response.data.customer_list);
      }
    } catch (error: any) {
      console.error("Error fetching customer list:", error);
    }
  };

  const fetchCustomers = async (customer_id: string) => {
    let updatedData = { ...data };
    const response = await getCustomer(customer_id);
    let customerData = response.data;
    if (customerData) {
      updatedData.customer_mobile = customerData.mobile;
      updatedData.customer_email = customerData.email;
      updatedData.customer_type = customerData.dealer_type;
      setData({ ...updatedData });
      setErrors({
        ...errors,
        customer_type: "",
        customer_email: "",
        customer_mobile: "",
      });
    } else {
      console.log("Customer not found");
    }
  };

  const handleSelectCustomer = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedId = selectedOption.id;
    const selectedName = selectedOption.value;
    let updatedData = { ...data };
    updatedData.customer_id = selectedId;
    updatedData.customer_name = selectedName;
    setData(updatedData);
  };

  useEffect(() => {
    fetchServiceType();
  }, []);

  useEffect(() => {
    if (detailsFields.length) {
      setData({ ...data, no_of_articles: String(detailsFields.length) });
    }
  }, [data, detailsFields]);

  useEffect(() => {
    if (data.customer_id) {
      fetchAuthorize(data.customer_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.customer_id]);

  useEffect(() => {
    if (data.customer_id) {
      fetchCustomers(data.customer_id);
    }
  }, [data.customer_id]);

  useEffect(() => {
    if (user && branch) {
      fetchCustomer(user, branch);
    }
  }, [user, branch]);

  const PopulateData = async (id: string, branch: any) => {
    try {
      const dataId = id;
      if (dataId === "new") {
        let emptyData: LotProps = Object.keys(data).reduce((obj, key) => {
          obj[key as keyof LotProps] = "";
          return obj;
        }, {} as LotProps);

        // Populate User For Admin and Employee

        let redirecData = {
          employee_id: user?.id,
          employee_name: user?.name,
          branch_id: branch?.id,
          branch_name: branch?.name,
        };
        if (user?.department_name === "Admin") {
          redirecData = {
            employee_id: "",
            employee_name: "",
            branch_id: "",
            branch_name: "",
          };
        }
        let resultedData = { ...emptyData, ...redirecData };
        setData(resultedData);
      } else {
        const response = await getLot(dataId);
        setData(response.data);
      }
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    if (params.id && branch) {
      PopulateData(params.id, branch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, branch]);

  const handlePrev = () => {
    // Programmatically navigate to a different route
    const lastSlashIndex = location.pathname.lastIndexOf("/");
    const newPathname = location.pathname.substring(0, lastSlashIndex + 1);

    let redirectUrl = (process.env.REACT_APP_liveURL + newPathname) as string;
    if (process.env.NODE_ENV === "development") {
      redirectUrl = (process.env.REACT_APP_localURL + newPathname) as string;
    }
    window.location.href = redirectUrl; // No need for type assertion
  };

  const doSubmit = async (): Promise<void> => {
    // Call the server
    console.log("Submitted", data);
    try {
      let id = "";
      const foundAuthorizePersons = await getAuthorizedPersons(id);
      const foundAuthorize = findItemByProperty(
        foundAuthorizePersons.data.customer_authorize_list,
        "auth_name",
        data.auth_name
      );
      const authData = {
        auth_id: foundAuthorize?.id,
        auth_name: foundAuthorize?.auth_name,
      };
      let originalDetailsField = detailsFields;
      let finalDetailsData;
      if (typeof originalDetailsField[0].servicetype != "string") {
        finalDetailsData = originalDetailsField?.map((arr: any) => {
          let d = {};
          if (d) {
            return {
              ...arr,
              servicetype: arr.servicetype?.map((item: any) => {
                let sData = services.find((art) => art.name === item.name);
                let s = {};
                if (s) {
                  return {
                    service_type_id: sData?.id,
                    service_type_name: item.name,
                    service_type_price: sData?.price,
                  };
                }
                return s;
              }),
            };
          }
          return d;
        });
      } else {
        finalDetailsData = [];
      }

      let resultedData = {
        ...data,
        articles: finalDetailsData,
        ...authData,
      };
      const response = await saveLot(resultedData);
      console.log("response", response);
      toast.success(response.data.message);
      if (response.data) {
        if (params.id === "new") {
          let redirectUrl = `${process.env.REACT_APP_liveURL}/${
            user.department_name === "Admin" ? "admin_panel" : "frontdesk_panel"
          }/lot/all/voucher/${response.data.lot_id}`;
          if (process.env.NODE_ENV === "development") {
            redirectUrl = `${process.env.REACT_APP_localURL}/${
              user.department_name === "Admin"
                ? "admin_panel"
                : "frontdesk_panel"
            }/lot/all/voucher/${response.data.lot_id}`;
          }
          window.location.href = redirectUrl; // No need for type assertion
        } else {
          handlePrev();
        }
      }
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    article_list: Joi.optional().allow(""),
    branch_id: Joi.optional().allow(""),
    branch_name: Joi.optional().allow(""),
    certificate_type: Joi.string().required().label("Certification Type"),
    created_date: Joi.optional().allow(""),
    criteria: Joi.optional().allow(""),
    customer_email: Joi.string().required().label("Customer Email"),
    customer_id: Joi.optional().allow(""),
    customer_mobile: Joi.string().required().label("Mobile Number"),
    customer_name: Joi.string().required().label("Customer Name"),
    auth_id: Joi.optional().allow(""),
    auth_name: Joi.string().required().label("Authorize Person Name"),
    customer_type: Joi.string().required().label("Customer Type"),
    delivery_date: Joi.date()
      .greater("now")
      .required()
      .error(() => {
        return {
          message:
            "Delivery Date must not be the current date or a date in the past.",
        };
      }),
    delivery_time: Joi.optional().allow(""),
    delivery_type: Joi.string().required().label("Delivery Type"),
    disclaimer: Joi.string().required().label("Disclaimer"),
    article_id: Joi.optional().allow(""),
    article_name: Joi.string().required().label("Article Name"),
    employee_id: Joi.optional().allow(""),
    employee_name: Joi.optional().allow(""),
    enc_lot_id: Joi.optional().allow(""),
    id: Joi.optional().allow(""),
    in_time: Joi.optional().allow(""),
    lots_number: Joi.optional().allow(""),
    total_gold_weight: Joi.optional().allow(""),
    total_carat_weight: Joi.optional().allow(""),
    no_of_articles: Joi.number().required().label("No of Articles"),
    out_time: Joi.optional().allow(""),
    bin_no: Joi.string().required().label("Bin No."),
    discount_amount: Joi.optional().allow(""),
    advance_amount: Joi.optional().allow(""),
    remarks: Joi.optional().allow(""),
    service_type: Joi.string().required().label("Service Type"),
    status: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
  };

  const { renderInput, renderInputArea, renderSelect, handleSubmit } =
    Form<LotProps>({
      data,
      setData,
      errors,
      setErrors,
      schema,
      onSubmit: doSubmit,
    });

  useEffect(() => {
    if (data) {
      const buttonElement = document.querySelector(".form-sample .btn");
      const blockInputElements = document.querySelectorAll(
        ".block-input input, .block-input select, .block-input textarea"
      ) as NodeListOf<HTMLInputElement | HTMLSelectElement>;

      blockInputElements.forEach(
        (inputElement: HTMLInputElement | HTMLSelectElement) => {
          inputElement.disabled = true;
        }
      );

      if (buttonElement) {
        buttonElement.classList.add("btn-svs");
      }
    }
  }, [data]);
  useEffect(() => {
    fetchArticle();
  }, []);

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper ">
          <div className="main-panel ">
            <div className="content-wrapper ">
              <Header />

              <form onSubmit={handleSubmit} className="form-sample">
                <div className="row ">
                  <div className="col-12 grid-margin ">
                    <div className="card ">
                      <div className="card-body">
                        {(user?.department_name === "Admin" ||
                          user?.department_name === "Front Office") && (
                          <>
                            <div className="form-sample">
                              <p className="card-description">
                                {" "}
                                Customers Details
                              </p>
                              <div className="row">
                                {/* Customer Basic Info */}
                                <InputCustom
                                  isUserAdmin={user.department_name === "Admin"}
                                  isDataEmpty={data.id === ""}
                                >
                                  <Select
                                    name="customer_name"
                                    label={``}
                                    options={customers}
                                    value={data.customer_name}
                                    onChange={handleSelectCustomer}
                                    error=""
                                  />
                                </InputCustom>
                                <InputCustom
                                  isUserAdmin={user.department_name === "Admin"}
                                  isDataEmpty={data.id === ""}
                                >
                                  {renderInput(
                                    "customer_type",
                                    'Customer Type <span class="text-danger">*</span>'
                                  )}
                                </InputCustom>
                                <div
                                  className={`col-md-4 block-flex ${
                                    user.department_name === "Admin"
                                      ? ""
                                      : data.id === ""
                                      ? ""
                                      : "block-input"
                                  }`}
                                >
                                  {" "}
                                  {renderSelect(
                                    "auth_name",
                                    'Authorize Person Name <span class="text-danger">*</span>',
                                    authorizeds
                                  )}
                                  <AuthModal
                                    customer_name={data.customer_name}
                                    fetchAuthorize={fetchAuthorize}
                                  />
                                </div>
                                <div className="col-md-6 block-input">
                                  {renderInput(
                                    "customer_email",
                                    'Email <span class="text-danger">*</span>'
                                  )}
                                </div>
                                <div className="col-md-6 block-input">
                                  {renderInput(
                                    "customer_mobile",
                                    'Phone <span class="text-danger">*</span>'
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {/* Lot Details Info */}

                        <div className="form-sample">
                          <p className="card-description"> Lots Details</p>

                          <div className="row">
                            <InputCustom
                              isUserAdmin={user.department_name === "Admin"}
                              isDataEmpty={data.id === ""}
                            >
                              {renderSelect(
                                "article_name",
                                'Article Name. <span class="text-danger">*</span>',
                                articles
                              )}
                            </InputCustom>
                            <InputCustom
                              isUserAdmin={user.department_name === "Admin"}
                              isDataEmpty={data.id === ""}
                            >
                              {renderInput(
                                "bin_no",
                                'Bin No. <span class="text-danger">*</span>'
                              )}
                            </InputCustom>
                            <InputCustom
                              isUserAdmin={user.department_name === "Admin"}
                              isDataEmpty={data.id === ""}
                            >
                              {renderInput(
                                "no_of_articles",
                                'No. of Articles <span class="text-danger">*</span>'
                              )}
                            </InputCustom>
                            <InputCustom
                              isUserAdmin={user.department_name === "Admin"}
                              isDataEmpty={data.id === ""}
                            >
                              {renderInput(
                                "delivery_date",
                                'Delivery Date <span class="text-danger">*</span>',
                                "date"
                              )}
                            </InputCustom>
                            <InputCustom
                              isUserAdmin={user.department_name === "Admin"}
                              isDataEmpty={data.id === ""}
                            >
                              {renderSelect(
                                "delivery_type",
                                'Delivery Type <span class="text-danger">*</span>',
                                DeliveryTypeArray
                              )}
                            </InputCustom>
                            <InputCustom
                              isUserAdmin={user.department_name === "Admin"}
                              isDataEmpty={data.id === ""}
                            >
                              {renderSelect(
                                "certificate_type",
                                'Certification Type <span class="text-danger">*</span>',
                                CertificationTypeArray
                              )}
                            </InputCustom>
                            <InputCustom
                              isUserAdmin={user.department_name === "Admin"}
                              isDataEmpty={data.id === ""}
                            >
                              {renderSelect(
                                "service_type",
                                'Service Type <span class="text-danger">*</span>',
                                ServiceTypeArray
                              )}
                            </InputCustom>
                            <InputCustom
                              isUserAdmin={user.department_name === "Admin"}
                              isDataEmpty={data.id === ""}
                            >
                              {renderInput(
                                "total_gold_weight",
                                'Total Gold Weight <span class="text-danger">*</span>'
                              )}
                            </InputCustom>
                            <InputCustom
                              isUserAdmin={user.department_name === "Admin"}
                              isDataEmpty={data.id === ""}
                            >
                              {renderInput(
                                "total_carat_weight",
                                'Total Carat Weight <span class="text-danger">*</span>'
                              )}
                            </InputCustom>

                            {/* <div className="col-md-3">
                              {renderInput("advance_amount", "Advance Amount")}
                            </div>
                            <div className="col-md-3">
                              {renderInput(
                                "discount_amount",
                                'Discount Amount <span class="text-danger">*</span>'
                              )}
                            </div> */}
                            <InputCustom
                              isUserAdmin={user.department_name === "Admin"}
                              isDataEmpty={data.id === ""}
                            >
                              {renderInputArea("criteria", "Criteria")}
                            </InputCustom>
                            {/* <div className="col-md-4">
                              {renderInput(
                                "delivery_time",
                                'Delivery Time <span class="text-danger">*</span>',
                                "time"
                              )}
                            </div> */}

                            <InputCustom
                              isUserAdmin={user.department_name === "Admin"}
                              isDataEmpty={data.id === ""}
                            >
                              {renderInputArea(
                                "disclaimer",
                                'Disclaimer <span class="text-danger">*</span>'
                              )}
                            </InputCustom>
                            <InputCustom
                              isUserAdmin={user.department_name === "Admin"}
                              isDataEmpty={data.id === ""}
                            >
                              {renderInputArea("remarks", "Remarks")}
                            </InputCustom>
                          </div>
                        </div>

                        <ArticleDetails
                          detailsFields={detailsFields}
                          setDetailsFields={setDetailsFields}
                          data={data}
                        />

                        <div
                          className="row "
                          style={{
                            width: "271px",
                            margin: "21px auto",
                            display:
                              params.id === "new" ||
                              (user &&
                                (user.department_name === "Admin" ||
                                  user.department_name === "Administrator" || // Include additional conditions here
                                  user.department_name === "Front Office" || // Include additional conditions here
                                  user.department_name === "Back Office" || // Include additional conditions here
                                  user.department_name === "Engraving"))
                                ? "block"
                                : "none",
                          }}
                        >
                          <button type="submit" className="btn btn-svs">
                            Save
                          </button>
                          <button
                            type="button"
                            onClick={handlePrev}
                            className="btn btn-cnc"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LotForm;
