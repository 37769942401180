import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../../../../common/form";
import "./StateForm.css";
import { ToastContent, toast } from "react-toastify";
import { getCountries } from "../../../../services/countryService";
import { getState, saveState } from "../../../../services/stateService";
import { StateProps } from "../../../../types/stateProps";
import { CountryProps } from "../../../../types/countryProps";
import findItemByProperty from "../../../../utils/findItem";

type Props = {
  id: string;
  showSideNav: boolean;
  setShowSideNav: (show: boolean) => void;
};

interface Errors {
  [key: string]: string | undefined;
}

function StateForm({ id, showSideNav, setShowSideNav }: Props) {
  const initialData: StateProps = {
    id: "",
    country_id: "",
    country_name: "",
    created_date: "",
    state_name: "",
    status: "",
    updated_date: "",
  };
  const [data, setData] = useState<StateProps>(initialData);
  const [countries, setCountries] = useState<CountryProps[]>([]);

  const [errors, setErrors] = useState<Errors>({});

  const fetchCountry = async () => {
    try {
      const response = await getCountries();
      const newCountries = response.data.country_list.map(
        ({
          country_name: name,
          ...rest
        }: {
          country_name: string;
          [key: string]: any;
        }) => ({
          name,
          ...rest,
        })
      );
      let activeCountries = newCountries.filter(
        (item: any) => item.status === "Active"
      );
      setCountries(activeCountries);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  const PopulateData = async (id: string) => {
    try {
      const dataId = id;
      if (dataId === "new") {
        let resultedData: StateProps = Object.keys(data).reduce((obj, key) => {
          obj[key as keyof StateProps] = "";
          return obj;
        }, {} as StateProps);
        setData(resultedData);
      } else {
        const response = await getState(dataId);
        setData(mapToViewModel(response.data));
      }
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  const mapToViewModel = ({ ...rest }: any): any => ({ ...rest });

  useEffect(() => {
    if (id) {
      PopulateData(id);
    }
  }, [id]);

  const doSubmit = async (): Promise<void> => {
    // Call the server
    console.log("Submitted", data);

    try {
      const country = await getCountries();
      const foundCountry = findItemByProperty(
        country.data.country_list,
        "country_name",
        data.country_name
      );
      const countryData = {
        country_id: foundCountry?.id,
        country_name: foundCountry?.country_name,
      };

      let resultedData = { ...data, ...countryData };
      const response = await saveState(resultedData);
      if (response) {
        toast.success(response.data.message);
        let resultedData: StateProps = Object.keys(data).reduce((obj, key) => {
          obj[key as keyof StateProps] = "";
          return obj;
        }, {} as StateProps);
        setData(resultedData);
        setShowSideNav(!showSideNav);
      }
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    id: Joi.optional().allow(""),
    country_id: Joi.optional().allow(""),
    country_name: Joi.string().required().label("Country Name"),
    state_name: Joi.string().required().label("State Name"),
    status: Joi.optional().allow(""),
    created_date: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
  };

  const { renderInput, renderSelect, renderButton, handleSubmit } =
    Form<StateProps>({
      data,
      setData,
      errors,
      setErrors,
      schema,
      onSubmit: doSubmit,
    });

  useEffect(() => {
    const buttonElement = document.querySelector(".forms-sample .btn");
    if (buttonElement) {
      buttonElement.classList.add("btn-gradient-primary");
    }
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit} className="forms-sample">
        {renderSelect(
          "country_name",
          'Country Name <span class="text-danger">*</span> ',
          countries
        )}
        {renderInput(
          "state_name",
          'State Name <span class="text-danger">*</span>'
        )}
        {renderButton("Save")}
        <p>&nbsp;</p>
      </form>
    </>
  );
}

export default StateForm;
