import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../../../../common/form";
import "./ArticleSubtypeForm.css";
import {
  getArticleSubtype,
  saveArticleSubtype,
} from "../../../../services/articleSubtypeService";
import { getArticles } from "../../../../services/articleService";
import { ToastContent, toast } from "react-toastify";
import { ArticleSubTypeProps } from "./../../../../types/articleSubTypeProps";
import { ArticleProps } from "../../../../types/articleProps";
import findItemByProperty from "../../../../utils/findItem";

type Props = {
  id: string;
  showSideNav: boolean;
  setShowSideNav: (show: boolean) => void;
};

interface Errors {
  [key: string]: string | undefined;
}

function ArticleSubtypeForm({ id, showSideNav, setShowSideNav }: Props) {
  const initialData: ArticleSubTypeProps = {
    id: "",
    article_id: "",
    article_name: "",
    article_subtype_name: "",
    created_date: "",
    status: "",
    updated_date: "",
  };
  const [data, setData] = useState<ArticleSubTypeProps>(initialData);
  const [articles, setArticles] = useState<ArticleProps[]>([]);

  const [errors, setErrors] = useState<Errors>({});

  const fetchArticle = async () => {
    try {
      const response = await getArticles();
      const newArticles = response.data.article_list.map(
        ({
          article_name: name,
          ...rest
        }: {
          article_name: string;
          [key: string]: any;
        }) => ({
          name,
          ...rest,
        })
      );
      setArticles(newArticles);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  useEffect(() => {
    fetchArticle();
  }, []);

  const PopulateData = async (id: string) => {
    try {
      const dataId = id;
      if (dataId === "new") {
        let resultedData: ArticleSubTypeProps = Object.keys(data).reduce(
          (obj, key) => {
            obj[key as keyof ArticleSubTypeProps] = "";
            return obj;
          },
          {} as ArticleSubTypeProps
        );
        setData(resultedData);
      } else {
        const response = await getArticleSubtype(dataId);
        setData(mapToViewModel(response.data));
      }
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  const mapToViewModel = ({ ...rest }: any): any => ({ ...rest });

  useEffect(() => {
    if (id) {
      PopulateData(id);
    }
  }, [id]);

  const doSubmit = async (): Promise<void> => {
    // Call the server
    console.log("Submitted", data);
    try {
      const article = await getArticles();
      const foundArticle = findItemByProperty(
        article.data.article_list,
        "article_name",
        data.article_name
      );
      const articleData = {
        article_id: foundArticle?.id,
        article_name: foundArticle?.article_name,
      };

      let resultedData = { ...data, ...articleData };
      console.log("resultedData", resultedData);
      const response = await saveArticleSubtype(resultedData);
      if (response) {
        toast.success(response.data.message);
        let resultedData: ArticleSubTypeProps = Object.keys(data).reduce(
          (obj, key) => {
            obj[key as keyof ArticleSubTypeProps] = "";
            return obj;
          },
          {} as ArticleSubTypeProps
        );
        setData(resultedData);
        setShowSideNav(!showSideNav);
      }
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    id: Joi.optional().allow(""),
    article_id: Joi.optional().allow(""),
    article_name: Joi.string().required().label("Article Name"),
    article_subtype_name: Joi.string()
      .required()
      .label("Article Sub Type Name"),
    status: Joi.optional().allow(""),
    created_date: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
  };

  const { renderInput, renderSelect, renderButton, handleSubmit } =
    Form<ArticleSubTypeProps>({
      data,
      setData,
      errors,
      setErrors,
      schema,
      onSubmit: doSubmit,
    });

  useEffect(() => {
    const buttonElement = document.querySelector(".forms-sample .btn");
    if (buttonElement) {
      buttonElement.classList.add("btn-gradient-primary");
    }
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit} className="forms-sample">
        {renderSelect(
          "article_name",
          'Article Type Name <span class="text-danger">*</span>',
          articles
        )}
        {renderInput(
          "article_subtype_name",
          'Article Sub Type Name <span class="text-danger">*</span>'
        )}
        {renderButton("Save")}
        <p>&nbsp;</p>
      </form>
    </>
  );
}

export default ArticleSubtypeForm;
