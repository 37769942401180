import http from "./httpService";
import { apiUrl } from "../config.js";
import { AccountProps } from "../types/accountProps";

const accountEndpoint = apiUrl + "/update_advance_discount";

// Get the Accountants Data

export async function getAccountants(accountant: {
  [key: string]: any;
}): Promise<any> {
  try {
    return await http.post(apiUrl + "/accountant_lot_list", {
      branch_id: accountant.branch_id,
      employee_id: accountant.employee_id,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving accountants data:", error);
    // throw error;
  }
}

// export async function getPhotography(id: string): Promise<any> {
//   try {
//     return await http.post(apiUrl + "/article_photo_list", { id: id });
//   } catch (error) {
//     // Handle error
//     console.error("Error retrieving photography data:", error);
//     // throw error;
//   }
// }

//  Create and Update Photography

export function saveAccounts(accounts: AccountProps) {
  return http.post(accountEndpoint, accounts);
}

// Update the status of the Account Data

export async function statusAccount(account: any) {
  try {
    return await http.post(apiUrl + "/update_status", account);
  } catch (error) {
    // Handle error
    console.error("Error retrieving article data:", error);
    // throw error;
  }
}
// Generate the invoice of the avaliable lots

export async function generateInvoice(data: any) {
  try {
    return await http.post(apiUrl + "/generate_invoice", data);
  } catch (error) {
    // Handle error
    console.error("Error retrieving article data:", error);
    // throw error;
  }
}

// // Delete the Article Data

// export async function deleteArticle(id: string): Promise<any> {
//   try {
//     return await http.post(apiUrl + "/delete_article", { id: id });
//   } catch (error) {
//     // Handle error
//     console.error("Error retrieving article data:", error);
//     // throw error;
//   }
// }
