import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { paginate } from "../../utils/paginate";
import Pagination from "../../common/pagination";
import ArticleSubtypeTable from "./ArticleSubtypeTable";
import { toast } from "react-toastify";
import {
  deleteArticleSubtype,
  getArticleSubTypes,
  statusArticleSubtype,
} from "../../services/articleSubtypeService";
import { ArticleSubTypeProps } from "../../types/articleSubTypeProps";
import SearchBox from "../../common/searchBox";
import PageSize from "../../common/pageSize";

type Props = {
  setId: React.Dispatch<React.SetStateAction<string>>;
  showSideNav: boolean;
  setShowSideNav: (show: boolean) => void;
};

function ArticleSubtypeList({ setId, showSideNav, setShowSideNav }: Props) {
  const [articleSubtypes, setArticleSubtypes] = useState<ArticleSubTypeProps[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const fetchData = async () => {
    try {
      const response = await getArticleSubTypes();
      setArticleSubtypes(response.data.article_sub_type_list ?? []);
    } catch (error) {
      console.error("Error fetching article sub types list:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleForm = (article_subtype: ArticleSubTypeProps) => {
    setId(String(article_subtype.id));
    setShowSideNav(!showSideNav);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleDelete = async (article_subtype: ArticleSubTypeProps) => {
    const originalDdata = articleSubtypes;
    const data = originalDdata.filter((u) => u.id !== article_subtype.id);
    setArticleSubtypes(data);
    setCurrentPage(1);

    try {
      const response = await deleteArticleSubtype(article_subtype.id);
      toast.success(response.data.message);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleStatus = async (article_subtype: ArticleSubTypeProps) => {
    let status: string =
      article_subtype.status === "Active" ? "Inactive" : "Active";

    try {
      const response = await statusArticleSubtype(article_subtype.id, status);
      toast.success(response.data.message);
      fetchData();
      // setCurrentPage(1);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    let filtered = articleSubtypes;
    if (searchQuery)
      filtered = articleSubtypes.filter((m) =>
        m.article_subtype_name
          .toLowerCase()
          .startsWith(searchQuery.toLowerCase())
      );
    const articleSubtype = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: articleSubtype };
  };

  useEffect(() => {
    if (!showSideNav) {
      fetchData();
    }
  }, [showSideNav]);

  const { length: count } = articleSubtypes;

  if (count === 0) return <p>There are no data in the database.</p>;

  const { totalCount, data: articleSubtype } = getPagedData();

  return (
    <>
      <div className="row">
        <div className="col-md-3 entries">
          <div
            style={{
              display: "grid",
              justifyContent: "space-between",
              alignItems: "center",
              gridTemplateColumns: "auto auto auto",
            }}
          >
            Showing
            <PageSize pageSize={pageSize} setPageSize={setPageSize} /> entries
          </div>
        </div>
        <div className="col-md-6 offset-md-3 search">
          <SearchBox value={searchQuery} onChange={handleSearch} />
        </div>
      </div>
      <ArticleSubtypeTable
        articleSubtypes={articleSubtype}
        onDelete={handleDelete}
        onForm={handleForm}
        onStatus={handleStatus}
      />

      <div className="row">
        <div className="col-md-9">
          <h3 className="page-title">
            Showing {totalCount} entries in the Database
          </h3>
        </div>
        <div className="col-md-3">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default ArticleSubtypeList;
