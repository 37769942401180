import http from "./httpService";
import { apiUrl } from "../config.js";
import { RoleProps } from "../types/roleProps";

const roleEndpoint = apiUrl + "/create_update_role";

// Get the role data

export async function getRoles(): Promise<any> {
  try {
    return await http.get(apiUrl + "/role_list");
  } catch (error) {
    // Handle error
    console.error("Error retrieving roles data:", error);
    // throw error;
  }
}
export async function getRole(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_role", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving role data:", error);
    // throw error;
  }
}

//  Create and Update Role

export function saveRole(role: RoleProps) {
  return http.post(roleEndpoint, role);
}

// Update the status of the role data

export async function statusRole(id: string, status: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/change_role_status", {
      id: id,
      status: status,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving role data:", error);
    // throw error;
  }
}

// Delete the role data

export async function deleteRole(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/delete_role", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving role data:", error);
    // throw error;
  }
}
