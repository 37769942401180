import http from "./httpService";
import { apiUrl } from "../config.js";
import { EmployProps } from "./../types/employeeProps";

const employeeEndpoint = apiUrl + "/create_update_employee";
const employeeExtstEndpoint = apiUrl + "/user_exist";

// Get the employs

export async function getEmploys(data: { [key: string]: any }): Promise<any> {
  try {
    return await http.post(apiUrl + "/employee_list", {
      branch_id: data.branch_id,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving employee data:", error);
    // throw error;
  }
}
export async function getEmploy(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_employee", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving employ data:", error);
    // throw error;
  }
}

//  Create and Update Employee

export function saveEmployee(employ: EmployProps) {
  return http.post(employeeEndpoint, employ);
}
export function saveEmployeeExistence(data: any) {
  return http.post(employeeExtstEndpoint, data);
}

// Update the status of the employs

export async function statusEmploy(id: string, status: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/change_employee_status", {
      id: id,
      status: status,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving employee data:", error);
    // throw error;
  }
}

// Delete the employs

export async function deleteEmploy(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/delete_employee", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving employee data:", error);
    // throw error;
  }
}
