import React, { useEffect, useState } from "react";
import { ScreeningDetailsProps } from "../../types/screeningDetailsProps";
import { ToastContent, toast } from "react-toastify";
import { getScreening, saveScreening } from "../../services/screeningService";
import Joi from "joi-browser";
import Form from "../../common/form";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";

type Props = {
  array: any;
  setArray: React.Dispatch<React.SetStateAction<any>>;
  currentIndex: number | undefined;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  detailsFields: any[];
};

interface Errors {
  [key: string]: string | undefined;
}

type ArrayProps = {
  id: string;
  name: string;
};

const StatusArray: ArrayProps[] = [
  {
    id: "1",
    name: "In Screening",
  },
  {
    id: "2",
    name: "Screening Completed",
  },
  {
    id: "3",
    name: "Rejected by Screening",
  },
];

function Modal({
  array,
  setArray,
  detailsFields,
  currentIndex,
  setCurrentIndex,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const initialData: ScreeningDetailsProps = {
    id: "",
    article_id: "",
    cvd: "",
    hpht: "",
    lot_id: "",
    lot_number: "",
    other: "",
    pho: "",
    rft: "",
    synthetic: "",
    created_date: "",
    status: "",
    updated_date: "",
    form_status: false,
  };

  const [data, setData] = useState<ScreeningDetailsProps>(initialData);
  const user = useSelector((state: UserState) => state.userReducer);
  const [errors, setErrors] = useState<Errors>({});

  const PopulateData = async (array: any) => {
    if (array.id) {
      try {
        const response = await getScreening(array.id);
        if (response.status === 204) {
          let resultedData: ScreeningDetailsProps = Object.keys(data).reduce(
            (obj, key) => {
              obj[key as keyof ScreeningDetailsProps] = "";
              return obj;
            },
            {} as ScreeningDetailsProps
          );
          setData({ ...resultedData, form_status: false });
        } else {
          setData(mapToViewModel(response.data));
        }
      } catch (error) {
        const errorMessage: ToastContent<unknown> =
          error as ToastContent<unknown>;
        toast.error(errorMessage);
      }
    }
  };

  const mapToViewModel = ({ ...rest }: any): any => ({ ...rest });

  useEffect(() => {
    if (array) {
      PopulateData(array);
    }
  }, [array]);

  const handleClose = () => {
    setOpen(!open);
    setArray([]);
  };

  const doSubmit = async (): Promise<void> => {
    // Call the server
    console.log("Submitted", data);
    try {
      const articleData = {
        article_id: array?.id,
        lot_id: array?.lot_id,
        lot_number: array?.lot_number,
      };

      let resultedData = { ...data, ...articleData };
      const response = await saveScreening(resultedData);
      toast.success(response.data.message);
      handleClose();
      setCurrentIndex((prevIndex: any) => {
        if (prevIndex !== undefined) {
          setArray(detailsFields[prevIndex + 1]);
          return prevIndex + 1;
        }
        return undefined;
      });
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    id: Joi.optional().allow(""),
    lot_id: Joi.optional().allow(""),
    lot_number: Joi.optional().allow(""),
    article_id: Joi.optional().allow(""),
    rft: Joi.optional().allow(""),
    pho: Joi.optional().allow(""),
    cvd: Joi.optional().allow(""),
    hpht: Joi.optional().allow(""),
    synthetic: Joi.optional().allow(""),
    other: Joi.optional().allow(""),
    status: Joi.optional().allow(""),
    created_date: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
    form_status: Joi.optional().allow(""),
  };

  const { renderInput, renderSelect, handleSubmit } =
    Form<ScreeningDetailsProps>({
      data,
      setData,
      errors,
      setErrors,
      schema,
      onSubmit: doSubmit,
    });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-4">
            {renderInput("rft", 'RFT <span class="text-danger">*</span>')}
          </div>
          <div className="col-md-4">
            {renderInput("pho", 'PHO <span class="text-danger">*</span>')}
          </div>
          <div className="col-md-4">
            {renderInput("cvd", 'CVD <span class="text-danger">*</span>')}
          </div>
          <div className="col-md-4">
            {renderInput("hpht", 'HPHT <span class="text-danger">*</span>')}
          </div>
          <div className="col-md-4">
            {renderInput(
              "synthetic",
              'Synthetic <span class="text-danger">*</span>'
            )}
          </div>
          <div className="col-md-4">
            {renderSelect(
              "status",
              'Status <span class="text-danger">*</span>',
              StatusArray
            )}
          </div>

          <div className="col-md-12">{renderInput("other", "Comments")}</div>
        </div>
        <div className="modal-footer">
          {data && (
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                display:
                  (user && user.department_name === "Admin") ||
                  data.form_status === false
                    ? "block"
                    : "none",
              }}
            >
              Submit
            </button>
          )}
        </div>
      </form>
    </>
  );
}

export default Modal;
