import { MdHome, MdFilePresent } from "react-icons/md";
import { HiOutlineClipboardList } from "react-icons/hi";
import { SiNintendogamecube, SiWebpack } from "react-icons/si";
import { FaUserCog } from "react-icons/fa";
import { VscPersonAdd } from "react-icons/vsc";

export const MenuItem = [
  {
    icon: <MdFilePresent className="menu-icon" size={25} />,
    title: "Customers",
    submenuItems: [{ to: "customer", text: "Manage Customer" }],
  },
  {
    icon: <HiOutlineClipboardList className="menu-icon" size={25} />,
    title: "Lots",
    submenuItems: [
      { to: "lot/all", text: "In Lab Services" },
      { to: "lot/new/new", text: "Mobile Services" },
      { to: "photography", text: "Photography" },
      { to: "screening", text: "Screening" },
      { to: "laboratory", text: "Laboratory" },
    ],
  },
  {
    icon: <SiWebpack className="menu-icon" size={25} />,
    title: "Reports",
    submenuItems: [{ to: "accounts/all", text: "Account" }],
  },
  {
    icon: <VscPersonAdd className="menu-icon" size={25} />,
    title: "Employee",
    submenuItems: [
      { to: "employee", text: "Manage Employee" },
      { to: "role", text: "Manage Roles" },
    ],
  },
  {
    icon: <FaUserCog className="menu-icon" size={25} />,
    title: "Master",
    submenuItems: [
      { to: "country", text: "Manage Country" },
      { to: "state", text: "Manage State" },
      { to: "branch", text: "Manage Branch" },
      // { to: "department", text: "Manage Department" },
      { to: "article", text: "Manage Article Type" },
      { to: "article_subtype", text: "Manage Article Sub Type" },
    ],
  },
  {
    icon: <SiNintendogamecube className="menu-icon" size={25} />,
    title: "Miscellaneous",
    submenuItems: [
      { to: "service", text: "Manage Service" },
      { to: "tax", text: "Manage Tax" },
    ],
  },
];
