import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Error from "./../pages/Error/Error";
import {
  Lot,
  Panel,
  Photography,
} from "../dashboard/BackOfficeDashboard/pages/";
import { BackOfficeDashboard } from "./../dashboard/";
import Logout from "../common/logout";
import { LotForm, PhotographyForm } from "../forms";
import { Certificate } from "../components";

function BackofficeRoute() {
  return (
    <>
      <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/backoffice_panel" element={<BackOfficeDashboard />}>
          <Route index element={<Panel />} />
          <Route path="lot/:id" element={<Lot />} />
          <Route path="lot/:id/:id" element={<LotForm />} />
          <Route path="photography" element={<Photography />} />
          <Route path="photography/:id" element={<PhotographyForm />} />
          <Route
            path="lot/:id/certificate/:lots_number"
            element={<Certificate />}
          />

          <Route path="*" element={<Error />} />
        </Route>
        <Route path="/" element={<Navigate to="/backoffice_panel" replace />} />
        <Route
          path="/admin_login"
          element={<Navigate to="/backoffice_panel" replace />}
        />
        <Route
          path="/employee_login"
          element={<Navigate to="/backoffice_panel" replace />}
        />

        <Route path="/logout" element={<Logout />} />
      </Routes>
    </>
  );
}

export default BackofficeRoute;
