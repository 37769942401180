import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "../../common/table";
import Delete from "../../common/delete";
import { LotProps } from "../../types/lotProps";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";
import StatusLot from "../../common/statusLot";
import Status from "../../common/status";
import TrackStatus from "../../common/trackStatus";

type Props = {
  lots: LotProps[];
};

function LotTable({ lots }: Props) {
  const user = useSelector((state: UserState) => state.userReducer);
  const [data, setData] = useState<any>();

  let columns = [
    {
      key: "sno", // Set a unique key value
      label: "S.No",
      content: (lot: LotProps) => <p>{lots.indexOf(lot) + 1}</p>,
    },

    { path: "lots_number", label: "Lot Number" },
    { path: "disclaimer", label: "Product" },
    { path: "no_of_articles", label: "Quantity" },
    {
      key: "Action",
      label: "Action",
      content: (lot: LotProps) => (
        <>
          <Link
            className="btn-edt"
            to={`create/${lot.lots_number}`}
            state={{ type: "" }}
          >
            <i className="fa fa-gg-circle" aria-hidden="true"></i>
          </Link>
        </>
      ),
    },
  ];

  return <Table data={lots} columns={columns} />;
}

export default LotTable;
