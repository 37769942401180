import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { ToastContent, toast } from "react-toastify";
import "./Certificate.css";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import { CertificateProps } from "./../../types/certificateProps";
import { getCertificate } from "../../services/certificateService";
import EightBlock from "./EightBlock";
import A4Size from "./A4Size";
import Dossier from "./Dossier";

type Props = {};
type InvoiceDocumentRef = React.MutableRefObject<HTMLDivElement | null>;
type HandlePrintFunction = () => void;

function Certificate({}: Props) {
  const [data, setData] = useState<CertificateProps[]>([]);
  const [certicateType, setCertificateType] = useState<string>("");
  const params = useParams();

  const invoiceDocument: InvoiceDocumentRef = useRef<HTMLDivElement>(null);

  const handlePrint: HandlePrintFunction = useReactToPrint({
    content: () => invoiceDocument.current,
  });

  const PopulateData = async (lot_number: string) => {
    try {
      const response = await getCertificate(lot_number);
      if (response.data.error) {
        return;
      }
      setData(response.data.certificate_list);
      setCertificateType(response.data.certificate_type);
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    if (params.lots_number) {
      let lot_number = params.lots_number;
      PopulateData(lot_number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.lots_number]);

  if (data?.length === 0 && certicateType === "")
    return (
      <div className="d-flex justify-content-center align-items-center flex-column vh-100">
        <p className="text-center">
          Apologize, but the certificate isn't generated yet. <br />
          Please come to collect it later.
        </p>
      </div>
    );

  return (
    <>
      <section ref={invoiceDocument}>
        {certicateType === "Normal" || certicateType === "A4 Size" ? (
          <>
            <A4Size data={data} />
          </>
        ) : null}
        {certicateType === "Credit Card Size" ||
        certicateType === "Seal" ||
        certicateType === "Stamp Size" ? (
          <>
            <EightBlock data={data} />
          </>
        ) : null}
        {certicateType === "Dossier" ? (
          <>
            <Dossier data={data} />
          </>
        ) : null}
      </section>

      <div className="d-flex justify-content-center">
        <button className="btn btn-outline-primary" onClick={handlePrint}>
          idtworldwide.com
        </button>
      </div>
    </>
  );
}

export default Certificate;
