import React from "react";
import { Home, Signin, Signup, Error, Admin } from "./../pages/";
import { Routes, Route, Navigate } from "react-router-dom";
import { Certificate } from "../components";

type Props = { department: string | null };

function AuthRoute({ department }: Props) {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home department={department} />} />
        <Route path="*" element={<Error />} />
        <Route path="/:lots_number" element={<Certificate />} />

        <Route path="/admin_login" element={<Admin />} />
        <Route path="/employee_login" element={<Signin />} />
        <Route
          path="/admin_panel"
          element={<Navigate to="/admin_login" replace />}
        />
        <Route
          path="/accounts_panel"
          element={<Navigate to="/employee_login" replace />}
        />
        <Route
          path="/backoffice_panel"
          element={<Navigate to="/employee_login" replace />}
        />
        <Route
          path="/frontdesk_panel"
          element={<Navigate to="/employee_login" replace />}
        />
        <Route
          path="/lab_panel"
          element={<Navigate to="/employee_login" replace />}
        />
        <Route
          path="/photography_panel"
          element={<Navigate to="/employee_login" replace />}
        />
        <Route
          path="/sales_panel"
          element={<Navigate to="/employee_login" replace />}
        />
        <Route
          path="/screening_panel"
          element={<Navigate to="/employee_login" replace />}
        />
        <Route
          path="/administrator_panel"
          element={<Navigate to="/employee_login" replace />}
        />
      </Routes>
    </>
  );
}

export default AuthRoute;
