import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Error from "./../pages/Error/Error";
import { Panel } from "../dashboard/SalesDashboard/pages";
import { SalesDashboard } from "../dashboard";
import Logout from "./../common/logout";

type Props = {};

function SalesRoute({}: Props) {
  return (
    <>
      <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/sales_panel" element={<SalesDashboard />}>
          <Route index element={<Panel />} />
          <Route path="*" element={<Error />} />
        </Route>
        <Route path="/" element={<Navigate to="/sales_panel" replace />} />
        <Route
          path="/admin_login"
          element={<Navigate to="/sales_panel" replace />}
        />
        <Route
          path="/employee_login"
          element={<Navigate to="/sales_panel" replace />}
        />

        <Route path="/logout" element={<Logout />} />
      </Routes>
    </>
  );
}

export default SalesRoute;
