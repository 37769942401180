import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Error from "./../pages/Error/Error";
import { Panel, Laboratory, Screening } from "../dashboard/LabDashboard/pages";
import { LabDashboard } from "../dashboard";
import Logout from "../common/logout";
import { LaboratoryForm, ScreeningForm } from "../forms";

function LabRoute() {
  return (
    <>
      <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/lab_panel" element={<LabDashboard />}>
          <Route index element={<Panel />} />
          <Route path="laboratory" element={<Laboratory />} />
          <Route path="laboratory/:id" element={<LaboratoryForm />} />
          <Route path="screening" element={<Screening />} />
          <Route path="screening/:id" element={<ScreeningForm />} />

          <Route path="*" element={<Error />} />
        </Route>
        <Route path="/" element={<Navigate to="/lab_panel" replace />} />
        <Route
          path="/admin_login"
          element={<Navigate to="/lab_panel" replace />}
        />
        <Route
          path="/employee_login"
          element={<Navigate to="/lab_panel" replace />}
        />

        <Route path="/logout" element={<Logout />} />
      </Routes>
    </>
  );
}

export default LabRoute;
