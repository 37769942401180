import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Joi from "joi-browser";
import "./EngravingForm.css";
import { ToastContent, toast } from "react-toastify";
import Form from "../../common/form";
import findItemByProperty from "../../utils/findItem";
import { LotProps } from "../../types/lotProps";
import { getLot, saveLot } from "../../services/lotService";
import Header from "./Header";
import { CustomerProps } from "../../types/customerProps";
import { getCustomers } from "../../services/customerService";
import ArticleDetails from "./ArticleDetails";
import { AuthorizedProps } from "../../types/authorizedProps";
import { getAuthorizedPersons } from "../../services/authorizedService";
import { useSelector } from "react-redux";
import { UserState } from "../../types/userTypes";
import { getServiceTypes } from "../../services/serviceTypeService";
import { getArticles } from "../../services/articleService";
import { initialFields } from "./InitialFields";

interface Errors {
  [key: string]: string | undefined;
}

type ArrayProps = {
  id: number;
  name: string;
};

interface RedirecData {
  employee_id: string;
  branch_id: string;
}

const EngravingForm = () => {
  const initialData: LotProps = {
    branch_id: "",
    branch_name: "",
    article_id: "",
    article_name: "",
    certificate_type: "",
    created_date: "",
    criteria: "",
    customer_email: "",
    customer_id: "",
    customer_mobile: "",
    customer_name: "",
    auth_id: "",
    auth_name: "",
    customer_type: "",
    delivery_date: "",
    delivery_time: "",
    delivery_type: "",
    disclaimer: "",
    employee_id: "",
    employee_name: "",
    enc_lot_id: "",
    id: "",
    in_time: "",
    lots_number: "",
    total_carat_weight: "",
    total_gold_weight: "",
    no_of_articles: "",
    out_time: "",
    bin_no: "",
    advance_amount: "",
    discount_amount: "",
    remarks: "",
    service_type: "",
    status: "",
    updated_date: "",
    article_list: [],
  };
  const [data, setData] = useState<LotProps>(initialData);
  const [errors, setErrors] = useState<Errors>({});
  const params = useParams();
  const [customers, setCustomers] = useState<CustomerProps[]>([]);
  const [authorizeds, setAuthorizeds] = useState<AuthorizedProps[]>([]);
  const [detailsFields, setDetailsFields] = useState<any[]>(initialFields);
  const location = useLocation();
  const user = useSelector((state: UserState) => state.userReducer);
  const branch = useSelector((state: any) => state.branchReducer);
  const [services, setServices] = useState<
    { id: string; name: string; price: string }[]
  >([]);
  const [articles, setArticles] = useState<{ id: string; name: string }[]>([]);

  const fetchArticle = async () => {
    try {
      const response = await getArticles();
      if (response?.data?.article_list) {
        const newArticles = response.data.article_list.map(
          ({
            article_name: name,
            ...rest
          }: {
            article_name: string;
            [key: string]: any;
          }) => ({
            name,
            ...rest,
          })
        );
        setArticles(newArticles);
      }
    } catch (error) {
      console.error("Error fetching article list:", error);
    }
  };

  const fetchAuthorize = async (customer_name: string): Promise<void> => {
    try {
      let id = "";
      const response = await getAuthorizedPersons(id);
      if (response) {
        const foundCustomers = response.data.customer_authorize_list.filter(
          (item: any) => item.customer_name === customer_name
        );
        const newCustomer = foundCustomers.map(
          ({
            auth_name: name,
            ...rest
          }: {
            auth_name: string;
            [key: string]: any;
          }) => ({
            name,
            ...rest,
          })
        );
        setAuthorizeds(newCustomer);
      }
    } catch (error: any) {
      console.error("Error fetching authorized list:", error);
    }
  };

  const fetchServiceType = async () => {
    try {
      const response = await getServiceTypes();

      if (response?.data?.service_type_list) {
        const newServices = response.data.service_type_list.map(
          ({
            title: name,
            ...rest
          }: {
            title: string;
            [key: string]: any;
          }) => ({
            name,
            ...rest,
          })
        );
        setServices(newServices);
      }
    } catch (error) {
      console.error("Error fetching service type list:", error);
    }
  };

  useEffect(() => {
    fetchServiceType();
  }, []);

  useEffect(() => {
    if (data.customer_name) {
      fetchAuthorize(data.customer_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.customer_name]);

  const fetchCustomer = async (
    user: { [key: string]: string } | null,
    branch: any
  ): Promise<void> => {
    try {
      let redirecData: RedirecData;

      redirecData = {
        employee_id: user?.id || "",
        branch_id: branch?.id || "",
      };

      if (user?.department_name === "Admin") {
        redirecData = {
          employee_id: "",
          branch_id: "",
        };
      }
      if (user?.department_name === "Back Office") {
        redirecData = {
          employee_id: "",
          branch_id: branch?.id,
        };
      }

      const response = await getCustomers(redirecData);
      if (response.status === 202) {
        setCustomers([]);
      } else {
        setCustomers(response.data.customer_list);
      }
    } catch (error: any) {
      console.error("Error fetching customer list:", error);
    }
  };

  const fetchCustomers = (customer_name: string) => {
    let updatedData = { ...data };
    let customerData = customers.find(
      (customer: any) => customer.name === customer_name
    );
    console.log("customerData", customerData);

    if (customerData) {
      updatedData.customer_id = customerData.id;
      updatedData.customer_mobile = customerData.mobile;
      updatedData.customer_email = customerData.email;
      updatedData.customer_type = customerData.dealer_type;
      setData(updatedData);
    } else {
      console.log("Customer not found");
    }
  };

  useEffect(() => {
    if (data.customer_name) {
      fetchCustomers(data.customer_name);
    }
  }, [data.customer_name]);

  useEffect(() => {
    if (user && branch) {
      fetchCustomer(user, branch);
    }
  }, [user, branch]);

  const PopulateData = async (id: string, branch: any) => {
    try {
      const dataId = id;
      if (dataId === "new") {
        let emptyData: LotProps = Object.keys(data).reduce((obj, key) => {
          obj[key as keyof LotProps] = "";
          return obj;
        }, {} as LotProps);

        // Populate User For Admin and Employee

        let redirecData = {
          employee_id: user?.id,
          employee_name: user?.name,
          branch_id: branch?.id,
          branch_name: branch?.name,
        };
        if (user?.department_name === "Admin") {
          redirecData = {
            employee_id: "",
            employee_name: "",
            branch_id: "",
            branch_name: "",
          };
        }
        let resultedData = { ...emptyData, ...redirecData };
        setData(resultedData);
      } else {
        const response = await getLot(dataId);
        setData(response.data);
      }
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    if (params.id && branch) {
      PopulateData(params.id, branch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, branch]);

  const handlePrev = () => {
    // Programmatically navigate to a different route
    const lastSlashIndex = location.pathname.lastIndexOf("/");
    const newPathname = location.pathname.substring(0, lastSlashIndex + 1);

    let redirectUrl = (process.env.REACT_APP_liveURL + newPathname) as string;
    if (process.env.NODE_ENV === "development") {
      redirectUrl = (process.env.REACT_APP_localURL + newPathname) as string;
    }
    window.location.href = redirectUrl; // No need for type assertion
  };

  console.log("error", errors);

  const doSubmit = async (): Promise<void> => {
    // Call the server
    console.log("Submitted", data);
    console.log("data try", data);
    try {
      let id = "";
      const foundAuthorizePersons = await getAuthorizedPersons(id);
      const foundAuthorize = findItemByProperty(
        foundAuthorizePersons.data.customer_authorize_list,
        "auth_name",
        data.auth_name
      );
      const authData = {
        auth_id: foundAuthorize?.id,
        auth_name: foundAuthorize?.auth_name,
      };
      let originalDetailsField = detailsFields;
      let finalDetailsData;
      if (typeof originalDetailsField[0].servicetype != "string") {
        finalDetailsData = originalDetailsField?.map((arr: any) => {
          let d = {};
          if (d) {
            return {
              ...arr,
              servicetype: arr.servicetype?.map((item: any) => {
                let sData = services.find((art) => art.name === item.name);
                let s = {};
                if (s) {
                  return {
                    service_type_id: sData?.id,
                    service_type_name: item.name,
                    service_type_price: sData?.price,
                  };
                }
                return s;
              }),
            };
          }
          return d;
        });
      } else {
        finalDetailsData = [];
      }

      let resultedData = {
        ...data,
        articles: finalDetailsData,
        ...authData,
      };
      const response = await saveLot(resultedData);
      toast.success(response.data.message);
      if (params.id === "new") {
        let redirectUrl = `${process.env.REACT_APP_liveURL}/${
          user.department_name === "Admin" ? "admin_panel" : "frontdesk_panel"
        }/lot/all/voucher/${response.data.lot_id}`;
        if (process.env.NODE_ENV === "development") {
          redirectUrl = `${process.env.REACT_APP_localURL}/${
            user.department_name === "Admin" ? "admin_panel" : "frontdesk_panel"
          }/lot/all/voucher/${response.data.lot_id}`;
        }
        window.location.href = redirectUrl; // No need for type assertion
      } else {
        handlePrev();
      }
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    article_list: Joi.optional().allow(""),
    branch_id: Joi.optional().allow(""),
    branch_name: Joi.optional().allow(""),
    certificate_type: Joi.string().required().label("Certification Type"),
    created_date: Joi.optional().allow(""),
    criteria: Joi.optional().allow(""),
    customer_email: Joi.string().required().label("Customer Email"),
    customer_id: Joi.optional().allow(""),
    customer_mobile: Joi.string().required().label("Mobile Number"),
    customer_name: Joi.string().required().label("Customer Name"),
    auth_id: Joi.optional().allow(""),
    auth_name: Joi.string().required().label("Authorize Person Name"),
    customer_type: Joi.string().required().label("Customer Type"),
    delivery_date: Joi.date()
      .greater("now")
      .required()
      .error(() => {
        return {
          message:
            "Delivery Date must not be the current date or a date in the past.",
        };
      }),
    delivery_time: Joi.optional().allow(""),
    delivery_type: Joi.string().required().label("Delivery Type"),
    disclaimer: Joi.string().required().label("Disclaimer"),
    article_id: Joi.optional().allow(""),
    article_name: Joi.string().required().label("Article Name"),
    employee_id: Joi.optional().allow(""),
    employee_name: Joi.optional().allow(""),
    enc_lot_id: Joi.optional().allow(""),
    id: Joi.optional().allow(""),
    in_time: Joi.optional().allow(""),
    lots_number: Joi.optional().allow(""),
    total_gold_weight: Joi.optional().allow(""),
    total_carat_weight: Joi.optional().allow(""),
    no_of_articles: Joi.number().required().label("No of Articles"),
    out_time: Joi.optional().allow(""),
    bin_no: Joi.string().required().label("Bin No."),
    discount_amount: Joi.optional().allow(""),
    advance_amount: Joi.optional().allow(""),
    remarks: Joi.optional().allow(""),
    service_type: Joi.string().required().label("Service Type"),
    status: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
  };

  const { renderInput, renderInputArea, renderSelect, handleSubmit } =
    Form<LotProps>({
      data,
      setData,
      errors,
      setErrors,
      schema,
      onSubmit: doSubmit,
    });

  useEffect(() => {
    const buttonElement = document.querySelector(".form-sample .btn");

    const blockInputElements = document.querySelectorAll(
      ".block-input input"
    ) as NodeListOf<HTMLInputElement>;
    blockInputElements.forEach((inputElement: HTMLInputElement) => {
      inputElement.disabled = true;
    });

    if (buttonElement) {
      buttonElement.classList.add("btn-svs");
    }
  }, []);

  useEffect(() => {
    fetchArticle();
  }, []);

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper ">
          <div className="main-panel ">
            <div className="content-wrapper ">
              <Header />

              {/* Lot Details Info */}
              <form onSubmit={handleSubmit} className="form-horizontal">
                <div className="row ">
                  <div className="col-12 grid-margin ">
                    <div className="card ">
                      <div className="card-body">
                        <ArticleDetails
                          detailsFields={detailsFields}
                          data={data}
                          setDetailsFields={setDetailsFields}
                        />

                        <div
                          className="row "
                          style={{
                            width: "271px",
                            margin: "21px auto",
                            // display:
                            //   (user &&
                            //     user.department_name === "Admin" &&
                            //     data.status !== "") ||
                            //   data.status === ""
                            //     ? "block"
                            //     : data.status !== ""
                            //     ? "none"
                            //     : "block",
                          }}
                        >
                          <button type="submit" className="btn btn-svs">
                            Save
                          </button>
                          <button
                            type="button"
                            onClick={handlePrev}
                            className="btn btn-cnc"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EngravingForm;
