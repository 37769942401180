import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Error from "./../pages/Error/Error";
import { Panel } from "../dashboard/ScreeningDashboard/pages";
import { ScreeningDashboard } from "../dashboard";
import Logout from "../common/logout";

type Props = {};

function ScreeningRoute({}: Props) {
  return (
    <>
      <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/screening_panel" element={<ScreeningDashboard />}>
          <Route index element={<Panel />} />
          <Route path="*" element={<Error />} />
        </Route>
        <Route path="/" element={<Navigate to="/screening_panel" replace />} />
        <Route
          path="/admin_login"
          element={<Navigate to="/screening_panel" replace />}
        />
        <Route
          path="/employee_login"
          element={<Navigate to="/screening_panel" replace />}
        />

        <Route path="/logout" element={<Logout />} />
      </Routes>
    </>
  );
}

export default ScreeningRoute;
