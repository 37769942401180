import React from "react";
import QRCode from "react-qr-code";

type Props = { data: any[] };

function Dossier({ data }: Props) {
  return (
    <>
      <section className="dosser">
        {data?.map((item) => (
          <>
            <section className="gold-leaf">
              <div className="container">
                <div className="row">
                  <div className="col-md-5">
                    <div className="artical-box-left">
                      <div className="artical-log"></div>
                      <div className="rprts">
                        <ul>
                          <li>IDT Report No:{item.certificate_number}</li>
                        </ul>
                      </div>
                      <div className="art-desct">
                        <h4>Description of Article</h4>
                        <p>{item.description}</p>
                      </div>

                      <div className="jewellerycert-lst">
                        <ul className="">
                          {item.shape_title && (
                            <>
                              <li>
                                <h6>Shape/Cut</h6>
                              </li>
                              <li>
                                <h6>{item.shape_title}</h6>
                              </li>
                            </>
                          )}

                          {item.weight && (
                            <>
                              <li>
                                <h6>Diamond Wt.</h6>
                              </li>
                              <li>
                                <h6>{item.weight} Cts</h6>
                              </li>
                            </>
                          )}

                          {item.color && (
                            <>
                              <li>
                                <h6>Min Color</h6>
                              </li>
                              <li>
                                <h6>{item.color}</h6>
                              </li>
                            </>
                          )}

                          {item.finish && (
                            <>
                              <li>
                                <h6>Finish</h6>
                              </li>
                              <li>
                                <h6>{item.finish}</h6>
                              </li>
                            </>
                          )}

                          {item.comments && (
                            <>
                              <li>
                                <h6>Comments</h6>
                              </li>
                              <li>
                                <h6>{item.comments}</h6>
                              </li>
                            </>
                          )}

                          {item.design_no && (
                            <>
                              <li>
                                <h6>Design No.</h6>
                              </li>
                              <li>
                                <h6>{item.design_no}</h6>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="artical-box-center">
                      <h4>Studded Jewelry Report</h4>
                      <img style={{ width: "70%" }} src={item.article_photo} />
                      <h5>Photo Approx.</h5>
                      <div className="Approx-img">
                        <div className="Approx-img-left">
                          <img src={item.article_photo} />
                        </div>
                        <div className="Approx-img-right">
                          <QRCode
                            size={128}
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                              width: "45px",
                            }}
                            value={item?.barcode_number}
                            viewBox={`0 0 256 256`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="artical-box-right">
                      <div className="artical-log"></div>
                      <div className="rprts">
                        <ul>
                          <li>IDT Report No:{item.certificate_number}</li>
                        </ul>
                      </div>
                      <div className="art-desct">
                        <h4>Description </h4>
                        <p>{item.description}</p>
                      </div>
                      <div className="jewellerycert-lst">
                        <ul className="">
                          {item.shape_title && (
                            <>
                              <li>
                                <h6>Shape/Cut</h6>
                              </li>
                              <li>
                                <h6>{item.shape_title}</h6>
                              </li>
                            </>
                          )}

                          {item.weight && (
                            <>
                              <li>
                                <h6>Diamond Wt.</h6>
                              </li>
                              <li>
                                <h6>{item.weight} Cts</h6>
                              </li>
                            </>
                          )}
                          {item.color && (
                            <>
                              <li>
                                <h6>Min Color</h6>
                              </li>
                              <li>
                                <h6>{item.color}</h6>
                              </li>
                            </>
                          )}

                          {item.finish && (
                            <>
                              <li>
                                <h6>Finish</h6>
                              </li>
                              <li>
                                <h6>{item.finish}</h6>
                              </li>
                            </>
                          )}

                          {item.comments && (
                            <>
                              <li>
                                <h6>Comments</h6>
                              </li>
                              <li>
                                <h6>{item.comments}</h6>
                              </li>
                            </>
                          )}
                          {item.design_no && (
                            <>
                              <li>
                                <h6>Design No.</h6>
                              </li>
                              <li>
                                <h6>{item.design_no}</h6>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ))}
      </section>
    </>
  );
}

export default Dossier;
