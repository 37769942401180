import React from "react";
import { MdHome, MdInfoOutline } from "react-icons/md";

type Props = {};

function Header({}: Props) {
  return (
    <>
      <div className="page-header">
        <h3 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white mr-2">
            <MdHome size={25} />
          </span>
          Dashboard
        </h3>
        <nav aria-label="breadcrumb ">
          <ul className="breadcrumb">
            <li className="breadcrumb-item active">
              <span>Overview</span>
              <MdInfoOutline
                size={25}
                className="icon-sm text-primary align-middle"
              />
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Header;
