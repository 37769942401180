import http from "./httpService";
import { apiUrl } from "../config.js";
import { ScreeningDetailsProps } from "../types/screeningDetailsProps";

const screeningEndpoint = apiUrl + "/create_update_screening";
const bulkScreeningEndpoint = apiUrl + "/update_bulk_screening";

// Get the Screenings Data

export async function getScreenings(screening: {
  [key: string]: any;
}): Promise<any> {
  try {
    return await http.post(apiUrl + "/screening_lot_list", {
      branch_id: screening.branch_id,
      employee_id: screening.employee_id,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving screenings data:", error);
    // throw error;
  }
}

export async function getScreening(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_article_screening", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving screening data:", error);
    // throw error;
  }
}

//  Create and Update Screening

export function saveScreening(screening: ScreeningDetailsProps) {
  return http.post(screeningEndpoint, screening);
}

export function saveBulkScreening(screening: any) {
  return http.post(bulkScreeningEndpoint, screening);
}

// Update the status of the Screening Data

export async function statusScreening(screening: any) {
  try {
    return await http.post(apiUrl + "/update_status", screening);
  } catch (error) {
    // Handle error
    console.error("Error retrieving article data:", error);
    // throw error;
  }
}
// Update the track status of the Screening Data

export async function trackScreening(screening: any) {
  try {
    return await http.post(apiUrl + "/update_bin", screening);
  } catch (error) {
    // Handle error
    console.error("Error retrieving article data:", error);
    // throw error;
  }
}

// // Delete the Article Data

// export async function deleteArticle(id: string): Promise<any> {
//   try {
//     return await http.post(apiUrl + "/delete_article", { id: id });
//   } catch (error) {
//     // Handle error
//     console.error("Error retrieving article data:", error);
//     // throw error;
//   }
// }
