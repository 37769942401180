import React from "react";
import Header from "./Header";
import Body from "./Body";
import Customer from "./Customer";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { UserState } from "../../../../types/userTypes";

type Props = {};

function Panel({}: Props) {
  const user = useSelector((state: UserState) => state.userReducer);

  if (user && user.role_name === "Photography") {
    return <Navigate to={`/backoffice_panel/photography`} replace />;
  } else if (user && user.role_name === "Excel Executive") {
    return <Navigate to={`/backoffice_panel/lot/all`} replace />;
  }

  return (
    <>
      <div className="content-wrapper">
        <Header />
        {/* <Body />
        <Customer /> */}
        <div className="d-flex justify-content-center align-items-center flex-column vh-100">
          <h1>Welcome you on Back office</h1>
        </div>
      </div>
    </>
  );
}

export default Panel;
