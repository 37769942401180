import React from "react";
import { ArticleList } from "../../../../tables";

type Props = {
  setId: React.Dispatch<React.SetStateAction<string>>;
  showSideNav: boolean;
  setShowSideNav: (show: boolean) => void;
};

function List({ setId, showSideNav, setShowSideNav }: Props) {
  return (
    <>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Recent Article Type</h4>
              {/* Ticket Table */}
              <ArticleList
                setId={setId}
                showSideNav={showSideNav}
                setShowSideNav={setShowSideNav}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
