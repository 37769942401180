import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Joi from "joi-browser";
import "./InvoiceDetailList.css";
import { ToastContent, toast } from "react-toastify";
import { CustomerProps } from "../../types/customerProps";
import Form from "../../common/form";
import { getCustomers } from "../../services/customerService";
import findItemByProperty from "../../utils/findItem";
import Header from "./Header";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";
import { InvoicesProps } from "../../types/invoicesProps";
import { getInvoice } from "../../services/invoiceService";
import Select from "../../common/select";
import { LotProps } from "../../types/lotProps";
import { getLots } from "../../services/lotService";
import LotTable from "./LotTable";
import { paginate } from "../../utils/paginate";
import Pagination from "../../common/pagination";

type Props = {};

interface Errors {
  [key: string]: string | undefined;
}
interface RedirecData {
  employee_id: string;
  branch_id: string;
  customer_id?: string;
}

const InvoiceDetailList = ({}: Props) => {
  const [customers, setCustomers] = useState<CustomerProps[]>([]);
  const [customer_id, setCustomerId] = useState<string>("");
  const [customer_name, setCustomerName] = useState<string>("");
  const [lots, setLots] = useState<LotProps[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const user = useSelector((state: UserState) => state.userReducer);
  const branch = useSelector((state: any) => state.branchReducer);
  const location = useLocation();
  const params = useParams();
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });

  const fetchCustomer = async (
    user: { [key: string]: string } | null,
    branch: any
  ): Promise<void> => {
    try {
      let redirecData: RedirecData;
      redirecData = {
        employee_id: "",
        branch_id: branch?.id || "",
      };
      if (user?.department_name === "Admin") {
        redirecData = {
          employee_id: "",
          branch_id: "",
        };
      }
      const response = await getCustomers(redirecData);
      if (response.status === 202) {
        setCustomers([]);
      } else {
        setCustomers(response.data.customer_list);
      }
    } catch (error: any) {
      console.error("Error fetching customer list:", error);
    }
  };

  const handleSelectCustomer = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedId = selectedOption.id;
    const selectedName = selectedOption.value;
    setCustomerId(selectedId);
    setCustomerName(selectedName);
  };

  const fetchLots = useCallback(
    async (
      user: { [key: string]: string } | null,
      branch?: { id: string } | null,
      customer_id?: string
    ): Promise<void> => {
      try {
        let redirecData: RedirecData;

        redirecData = {
          employee_id: "",
          branch_id: branch?.id || "",
          customer_id,
        };

        if (user?.department_name === "Admin") {
          redirecData = {
            employee_id: "",
            branch_id: "",
            customer_id,
          };
        }

        const response = await getLots(redirecData);

        if (response.data?.lots_list) {
          setLots(response.data?.lots_list);
        } else {
          setLots([]);
        }
      } catch (error: any) {
        console.error("Error fetching lot list:", error);
      }
    },
    [customer_id]
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (user && branch && customer_id) fetchLots(user, branch, customer_id);
  }, [user, fetchLots, branch, customer_id]);

  const handlePrev = () => {
    // Programmatically navigate to a different route
    const lastSlashIndex = location.pathname.lastIndexOf("/");
    const newPathname = location.pathname.substring(0, lastSlashIndex + 1);

    let redirectUrl = (process.env.REACT_APP_liveURL + newPathname) as string;
    if (process.env.NODE_ENV === "development") {
      redirectUrl = (process.env.REACT_APP_localURL + newPathname) as string;
    }
    window.location.href = redirectUrl; // No need for type assertion
  };

  useEffect(() => {
    if (user && branch) {
      fetchCustomer(user, branch);
    }
  }, [user, branch]);

  const getPagedData = () => {
    let filtered = lots;
    // .filter(
    //   (f: any) =>
    //     f.status !== "Invoice Generated" &&
    //     f.status !== "Taken Back" &&
    //     f.status !== "Delivered"
    // );

    const lot = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: lot };
  };

  useEffect(() => {
    const buttonElement = document.querySelector(".form-horizontal .btn");
    if (buttonElement) {
      buttonElement.classList.add("btn-svs");
    }
  }, []);

  const { length: count } = lots ?? [];
  const { totalCount, data: lot } = getPagedData();

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper ">
          <div className="main-panel ">
            <div className="content-wrapper ">
              <Header />

              <div className="row ">
                <div className="col-12 grid-margin stretch-card">
                  <div className="card ">
                    <div className="card-body">
                      <div className="customer-main d-flex">
                        <div className="customer-left d-flex">
                          <h4 className="text-dark">Customer Details</h4>
                          {params.id === "new" && (
                            <>
                              <Select
                                name="department_name"
                                label={``}
                                options={customers}
                                value={customer_name}
                                onChange={handleSelectCustomer}
                                error=""
                              />
                            </>
                          )}
                        </div>
                        <div className="customer-right d-flex">
                          <h4>Date/Time</h4>
                          <p>{formattedDate}</p>
                        </div>
                      </div>
                      <div className="">
                        <p className="card-description"> Lots Details</p>
                        <div className="lot">
                          {customer_id === "" && totalCount === 0 ? (
                            "Select Customer Details"
                          ) : customer_id !== "" && totalCount === 0 ? (
                            "No Lot Found ..."
                          ) : (
                            <>
                              <div className="row">
                                <LotTable lots={lot} />
                              </div>
                              <div className="row">
                                <div className="col-md-9">
                                  <h3 className="page-title">
                                    Showing {totalCount} entries in the Database
                                  </h3>
                                </div>
                                <div className="col-md-3">
                                  <Pagination
                                    itemsCount={totalCount}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        className="row "
                        style={{
                          width: "271px",
                          margin: "21px auto",
                          display:
                            params.id === "new" ||
                            (user && user.department_name === "Admin")
                              ? "block"
                              : "none",
                        }}
                      >
                        <button
                          type="button"
                          onClick={handlePrev}
                          className="btn btn-cnc"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceDetailList;
