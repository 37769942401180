import React from "react";
import { Link } from "react-router-dom";
import Table from "../../common/table";
// import Delete from "../../common/delete";
import { LaboratoryProps } from "../../types/laboratoryProps";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";
import StatusLot from "../../common/statusLot";
import Status from "../../common/status";
import TrackStatus from "../../common/trackStatus";

type Props = {
  laboratorys: LaboratoryProps[];
  onBin: (laboratory: LaboratoryProps) => void;
  onDelete: (laboratory: LaboratoryProps) => void;
  onStatus: (laboratory: LaboratoryProps) => void;
};

function LaboratoryTable({ laboratorys, onBin, onDelete, onStatus }: Props) {
  const user = useSelector((state: UserState) => state.userReducer);

  const columns = [
    { path: "lots_number", label: "Lot Number" },
    // {
    //   path: "customerDetails",
    //   label: "Customer Details",
    //   content: (laboratory: LaboratoryProps) => (
    //     <>
    //       <p>Name: {laboratory.customer_name}</p>
    //       <p>Email: {laboratory.customer_email}</p>
    //       <p>Mobile: {laboratory.customer_mobile}</p>
    //       {laboratory.branch_name && (
    //         <p>Branch Name: {laboratory.branch_name}</p>
    //       )}
    //     </>
    //   ),
    // },
    {
      path: "lotDetails",
      label: "Lot Details",
      content: (laboratory: LaboratoryProps) => (
        <>
          <p>No. of Articles: {laboratory.no_of_articles}</p>
          <p>Service Type: {laboratory.service_type}</p>
        </>
      ),
    },

    {
      path: "status",
      label: "Status",
      content: (laboratory: LaboratoryProps) => (
        <>
          <div className="status">
            <Status
              status={laboratory.status}
              onClick={() => onStatus(laboratory)}
            />
          </div>
        </>
      ),
    },
    { path: "created_date", label: "Created On" },
    {
      key: "Action",
      label: "Action",
      content: (laboratory: LaboratoryProps) => (
        <>
          <div className="d-flex justify-content-center align-items-center">
            {(laboratory.status === "New" ||
              laboratory.status === "In Laboratory" ||
              laboratory.status === "Screening Completed" ||
              laboratory.status === "Approved") &&
              user.department_name !== "Admin" && (
                <StatusLot
                  item={laboratory}
                  onClick={onStatus}
                  department="Lab Testing"
                />
              )}

            {laboratory.status === "In Laboratory" && (
              <>
                <Link
                  className="btn-edt"
                  to={laboratory.id}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Edit"
                >
                  {user && user.department_name === "Admin" ? (
                    <i className="fa fa-edit" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-eye" aria-hidden="true"></i>
                  )}
                </Link>
              </>
            )}
            <TrackStatus item={laboratory} onClick={onBin} />
          </div>
        </>
      ),
    },
  ];

  return <Table data={laboratorys} columns={columns} />;
}

export default LaboratoryTable;
