import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Error from "../pages/Error/Error";
import { Panel, Engraving } from "../dashboard/EngravingDashboard/pages/";
import { EngravingDashboard } from "../dashboard";
import Logout from "../common/logout";
import { EngravingForm } from "../forms";

function EngravingRoute() {
  return (
    <>
      <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/engraving_panel" element={<EngravingDashboard />}>
          <Route index element={<Panel />} />
          <Route path="lot/:id" element={<Engraving />} />
          <Route path="lot/:id/:id" element={<EngravingForm />} />
          <Route path="*" element={<Error />} />
        </Route>
        <Route path="/" element={<Navigate to="/engraving_panel" replace />} />
        <Route
          path="/admin_login"
          element={<Navigate to="/engraving_panel" replace />}
        />
        <Route
          path="/employee_login"
          element={<Navigate to="/engraving_panel" replace />}
        />

        <Route path="/logout" element={<Logout />} />
      </Routes>
    </>
  );
}

export default EngravingRoute;
