import http from "./httpService";
import { apiUrl } from "../config.js";
import { TaxProps } from "../types/taxProps";

const taxEndpoint = apiUrl + "/create_update_tax";

// Get the Taxes data

export async function getTaxes(): Promise<any> {
  try {
    return await http.get(apiUrl + "/tax_list");
  } catch (error) {
    // Handle error
    console.error("Error retrieving taxes data:", error);
    // throw error;
  }
}
export async function getTax(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_tax", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving tax data:", error);
    // throw error;
  }
}

//  Create and Update Tax

export function saveTax(tax: TaxProps) {
  return http.post(taxEndpoint, tax);
}

// Update the status of the Tax data

export async function statusTax(id: string, status: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/change_tax_status", {
      id: id,
      status: status,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving tax data:", error);
    // throw error;
  }
}

// Delete the Tax data

export async function deleteTax(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/delete_tax", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving tax data:", error);
    // throw error;
  }
}
