import React, { useState } from "react";
import Header from "./Header";
import Sidenav from "./Sidenav";
import List from "./List";
import "./Role.css";

type Props = {};

function Role({}: Props) {
  const [showSideNav, setShowSideNav] = useState<boolean>(false);
  const [id, setId] = useState<string>("");

  return (
    <>
      <div className="content-wrapper">
        <Header
          setId={setId}
          showSideNav={showSideNav}
          setShowSideNav={setShowSideNav}
        />
        <Sidenav
          id={id}
          setId={setId}
          showSideNav={showSideNav}
          setShowSideNav={setShowSideNav}
        />
        <List
          setId={setId}
          showSideNav={showSideNav}
          setShowSideNav={setShowSideNav}
        />
      </div>
    </>
  );
}

export default Role;
