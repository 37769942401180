import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import Joi from "joi-browser";
import "./InvoiceDetailForm.css";
import { ToastContent, toast } from "react-toastify";
import { CustomerProps } from "../../types/customerProps";
import Form from "../../common/form";
import { getCustomers } from "../../services/customerService";
import findItemByProperty from "../../utils/findItem";
import Header from "./Header";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";
import { InvoicesProps } from "../../types/invoicesProps";
import { getInvoice } from "../../services/invoiceService";
import { initialFields } from "./InitialFields";
import LotTable from "./LotTable";
import { InvoiceProps } from "../../types/invoiceProps";
import { generateInvoice } from "../../services/accountsService";

type Props = {};

interface Errors {
  [key: string]: string | undefined;
}
interface RedirecData {
  employee_id: string;
  branch_id: string;
  customer_id?: string;
}

const InvoiceDetailForm = ({}: Props) => {
  const [detailsFields, setDetailsFields] = useState<any[]>(initialFields);

  const [data, setData] = useState<InvoiceProps>();
  const user = useSelector((state: UserState) => state.userReducer);
  const branch = useSelector((state: any) => state.branchReducer);
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });

  const PopulateData = async (lots_number: string, type: string) => {
    try {
      const response = await getInvoice(lots_number, type);
      console.log("invoice ", response);

      setData(response.data);
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;

      // toast.error(errorMessage);

      // Assuming user and user.department_name are defined
      if (user && user.department_name === "Admin") {
        // Navigate to "admin_panel/lot/all/invoice/{lots_number}" with state
        navigate(`/admin_panel/lot/all/invoice/${lots_number}`, {
          state: { type: "after" },
        });
      } else if (user && user.department_name === "Accounts") {
        // Navigate to "accounts_panel/lot/all/invoice/{lots_number}" with state
        navigate(`/accounts_panel/lot/all/invoice/${lots_number}`, {
          state: { type: "after" },
        });
      }
    }
  };
  const doSubmit = async () => {
    // Call the server
    console.log("Submitted", data);
    try {
      let send_data = {
        branch_id: user?.department_name === "Admin" ? "" : branch?.id,
        branch_name: user?.department_name === "Admin" ? "" : branch?.name,
        employee_id: user?.department_name === "Admin" ? "" : user?.id,
        employee_name: user?.department_name === "Admin" ? "" : user?.name,
        employee_role: user?.department_name === "Admin" ? "" : user?.role_name,
        lot_id: data?.lot_id,
        lot_number: data?.lots_number,
        department_id:
          user?.department_name === "Admin" ? "" : user?.department_id,
        department_name:
          user?.department_name === "Admin" ? "" : user?.department_name,
        invoice_items: detailsFields,
      };

      const response = await generateInvoice(send_data);
      console.log("response", response);

      // toast.success(response?.data.message);

      // let redirectUrl = `${process.env.REACT_APP_liveURL}/${
      //   user.department_name === "Admin"
      //     ? "admin_panel/invoices/all"
      //     : "accounts_panel/invoices/all"
      // }/invoice/${response?.data.lot_number}`;
      // if (process.env.NODE_ENV === "development") {
      //   redirectUrl = `${process.env.REACT_APP_localURL}/${
      //     user.department_name === "Admin"
      //       ? "admin_panel/invoices/all"
      //       : "accounts_panel/invoices/all"
      //   }/invoice/${response?.data.lot_number}`;
      // }
      // window.location.href = redirectUrl; // No need for type assertion
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handlePrev = () => {
    // Programmatically navigate to a different route
    const lastSlashIndex = location.pathname.lastIndexOf("/");
    const newPathname = location.pathname.substring(0, lastSlashIndex + 1);

    let redirectUrl = (process.env.REACT_APP_liveURL + newPathname) as string;
    if (process.env.NODE_ENV === "development") {
      redirectUrl = (process.env.REACT_APP_localURL + newPathname) as string;
    }
    window.location.href = redirectUrl; // No need for type assertion
  };

  useEffect(() => {
    if (params.lots_number && location.state) {
      PopulateData(params.lots_number, location.state.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.lots_number, location]);

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper ">
          <div className="main-panel ">
            <div className="content-wrapper ">
              <Header />
              <div className="row ">
                <div className="col-12 grid-margin stretch-card">
                  <div className="card ">
                    <div className="card-body">
                      <div className="customer-main d-flex">
                        <div className="customer-left d-flex">
                          <h4 className="text-dark">Customer Details</h4>
                          <p>{data?.bill_to} </p>
                          <p>GST:-{data?.bill_gst} </p>
                          <p>{data?.bill_phone}</p>
                        </div>
                        <div className="customer-right d-flex">
                          <h4>Date/Time</h4>
                          <p>{formattedDate}</p>
                        </div>
                      </div>
                      <div className="my-3">
                        <p className="card-description"> Products Details</p>
                        <div className="lot">
                          <div className="row">
                            {data && (
                              <LotTable
                                detailsFields={detailsFields}
                                setDetailsFields={setDetailsFields}
                                data={data}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="row "
                        style={{
                          width: "271px",
                          margin: "21px auto",
                          display:
                            params.id === "new" ||
                            (user && user.department_name === "Admin")
                              ? "block"
                              : "none",
                        }}
                      >
                        <button
                          type="button"
                          onClick={doSubmit}
                          className="btn btn-svs"
                        >
                          Save
                        </button>

                        <button
                          type="button"
                          onClick={handlePrev}
                          className="btn btn-cnc"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceDetailForm;
