import React from "react";
import { Link, useLocation } from "react-router-dom";
import Table from "../../common/table";
import Delete from "../../common/delete";
import Status from "../../common/status";
import { DepartmentProps } from "../../types/departmentProps";

type Props = {
  departments: DepartmentProps[];
  onDelete: (department: DepartmentProps) => void;
  onForm: (department: DepartmentProps) => void;
  onStatus: (department: DepartmentProps) => void;
};

function DepartmentTable({ departments, onDelete, onForm, onStatus }: Props) {
  const columns = [
    {
      path: "branch_name",
      label: "Branch Name",
    },
    {
      path: "department_name",
      label: "Department Name",
    },
    {
      path: "created_date",
      label: "Created Date",
    },
    {
      path: "status",
      label: "Status",
      content: (department: DepartmentProps) => (
        <>
          <Status
            status={department.status}
            onClick={() => onStatus(department)}
          />
        </>
      ),
    },
    {
      key: "Action",
      label: "Action",
      content: (department: DepartmentProps) => (
        <>
          <Link className="btn-edt" to="#" onClick={() => onForm(department)}>
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </Link>
          <Delete onClick={() => onDelete(department)} />
        </>
      ),
    },
  ];

  return <Table data={departments} columns={columns} />;
}

export default DepartmentTable;
