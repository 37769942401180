import React from "react";
import { Link } from "react-router-dom";
import Table from "../../common/table";
import Delete from "../../common/delete";
import Status from "../../common/status";
import { AuthorizedProps } from "../../types/authorizedProps";
import { useSelector } from "react-redux";
import { UserState } from "../../types/userTypes";

type Props = {
  authorizeds: AuthorizedProps[];
  onDelete: (authorized: AuthorizedProps) => void;
  onStatus: (authorized: AuthorizedProps) => void;
};

function AuthorizedTable({ authorizeds, onDelete, onStatus }: Props) {
  const user = useSelector((state: UserState) => state.userReducer);

  const columns = [
    {
      path: "customerDetails",
      label: "Authorized Perosn Details",
      content: (authorized: AuthorizedProps) => (
        <>
          <p>Name: {authorized.auth_name}</p>
          <p>Email: {authorized.auth_email}</p>
          <p>Mobile: {authorized.auth_mobile}</p>
        </>
      ),
    },
    {
      path: "address",
      label: "Address",
      content: (authorized: AuthorizedProps) => (
        <>
          <p>{authorized.address}</p>
          <p>
            {authorized.city_name} {authorized.state_name} -{" "}
            {authorized.pincode}, {authorized.country_name}
          </p>
        </>
      ),
    },
    {
      path: "status",
      label: "Status",
      content: (authorized: AuthorizedProps) => (
        <>
          <Status
            status={authorized.status}
            onClick={() => onStatus(authorized)}
          />
        </>
      ),
    },
    { path: "created_date", label: "Created On" },
    {
      key: "Action",
      label: "Action",
      content: (authorized: AuthorizedProps) => (
        <>
          <Link className="btn-edt" to={authorized.id}>
            {user && user.department_name === "Admin" ? (
              <i className="fa fa-edit" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-eye" aria-hidden="true"></i>
            )}
          </Link>
          {user && user.department_name === "Admin" && (
            <Delete onClick={() => onDelete(authorized)} />
          )}
        </>
      ),
    },
  ];

  return <Table data={authorizeds} columns={columns} />;
}

export default AuthorizedTable;
