import React from "react";
import Select from "./select";
import { UserState } from "../types/userTypes";
import { useDispatch, useSelector } from "react-redux";
import { setBranch } from "../actions";

type Props = {};

function Branch({}: Props) {
  const user = useSelector((state: UserState) => state.userReducer);
  const branch = useSelector((state: any) => state.branchReducer);

  const dispatch = useDispatch();
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let options = user?.employee_branch.map((b: any) => ({
      id: b.branch_id,
      name: b.branch_name,
    }));
    const selectedOption = options.find(
      (option: any) => option.name === event.target.value
    );

    if (selectedOption) {
      dispatch(
        setBranch({
          id: selectedOption.id,
          name: selectedOption.name,
        })
      );
    }
  };

  return (
    <>
      <div className="branch-select">
        <Select
          name=""
          value={branch?.name}
          label=""
          options={user?.employee_branch.map((b: any) => ({
            id: b.branch_id,
            name: b.branch_name,
          }))}
          onChange={handleSelectChange}
          error=""
        />
      </div>
    </>
  );
}

export default Branch;
