import http from "./httpService";
import { apiUrl } from "../config.js";
import { ArticleProps } from "./../types/articleProps";

const articleEndpoint = apiUrl + "/create_update_article";

// Get the Article Data

export async function getArticles(): Promise<any> {
  try {
    return await http.get(apiUrl + "/article_list");
  } catch (error) {
    // Handle error
    console.error("Error retrieving articles data:", error);
    // throw error;
  }
}
export async function getArticle(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_article", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving article data:", error);
    // throw error;
  }
}

//  Create and Update Article

export function saveArticle(article: ArticleProps) {
  return http.post(articleEndpoint, article);
}

// Update the status of the Article Data

export async function statusArticle(id: string, status: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/change_article_status", {
      id: id,
      status: status,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving article data:", error);
    // throw error;
  }
}

// Delete the Article Data

export async function deleteArticle(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/delete_article", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving article data:", error);
    // throw error;
  }
}
