interface CustomFile extends File {
  status: boolean;
}

const handleMultipleUpload = (
  files: FileList | null,
  MAX_COUNT: number
): [CustomFile[], { status: boolean }] => {
  if (!files) {
    return [[], { status: false }];
  }

  const uploaded: CustomFile[] = Array.from(files) as CustomFile[];
  let limitExceeded = true;

  Array.from(files).forEach((file) => {
    if (uploaded.findIndex((f) => f.name === file.name) === -1) {
      uploaded.push({ ...file, status: true });
      if (uploaded.length === MAX_COUNT) {
        limitExceeded = true;
        return;
      }
      if (uploaded.length > MAX_COUNT) {
        limitExceeded = false;
        return;
      }
    }
  });

  if (uploaded.length > MAX_COUNT) {
    limitExceeded = false;
  }

  return [uploaded, { status: limitExceeded }];
};

export default handleMultipleUpload;
