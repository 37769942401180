import http from "./httpService";
import { apiUrl } from "../config.js";
import { CustomerProps } from "../types/customerProps";

const customerEndpoint = apiUrl + "/create_update_customer";

// Get the customers

export async function getCustomers(customer: {
  [key: string]: any;
}): Promise<any> {
  try {
    return await http.post(apiUrl + "/customer_list", {
      employee_id: customer.employee_id,
      branch_id: customer.branch_id,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving customers data:", error);
    // throw error;
  }
}
export async function getCustomer(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_customer", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving customer data:", error);
    // throw error;
  }
}

//  Create and Update Customer

export function saveCustomer(customer: CustomerProps) {
  return http.post(customerEndpoint, customer);
}

// Update the status of the customer

export async function statusCustomer(id: string, status: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/change_customer_status", {
      id: id,
      status: status,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving customer data:", error);
    // throw error;
  }
}

// Delete the customer

export async function deleteCustomer(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/delete_customer", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving customer data:", error);
    // throw error;
  }
}
