import React from "react";
import { Link } from "react-router-dom";
import Table from "../../common/table";
import Delete from "../../common/delete";
import { LotProps } from "../../types/lotProps";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";
import StatusLot from "../../common/statusLot";
import Status from "../../common/status";
import TrackStatus from "../../common/trackStatus";
import Engraving from "../../common/engraving";

type Props = {
  lots: LotProps[];
  onEngraving: (lot: LotProps) => void;
  onDelete: (lot: LotProps) => void;
  onStatus: (lot: LotProps) => void;
  onBin: (lot: LotProps) => void;
};

function EngravingTable({
  lots,
  onDelete,
  onEngraving,
  onStatus,
  onBin,
}: Props) {
  const user = useSelector((state: UserState) => state.userReducer);

  const columns = [
    { path: "lots_number", label: "Lot Number" },
    {
      path: "lotDetails",
      label: "Lot Details",
      content: (lot: LotProps) => (
        <>
          <p>No. of Articles: {lot.no_of_articles}</p>
          <p>Service Type: {lot.service_type}</p>
        </>
      ),
    },
    {
      path: "status",
      label: "Status",
      content: (lot: LotProps) => (
        <>
          <div className="status">
            <Status status={lot.status} onClick={() => onStatus(lot)} />
          </div>
        </>
      ),
    },
    {
      path: "created_date",
      label: "Created On",
      content: (lot: LotProps) => <>{lot?.created_date.split(" ")[0]}</>,
    },
    {
      key: "Action",
      label: "Actions",
      content: (lot: LotProps) => (
        <>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ flexWrap: "wrap", width: "120px", height: "100px" }}
          >
            {user.department_name !== "Back Office" ||
              ("Engraving" && (
                <>
                  <StatusLot
                    item={lot}
                    onClick={onStatus}
                    department="Front Office Manager"
                  />
                  <Link
                    style={{ cursor: "pointer" }}
                    to={`jobcard/${lot.lots_number}`}
                    className="btn-edt"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Job Card"
                  >
                    <i className="fa fa-address-card-o " aria-hidden="true" />
                  </Link>
                  {user && user.department_name === "Admin" && (
                    <>
                      <Link
                        className="btn-edt"
                        style={{
                          display:
                            lot.status === "Approved" ||
                            lot.status === "Invoice Generated"
                              ? "block"
                              : "none",
                        }}
                        to={`certificate/${lot.lots_number}`}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="View Certificate"
                      >
                        <i className="fa fa-certificate" aria-hidden="true"></i>
                      </Link>
                    </>
                  )}
                  <Link
                    style={{
                      display:
                        lot.status === "Invoice Generated" ? "block" : "none",
                    }}
                    className="btn-edt"
                    to={`invoice/${lot.lots_number}`}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="View Invoice"
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </Link>
                  <Link
                    className="btn-edt"
                    to={`voucher/${lot.enc_lot_id}`}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="View Voucher"
                  >
                    <i className="fa fa-sticky-note" aria-hidden="true"></i>
                  </Link>
                  <Link
                    className="btn-edt"
                    to={`barcode/${lot.lots_number}`}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="View Barcode"
                  >
                    <i className="fa fa-barcode" aria-hidden="true"></i>
                  </Link>
                </>
              ))}

            <Link
              className="btn-edt"
              to={lot.id}
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              {user && user.department_name === "Admin" ? (
                <i className="fa fa-edit" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-eye" aria-hidden="true"></i>
              )}
            </Link>
            {user && user.department_name === "Admin" && (
              <Delete onClick={() => onDelete(lot)} />
            )}

            <Engraving article={lot} onClick={onEngraving} />

            {lot?.status !== "New" && (
              <TrackStatus item={lot} onClick={onBin} />
            )}
          </div>
        </>
      ),
    },
  ];

  return <Table data={lots} columns={columns} />;
}

export default EngravingTable;
