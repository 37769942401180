import React from "react";
import { MdHome } from "react-icons/md";
import { Link } from "react-router-dom";

type Props = {};

function Header({}: Props) {
  return (
    <>
      <div className="page-header">
        <h3 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white mr-2">
            <MdHome size={25} />
          </span>
          Photography List
        </h3>
        {/* <Link to="new" style={{ textDecoration: "none" }}>
          <span className="add-btns">Add</span>
        </Link> */}
      </div>
    </>
  );
}

export default Header;
