import React from "react";

type Props = {
  name: string;
  label: string;
  options: { id: string; name: string }[];
  error?: string;
  value: string | null;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void; // Add onChange prop to Props type
};

function Select({
  name,
  label,
  value,
  options,
  error,
  disabled,
  onChange,
  ...rest
}: Props) {
  return (
    <>
      <div className="form-group">
        <label
          id={name}
          htmlFor={name}
          dangerouslySetInnerHTML={{ __html: label }}
        />
        <select
          name={name}
          className="form-control"
          onChange={onChange}
          disabled={disabled}
          {...rest}
        >
          <option value=""> -- Select the option -- </option>
          {options.map((option, index) => (
            <option
              key={index}
              id={option.id}
              value={option.name}
              selected={
                option.name &&
                option.name.toLowerCase() === value?.toLowerCase()
                  ? true
                  : false
              }
            >
              {option.name}
            </option>
          ))}
        </select>
        {error && <p className="text-danger">{error}</p>}
      </div>
    </>
  );
}

export default Select;
