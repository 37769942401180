import http from "./httpService";
import { apiUrl } from "../config.js";

// Get the Certificate data

export async function getCertificate(lot_number: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/certificate_list", {
      lot_number: lot_number,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving certificate data:", error);
    // throw error;
  }
}
