import http from "./httpService";
import { apiUrl } from "../config.js";

// Get the Shapes Data

export async function getShapes(): Promise<any> {
  try {
    return await http.get(apiUrl + "/shape_list");
  } catch (error) {
    // Handle error
    console.error("Error retrieving shapes data:", error);
    // throw error;
  }
}
export async function getShape(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_shape", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving shape data:", error);
    // throw error;
  }
}
// Get the Metals Data

export async function getMetals(): Promise<any> {
  try {
    return await http.get(apiUrl + "/metal_type_list");
  } catch (error) {
    // Handle error
    console.error("Error retrieving metals data:", error);
    // throw error;
  }
}
export async function getMetal(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_metal_type", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving metal data:", error);
    // throw error;
  }
}
