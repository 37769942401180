import { ArticleDetailsProps } from "../../types/articleDetailsProps";

export const initialFields: ArticleDetailsProps[] = [
  {
    id: "",
    lot_id: "",
    lot_number: "",
    barcode_number: "",
    employee_id: "",
    employee_name: "",
    branch_id: "",
    branch_name: "",
    article_name: "",
    article_id: "",
    article_subtype_name: "",
    article_subtype_id: "",
    metal: "",
    service_type: "",
    weight: "",
    carat_weight: "",
    unit: "",
    carat_weight_unit: "",
    no_of_diamond: "",
    shape_id: "",
    shape_title: "",
    design_no: "",
    item_name: "",
    shape: "",
    cut: "",
    measurements: "",
    transparency: "",
    color: "",
    species: "",
    origin: "",
    remarks: "",
    assignto_photography: "",
    assignto_screening: "",
    assignto_laboratory: "",
    article_image_name: "",
    certificate_number: "",
    clarity: "",
    conclusion: "",
    crown_angle: "",
    finish: "",
    fluorescence: "",
    gemstone: "",
    gridle_thikness: "",
    loose_table: "",
    magnification: "",
    optic_character: "",
    pavilion_depth: "",
    polish: "",
    refractive_index: "",
    spcific_gravity: "",
    spectroscopy: "",
    symmetry: "",
    total_depth: "",
    uncut_diamond: "",
    servicetype: "",
    engraving_date: "",
    engraving_status: "",
    status: "",
    created_date: "",
    updated_date: "",
  },
];
