import React from "react";
import { Link, useLocation } from "react-router-dom";
import Table from "../../common/table";
import Delete from "../../common/delete";
import Status from "../../common/status";
import { ArticleProps } from "./../../types/articleProps";

type Props = {
  articles: ArticleProps[];
  onDelete: (article: ArticleProps) => void;
  onForm: (article: ArticleProps) => void;
  onStatus: (article: ArticleProps) => void;
};

function ArticleTable({ articles, onDelete, onForm, onStatus }: Props) {
  const location = useLocation();

  const columns = [
    {
      path: "article_name",
      label: "Article Type Name",
    },
    {
      path: "created_date",
      label: "Created Date",
    },
    {
      path: "status",
      label: "Status",
      content: (article: ArticleProps) => (
        <>
          <Status status={article.status} onClick={() => onStatus(article)} />
        </>
      ),
    },
    // {
    //   key: "Action",
    //   label: "Action",
    //   content: (article: ArticleProps) => (
    //     <>
    //       <Link className="btn-edt" to="#" onClick={() => onForm(article)}>
    //         <i className="fa fa-pencil" aria-hidden="true"></i>
    //       </Link>
    //       <Delete onClick={() => onDelete(article)} />
    //     </>
    //   ),
    // },
  ];

  return <Table data={articles} columns={columns} />;
}

export default ArticleTable;
