import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Joi from "joi-browser";
import "./BranchForm.css";
import { ToastContent, toast } from "react-toastify";
import { BranchProps } from "../../types/branchProps";
import { getCountries } from "../../services/countryService";
import { getStates } from "../../services/stateService";
import { StateProps } from "../../types/stateProps";
import { CountryProps } from "../../types/countryProps";
import { getBranch, saveBranch } from "../../services/branchService";
import findItemByProperty from "../../utils/findItem";
import Form from "../../common/form";
import Header from "./Header";

type Props = {};

interface Errors {
  [key: string]: string | undefined;
}

function BranchForm({}: Props) {
  const initialData: BranchProps = {
    address: "",
    branch_name: "",
    city_name: "",
    contact_email: "",
    contact_mobile: "",
    contact_name: "",
    country_id: "",
    country_name: "",
    created_date: "",
    id: "",
    pincode: "",
    state_id: "",
    state_name: "",
    gst_no: "",
    status: "",
    updated_date: "",
  };
  const [data, setData] = useState<BranchProps>(initialData);
  const [errors, setErrors] = useState<Errors>({});
  const [countries, setCountries] = useState<CountryProps[]>([]);
  const [states, setStates] = useState<StateProps[]>([]);
  const location = useLocation();
  const params = useParams();

  const fetchCountry = async () => {
    try {
      const response = await getCountries();
      const newCountries = response.data.country_list.map(
        ({
          country_name: name,
          ...rest
        }: {
          country_name: string;
          [key: string]: any;
        }) => ({
          name,
          ...rest,
        })
      );
      let activeCountries = newCountries.filter(
        (item: any) => item.status === "Active"
      );
      setCountries(activeCountries);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  const fetchStates = async (country_name: string) => {
    try {
      const response = await getStates();
      const newStates = response.data.state_list.map(
        ({
          state_name: name,
          ...rest
        }: {
          state_name: string;
          [key: string]: any;
        }) => ({
          name,
          ...rest,
        })
      );
      const filtered = newStates.filter(
        (item: StateProps) => item.country_name === country_name
      );

      let activeStates = filtered.filter(
        (item: any) => item.status === "Active"
      );
      setStates(activeStates);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  const PopulateData = async (id: string) => {
    try {
      const dataId = id;
      if (dataId === "new") {
        let resultedData: BranchProps = Object.keys(data).reduce((obj, key) => {
          obj[key as keyof BranchProps] = "";
          return obj;
        }, {} as BranchProps);
        setData(resultedData);
      } else {
        const response = await getBranch(dataId);
        console.log("response", response);

        setData(mapToViewModel(response.data));
      }
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  const mapToViewModel = ({ ...rest }: any): any => ({ ...rest });

  useEffect(() => {
    if (params.id) {
      PopulateData(params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handlePrev = () => {
    // Programmatically navigate to a different route
    const lastSlashIndex = location.pathname.lastIndexOf("/");
    const newPathname = location.pathname.substring(0, lastSlashIndex + 1);

    let redirectUrl = (process.env.REACT_APP_liveURL + newPathname) as string;
    if (process.env.NODE_ENV === "development") {
      redirectUrl = (process.env.REACT_APP_localURL + newPathname) as string;
    }
    window.location.href = redirectUrl; // No need for type assertion
  };

  const doSubmit = async (): Promise<void> => {
    // Call the server
    console.log("Submitted", data);
    try {
      // get Country Data
      const country = await getCountries();
      const foundCountry = findItemByProperty(
        country.data.country_list,
        "country_name",
        data.country_name
      );
      const countryData = {
        country_id: foundCountry?.id,
        country_name: foundCountry?.country_name,
      };

      // Get State Data
      const state = await getStates();
      const foundState = findItemByProperty(
        state.data.state_list,
        "state_name",
        data.state_name
      );
      const stateData = {
        state_id: foundState?.id,
        state_name: foundState?.state_name,
      };

      let resultedData = { ...data, ...countryData, ...stateData };

      const response = await saveBranch(resultedData);
      toast.success(response.data.message);
      handlePrev();
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    address: Joi.string().required().label("Address"),
    branch_name: Joi.string().required().label("Branch Name"),
    city_name: Joi.string().required().label("City Name"),
    contact_email: Joi.string().required().label("Email"),
    contact_mobile: Joi.string().required().label("Mobile Number"),
    contact_name: Joi.string().required().label("Name"),
    country_id: Joi.optional().allow(""),
    country_name: Joi.string().required().label("Country Name"),
    created_date: Joi.optional().allow(""),
    id: Joi.optional().allow(""),
    pincode: Joi.string().required().label("Pin Code"),
    state_id: Joi.optional().allow(""),
    state_name: Joi.string().required().label("State Name"),
    gst_no: Joi.string().required().label("GST No."),
    status: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
  };

  const {
    renderInput,
    renderInputArea,
    renderSelect,
    renderButton,
    handleSubmit,
  } = Form<BranchProps>({
    data,
    setData,
    errors,
    setErrors,
    schema,
    onSubmit: doSubmit,
  });

  useEffect(() => {
    const buttonElement = document.querySelector(".forms-sample .btn");
    if (buttonElement) {
      buttonElement.classList.add("btn-svs");
    }
  }, []);

  useEffect(() => {
    fetchCountry();
  }, []);

  useEffect(() => {
    if (data.country_name) fetchStates(data.country_name);
  }, [data.country_name]);

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper ">
          <div className="main-panel ">
            <div className="content-wrapper ">
              <Header />

              <form onSubmit={handleSubmit} className="forms-sample">
                <div className="row ">
                  <div className="col-12 grid-margin ">
                    <div className="card ">
                      <div className="card-body">
                        <div className="form-sample">
                          <p className="card-description"> Branch Details</p>

                          <div className="row">
                            <div className="col-md-6">
                              {renderInput(
                                "branch_name",
                                'Branch Name <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-6">
                              {renderInput(
                                "gst_no",
                                'GST No. <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderInput(
                                "contact_name",
                                `Contact Person Name <span class="text-danger">*</span>`
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderInput(
                                "contact_email",
                                `Contact Person Email <span class="text-danger">*</span>`
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderInput(
                                "contact_mobile",
                                `Contact Person Phone <span class="text-danger">*</span>`
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderSelect(
                                "country_name",
                                'Country Name <span class="text-danger">*</span>',
                                countries
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderSelect(
                                "state_name",
                                'State Name <span class="text-danger">*</span>',
                                states
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderInput(
                                "city_name",
                                'City Name <span class="text-danger">*</span> '
                              )}
                            </div>
                            <div className="col-md-9">
                              {renderInput(
                                "address",
                                'Address <span class="text-danger">*</span> '
                              )}
                            </div>
                            <div className="col-md-3">
                              {renderInput(
                                "pincode",
                                'Pincode <span class="text-danger">*</span>'
                              )}
                            </div>
                          </div>

                          <p>&nbsp;</p>
                        </div>
                        <div
                          className="row "
                          style={{ width: "271px", margin: "21px auto" }}
                        >
                          {renderButton("Save")}
                          <button
                            type="button"
                            onClick={handlePrev}
                            className="btn btn-cnc"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BranchForm;
