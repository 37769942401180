import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { paginate } from "../../utils/paginate";
import Pagination from "../../common/pagination";
import LaboratoryTable from "./LaboratoryTable";
import { toast } from "react-toastify";
import auth from "../../services/authServices";
import SearchBox from "../../common/searchBox";
import PageSize from "../../common/pageSize";
import { LaboratoryProps } from "../../types/laboratoryProps";
import {
  getLabLots,
  statusLaboratory,
  trackLaboratory,
} from "../../services/lotLabService";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";
import EmptyLotsMessage from "../../common/emptyLotsMessage";
import NoLotMessage from "../../common/noLotMessage";

type Props = {};

interface RedirecData {
  branch_id: string;
  employee_id: string;
}

type ArrayProps = {
  id: string;
  name: string;
};

const TabPanelArray: ArrayProps[] = [
  {
    id: "1",
    name: "My Lots",
  },
  {
    id: "2",
    name: "All Lots",
  },
];

function LaboratoryList({}: Props) {
  const [laboratorys, setLaboratorys] = useState<LaboratoryProps[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const user = useSelector((state: UserState) => state.userReducer);
  const branch = useSelector((state: any) => state.branchReducer);

  const fetchData = async (
    user: { [key: string]: string } | null,
    currentIndex?: number,
    branch?: any
  ): Promise<void> => {
    try {
      let redirecData: RedirecData;

      redirecData = {
        branch_id: branch.id || "",
        employee_id: currentIndex === 0 ? user?.id || "" : "",
      };

      if (user?.department_name === "Admin") {
        redirecData = {
          branch_id: "",
          employee_id: "",
        };
      }

      const response = await getLabLots(redirecData);
      response?.data.error ? setLaboratorys([]) : setLaboratorys(response.data);
    } catch (error: any) {
      console.error("Error fetching lot list:", error);
    }
  };

  useEffect(() => {
    if (user && currentIndex !== undefined && branch) {
      fetchData(user, currentIndex, branch);
    }
  }, [user, currentIndex, branch]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleBin = async (laboratory: LaboratoryProps) => {
    console.log("laboratory", laboratory);
    let data = {
      lot_id: laboratory?.id,
      lot_number: laboratory.lots_number,
      bin_number: laboratory.bin_no,
      article_id: "",
      employee_id: user?.department_name === "Admin" ? "" : user?.id,
      employee_name: user?.department_name === "Admin" ? "" : user?.name,
      employee_role: user?.department_name === "Admin" ? "" : user?.role_name,
      branch_id: user?.department_name === "Admin" ? "" : branch.id,
      branch_name: user?.department_name === "Admin" ? "" : branch.name,
      department_id:
        user?.department_name === "Admin" ? "" : user?.department_id,
      department_name:
        user?.department_name === "Admin" ? "" : user?.department_name,
      status: laboratory.status === "" ? "In Laboratory" : laboratory.status,
      remarks: laboratory.remarks,
    };

    try {
      const response = await trackLaboratory(data);
      toast.success(response?.data.message);
      fetchData(user, currentIndex, branch);
      setCurrentPage(1);
      setCurrentIndex(0);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleDelete = async (laboratory: LaboratoryProps) => {
    // const originalDdata = laboratorys;
    // const data = originalDdata.filter((u) => u.id !== laboratory.id);
    // setLaboratorys(data);
    // setCurrentPage(1);

    try {
      // const response = await deletelot(lot.id);
      // toast.success(response.data.message);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleStatus = async (laboratory: LaboratoryProps) => {
    console.log("lot", laboratory);
    let data = {
      lot_id: laboratory?.id,
      lot_number: laboratory.lots_number,
      article_id: "",
      employee_id: user?.department_name === "Admin" ? "" : user?.id,
      employee_name: user?.department_name === "Admin" ? "" : user?.name,
      branch_id: user?.department_name === "Admin" ? "" : branch.id,
      branch_name: user?.department_name === "Admin" ? "" : branch.name,
      department_id:
        user?.department_name === "Admin" ? "" : user?.department_id,
      department_name:
        user?.department_name === "Admin" ? "" : user?.department_name,
      status: laboratory.status === "" ? "In Laboratory" : laboratory.status,
      remarks: laboratory.remarks,
    };

    try {
      const response = await statusLaboratory(data);
      toast.success(response?.data.message);
      fetchData(user, currentIndex, branch);
      setCurrentPage(1);
      setCurrentIndex(0);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    let filtered = laboratorys;
    if (searchQuery)
      filtered = laboratorys.filter((m) =>
        m.lots_number.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const laboratory = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: laboratory };
  };

  const { length: count } = laboratorys ?? [];
  const { totalCount, data: laboratory } = getPagedData();

  const HandlePage = () => (
    <div className="row">
      <div className="col-md-9">
        <h3 className="page-title">
          Showing {totalCount} entries in the Database
        </h3>
      </div>
      <div className="col-md-3">
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );

  const TabContent = () => (
    <div className="tab-content" id="myTabContent">
      {TabPanelArray.map((tab, index) => (
        <div
          key={tab.id}
          className={`tab-pane fade lot ${
            currentIndex === index ? "show active" : ""
          }`}
          id={`#${tab.name}-tab-pane`}
          role="tabpanel"
          aria-labelledby={`#${tab.name}-tab`}
          tabIndex={0}
        >
          <LaboratoryTable
            laboratorys={laboratory}
            onBin={handleBin}
            onDelete={handleDelete}
            onStatus={handleStatus}
          />
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    if (count === 0) {
      currentIndex === 0 ? setCurrentIndex(0) : setCurrentIndex(1);
    }
  }, [laboratorys, currentIndex, setCurrentIndex]);

  return (
    <>
      <div className="row">
        <div className="col-md-3 entries">
          <div
            style={{
              display: "grid",
              justifyContent: "space-between",
              alignItems: "center",
              gridTemplateColumns: "auto auto auto",
            }}
          >
            Showing
            <PageSize pageSize={pageSize} setPageSize={setPageSize} /> entries
          </div>
        </div>
        <div className="col-md-6 search">
          <SearchBox value={searchQuery} onChange={handleSearch} />
        </div>
        <div
          className="col-md-3 tabList"
          style={{ display: user?.department_name === "Admin" ? "none" : "" }}
        >
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            {TabPanelArray.map((tab, index) => (
              <>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      currentIndex === index ? "active" : ""
                    } `}
                    id={`${tab.name}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${tab.name}-tab-pane`}
                    type="button"
                    role="tab"
                    aria-controls={`${tab.name}-tab-pane`}
                    aria-selected="true"
                    // disabled={photography.length === 0 ? true : true}
                    onClick={() => setCurrentIndex(currentIndex === 0 ? 1 : 0)}
                  >
                    {tab.name}
                  </button>
                </li>
              </>
            ))}
          </ul>
        </div>
      </div>

      {currentIndex === 0 && laboratory.length === 0 && <EmptyLotsMessage />}
      {currentIndex === 1 && laboratory.length === 0 && <NoLotMessage />}
      {currentIndex === 0 && laboratory.length !== 0 && (
        <>
          {" "}
          <TabContent /> <HandlePage />
        </>
      )}
      {currentIndex === 1 && laboratory.length !== 0 && (
        <>
          {" "}
          <TabContent /> <HandlePage />
        </>
      )}
    </>
  );
}

export default LaboratoryList;
