import { MdHome, MdFilePresent } from "react-icons/md";
import { HiOutlineClipboardList } from "react-icons/hi";
import { SiNintendogamecube, SiWebpack } from "react-icons/si";
import { FaUserCog } from "react-icons/fa";
import { VscPersonAdd } from "react-icons/vsc";

export const MenuItem = [
  {
    icon: <MdFilePresent className="menu-icon" size={25} />,
    title: "Customers",
    submenuItems: [{ to: "customer", text: "Manage Customer" }],
  },
  {
    icon: <HiOutlineClipboardList className="menu-icon" size={25} />,
    title: "Lots",
    submenuItems: [
      { to: "lot/all", text: "In Lab Services" },
      { to: "lot/new/new", text: "Mobile Services" },
    ],
  },
  {
    icon: <SiNintendogamecube className="menu-icon" size={25} />,
    title: "Article",
  },
  {
    icon: <SiWebpack className="menu-icon" size={25} />,
    title: "Reports",
  },
];
