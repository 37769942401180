import React, { useEffect } from "react";
import dark from "../../assets/images/logo-2.png";
import transparent from "../../assets/images/transparent.png";
import "./Admin.css";
import { AdminForm } from "./../../forms/";
import { useDispatch } from "react-redux";
import { setBranch } from "../../actions";

type Props = {};

function Admin({}: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    const defaultBranch = {
      id: "",
      name: "",
    };
    dispatch(setBranch(defaultBranch));
  }, []);

  return (
    <>
      <section className="sales">
        <div className="container-fluid ps-0 pe-0 salesi">
          <div className="row">
            <div className="col-md-6 sales-left1">
              <div className="sales-left">
                <img src={dark} alt="dark.png" />
                {/* Login Form */}
                <AdminForm />
              </div>
            </div>
            <div className="col-md-6 sales-right1">
              <div className="sales-right">
                <img className="sales-rights" src={transparent} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Admin;
