import React, { useState } from "react";
import Header from "./Header";
import List from "./List";
import "./Lot.css";
import { UserState } from "../../../../types/userTypes";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
type Props = {};

function Lot({}: Props) {
  const user = useSelector((state: UserState) => state.userReducer);

  if (user && user.role_name !== "Excel Executive") {
    return <Navigate to={`/backoffice_panel/photography`} replace />;
  }

  return (
    <>
      <div className="content-wrapper">
        <Header />
        <List />
      </div>
    </>
  );
}

export default Lot;
