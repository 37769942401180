import React from "react";
import { Link, useLocation } from "react-router-dom";
import Table from "../../common/table";
import Delete from "./../../common/delete";
import Status from "./../../common/status";
import { EmployProps } from "../../types/employeeProps";

type Props = {
  employs: EmployProps[];
  onDelete: (employ: EmployProps) => void;
  onStatus: (employ: EmployProps) => void;
};

function EmployTable({ employs, onDelete, onStatus }: Props) {
  const location = useLocation();
  const columns = [
    {
      path: "employeeDetails",
      label: "Employee Details",
      content: (employ: EmployProps) => (
        <>
          <p>Name: {employ.name}</p>
          <p>Email: {employ.email}</p>
          <p>Mobile: {employ.mobile}</p>
        </>
      ),
    },
    { path: "department_name", label: "Department Name" },
    {
      path: "status",
      label: "Status",
      content: (employ: EmployProps) => (
        <>
          <Status status={employ.status} onClick={() => onStatus(employ)} />
        </>
      ),
    },
    { path: "created_date", label: "Created On" },
    {
      key: "Action",
      label: "Action",
      content: (employ: EmployProps) => (
        <>
          <Link className="btn-edt" to={employ.id}>
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </Link>
          <Delete onClick={() => onDelete(employ)} />
        </>
      ),
    },
  ];

  return <Table data={employs} columns={columns} />;
}

export default EmployTable;
