import { MdHome, MdFilePresent } from "react-icons/md";
import { HiOutlineClipboardList } from "react-icons/hi";
import { SiNintendogamecube, SiWebpack } from "react-icons/si";

export const MenuItem = [
  {
    icon: <HiOutlineClipboardList className="menu-icon" size={25} />,
    title: "Lots",
    submenuItems: [{ to: "lot/all", text: "In Lab Services" }],
  },
  {
    icon: <SiNintendogamecube className="menu-icon" size={25} />,
    title: "Article",
  },
  {
    icon: <SiWebpack className="menu-icon" size={25} />,
    title: "Reports",
  },
];
