import React, { ReactNode } from "react";

interface CustomColumnProps {
  isUserAdmin: boolean;
  isDataEmpty: boolean;
  children: ReactNode;
}

const InputCustom: React.FC<CustomColumnProps> = ({
  isUserAdmin,
  isDataEmpty,
  children,
}) => {
  const className = `col-md-4 ${
    isUserAdmin ? "" : isDataEmpty ? "" : "block-input"
  }`;

  return <div className={className}>{children}</div>;
};

export default InputCustom;
