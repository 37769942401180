import http from "./httpService";
import { apiUrl } from "../config.js";
import { ArticleSubTypeProps } from "../types/articleSubTypeProps";

const articleSubtypeEndpoint = apiUrl + "/create_update_article_subtype";

// Get the Article Subtype Data

export async function getArticleSubTypes(): Promise<any> {
  try {
    return await http.get(apiUrl + "/article_sub_type_list");
  } catch (error) {
    // Handle error
    console.error("Error retrieving articles subtypes data:", error);
    // throw error;
  }
}

export async function getArticleSubtype(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_article_sub_type", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving article subtype data:", error);
    // throw error;
  }
}

//  Create and Update Article Sub Type

export function saveArticleSubtype(article_subtype: ArticleSubTypeProps) {
  return http.post(articleSubtypeEndpoint, article_subtype);
}

// Update the status of the Article Sub type Data

export async function statusArticleSubtype(
  id: string,
  status: string
): Promise<any> {
  try {
    return await http.post(apiUrl + "/change_article_sub_type_status", {
      id: id,
      status: status,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving article sub type data:", error);
    // throw error;
  }
}

// Delete the Article Sub Type Data

export async function deleteArticleSubtype(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/delete_article_sub_type", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving article sub type data:", error);
    // throw error;
  }
}
