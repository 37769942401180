import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { ToastContent, toast } from "react-toastify";
import "./Barcode.css";
import { useReactToPrint } from "react-to-print";
import { BarcodeProps } from "../../types/barcodeProps";
import { getBarcode } from "../../services/barcodeService";
import Barcode from "react-barcode";

type Props = {};
type InvoiceDocumentRef = React.MutableRefObject<HTMLDivElement | null>;
type HandlePrintFunction = () => void;

function Certificate({}: Props) {
  const [data, setData] = useState<BarcodeProps[]>([]);
  const params = useParams();

  const invoiceDocument: InvoiceDocumentRef = useRef<HTMLDivElement>(null);

  const handlePrint: HandlePrintFunction = useReactToPrint({
    content: () => invoiceDocument.current,
  });

  const PopulateData = async (lot_number: string) => {
    try {
      const response = await getBarcode(lot_number);
      if (response.data.error) {
        return;
      }
      setData(response.data.barcode_list);
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    if (params.lots_number) {
      let lot_number = params.lots_number;
      PopulateData(lot_number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.lots_number]);

  if (data?.length === 0)
    return (
      <div className="d-flex justify-content-center align-items-center flex-column vh-100">
        <p className="text-center">
          Apologize, but the barcode isn't generated yet. <br />
          Please come to collect it later.
        </p>
      </div>
    );

  return (
    <>
      <section
        className="jewellerycert barcode-container"
        ref={invoiceDocument}
      >
        <div className="row" style={{ width: "100%" }}>
          {data?.map((item) => (
            <>
              <div className="col-md-4 pb-4">
                <h6>
                  CERTIFICATE NO <strong>: {item?.certificate_number}</strong>
                </h6>

                <Barcode value={item?.barcode_number} />
              </div>
            </>
          ))}
        </div>
      </section>
      <div className="d-flex justify-content-center">
        <button className="btn btn-outline-primary" onClick={handlePrint}>
          idtworldwide.com
        </button>
      </div>
    </>
  );
}

export default Certificate;
