import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

// Assets for JS Files

import "bootstrap/dist/js/bootstrap.bundle.min.js";

// Assets for CSS Files

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import "../assets/css/style.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import { Footer } from "./../components/";

import AuthRoute from "./authRoute";
import AccountsRoute from "./accountsRoute";
import BackofficeRoute from "./backofficeRoute";
import AdminRoute from "./adminRoute";
import FrontDeskRoute from "./frontdeskRoute";
import LabRoute from "./labRoute";
import PhotographyRoute from "./photographyRoute";
import SalesRoute from "./salesRoute";
import ScreeningRoute from "./screeningRoute";
import auth from "../services/authServices";

import { setBranch, setUser } from "../actions";
import { UserState } from "../types/userTypes";
import AdministratorRoute from "./administratorRoute";
import EngravingRoute from "./engravingRoute";
import { saveEmployeeExistence } from "../services/employeeService";

function RootNavigation() {
  const [department, setDepartment] = useState<string | null>(null);

  const dispatch = useDispatch();
  const user = useSelector((state: UserState) => state.userReducer);
  const branch = useSelector((state: any) => state.branchReducer);

  useEffect(() => {
    const currentUser = auth.getCurrentUser();
    dispatch(setUser(currentUser));
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.department_name !== "Admin") {
        try {
          let response = await saveEmployeeExistence({
            id: user.id,
            email: user.email,
          });
        } catch (ex: any) {
          if (ex.response && ex.response.status >= 400) {
            await auth.logout();
          }
        }
      }
    };

    fetchData(); // Call the fetchData function
  }, [user]);

  useEffect(() => {
    const jwt = localStorage.getItem("user");
    setDepartment(jwt);
  }, []);

  useEffect(() => {
    if (user) {
      if (user.department_name !== "Admin" && branch) {
        if (branch.id == "") {
          const defaultBranch = {
            id: user?.employee_branch[0]?.branch_id || "",
            name: user?.employee_branch[0]?.branch_name || "",
          };
          dispatch(setBranch(defaultBranch));
        } else {
          const existingItemIndex = user?.employee_branch.findIndex(
            (item: any) => item.branch_id === branch.id
          );

          if (existingItemIndex !== -1) {
            dispatch(setBranch(branch));
          } else {
            const defaultBranch = {
              id: user?.employee_branch[0]?.branch_id || "",
              name: user?.employee_branch[0]?.branch_name || "",
            };
            dispatch(setBranch(defaultBranch));
          }
        }
      }
    }
  }, [user, branch, dispatch]);

  return (
    <>
      <ToastContainer />
      {user && (
        <>
          {user.department_name === "Accounts" && <AccountsRoute />}
          {user.department_name === "Admin" && <AdminRoute />}
          {user.department_name === "Administrator" && <AdministratorRoute />}
          {user.department_name === "Back Office" && <BackofficeRoute />}
          {user.department_name === "Front Office" && <FrontDeskRoute />}
          {user.department_name === "Laboratory" && <LabRoute />}
          {user.department_name === "Photography" && <PhotographyRoute />}
          {user.department_name === "Sales" && <SalesRoute />}
          {user.department_name === "Screening" && <ScreeningRoute />}
          {user.department_name === "Engraving" && <EngravingRoute />}
        </>
      )}
      {!user && <AuthRoute department={department} />}

      <Footer />
    </>
  );
}

export default RootNavigation;
