import React from "react";
import "./Footer.css";

type Props = {};

function Footer({}: Props) {
  return (
    <>
      <footer className="footer ">
        <div className="d-sm-flex justify-content-center justify-content-sm-between ">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block ">
            Copyright © 2023{" "}
            <a href="# " target="_blank ">
              Idtworldwide.com
            </a>
            . All rights reserved.
          </span>
          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center ">
            Developed by{" "}
            <a href="https://soulilution.com/">Soulilution IT Services</a>{" "}
          </span>
        </div>
      </footer>
    </>
  );
}

export default Footer;
