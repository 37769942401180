import http from "./httpService";
import { apiUrl } from "../config.js";
import { AuthorizedProps } from "../types/authorizedProps";

const authorizedEndpoint = apiUrl + "/create_update_customer_authorize_person";

// Get the Authorizeds Data

export async function getAuthorizedPersons(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/customer_authorize_person_list", {
      id: id,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving authorizeds data:", error);
    // throw error;
  }
}

export async function getAuthorizedPerson(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_customer_authorize_person", {
      id: id,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving authorized data:", error);
    // throw error;
  }
}

//  Create and Update Authorized

export function saveAuthorized(authorized: AuthorizedProps) {
  return http.post(authorizedEndpoint, authorized);
}

// Update the status of the Authorized Data

export async function statusAuthorized(
  id: string,
  status: string
): Promise<any> {
  try {
    return await http.post(
      apiUrl + "/change_customer_authorize_person_status",
      {
        id: id,
        status: status,
      }
    );
  } catch (error) {
    // Handle error
    console.error("Error retrieving authorized data:", error);
    // throw error;
  }
}

// Delete the Authorized Data

export async function deleteAuthorized(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/delete_customer_authorize_person", {
      id: id,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving authorized data:", error);
    // throw error;
  }
}
