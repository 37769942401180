import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { paginate } from "../../utils/paginate";
import Pagination from "../../common/pagination";
import BranchTable from "./BranchTable";
import { toast } from "react-toastify";
import {
  deleteBranch,
  getBranches,
  statusBranch,
} from "../../services/branchService";
import { BranchProps } from "../../types/branchProps";
import SearchBox from "../../common/searchBox";
import PageSize from "../../common/pageSize";

type Props = {};

function BranchList({}: Props) {
  const [branches, setBranches] = useState<BranchProps[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const fetchData = async () => {
    try {
      const response = await getBranches();
      setBranches(response?.data?.branch_list ?? []);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleDelete = async (branch: BranchProps) => {
    const originalDdata = branches;
    const data = originalDdata.filter((u) => u.id !== branch.id);
    setBranches(data);
    setCurrentPage(1);

    try {
      const response = await deleteBranch(branch.id);
      toast.success(response.data.message);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleStatus = async (branch: BranchProps) => {
    let status: string = branch.status === "Active" ? "Inactive" : "Active";

    try {
      const response = await statusBranch(branch.id, status);
      toast.success(response.data.message);
      fetchData();
      // setCurrentPage(1);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    let filtered = branches;
    if (searchQuery)
      filtered = branches.filter((m) =>
        m.branch_name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const branch = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: branch };
  };

  const { length: count } = branches;

  if (count === 0) return <p>There are no data in the database.</p>;

  const { totalCount, data: branch } = getPagedData();

  return (
    <>
      <div className="row">
        <div className="col-md-3 entries">
          <div
            style={{
              display: "grid",
              justifyContent: "space-between",
              alignItems: "center",
              gridTemplateColumns: "auto auto auto",
            }}
          >
            Showing
            <PageSize pageSize={pageSize} setPageSize={setPageSize} /> entries
          </div>
        </div>
        <div className="col-md-6 offset-md-3 search">
          <SearchBox value={searchQuery} onChange={handleSearch} />
        </div>
      </div>

      <BranchTable
        branches={branch}
        onDelete={handleDelete}
        onStatus={handleStatus}
      />
      <div className="row">
        <div className="col-md-9">
          <h3 className="page-title">
            Showing {totalCount} entries in the Database
          </h3>
        </div>
        <div className="col-md-3">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default BranchList;
