import React, { useState } from "react";
import Joi from "joi-browser";
import "./SigninForm.css";
import Form from "../../common/form";
import auth from "../../services/authServices";
import { toast } from "react-toastify";
import { EmployFormProps } from "../../types/employFormProps";
import { apiUrl } from "../../config";

interface Errors {
  [key: string]: string | undefined;
}

function SigninForm() {
  const initialData: EmployFormProps = { email: "", password: "" };
  const [data, setData] = useState<EmployFormProps>(initialData);
  const [errors, setErrors] = useState<Errors>({});
  const employEndpoint = apiUrl + "/employee_signin";

  const doSubmit = async () => {
    // Call the server
    console.log("Submitted", data);
    try {
      await auth.login(employEndpoint, data, "email", "password");
      let redirectUrl = process.env.REACT_APP_liveURL as string;
      if (process.env.NODE_ENV === "development") {
        redirectUrl = process.env.REACT_APP_localURL as string;
      }
      window.location.href = redirectUrl; // No need for type assertion
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    email: Joi.string().email().required().label("Email"),
    password: Joi.string().required().label("Password"),
  };

  const { renderInput, renderButton, handleSubmit } = Form<EmployFormProps>({
    data,
    setData,
    errors,
    setErrors,
    schema,
    onSubmit: doSubmit,
  });
  return (
    <>
      {/* Login Form */}
      <form className="logine" onSubmit={handleSubmit}>
        {renderInput("email", "Email", "email")}
        {renderInput("password", "Password", "password")}
        {renderButton("Login")}
        <p>&nbsp;</p>
      </form>
    </>
  );
}

export default SigninForm;
