import http from "./httpService";
import { apiUrl } from "../config.js";
import { DepartmentProps } from "../types/departmentProps";

const departmentEndpoint = apiUrl + "/create_update_department";

// Get the Department data

export async function getDepartments(): Promise<any> {
  try {
    return await http.get(apiUrl + "/department_list");
  } catch (error) {
    // Handle error
    console.error("Error retrieving departments data:", error);
    // throw error;
  }
}

export async function getDepartment(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_department", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving department data:", error);
    // throw error;
  }
}

//  Create and Update Department

export function saveDepartment(department: DepartmentProps) {
  return http.post(departmentEndpoint, department);
}

// Update the status of the Department data

export async function statusDepartment(
  id: string,
  status: string
): Promise<any> {
  try {
    return await http.post(apiUrl + "/change_department_status", {
      id: id,
      status: status,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving department data:", error);
    // throw error;
  }
}

// Delete the Department data

export async function deleteDepartment(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/delete_department", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving department data:", error);
    // throw error;
  }
}
