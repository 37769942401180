import React from "react";
import QRCode from "react-qr-code";

type Props = { data: any[] };

function EightBlock({ data }: Props) {
  return (
    <>
      <section className="content-container">
        {data?.map((item) => (
          <>
            <div className="content">
              <div className="content-summary">
                {/* Content Header */}

                <div className="content-header">
                  <QRCode
                    size={128}
                    style={{
                      height: "auto",
                      maxWidth: "100%",
                      width: "45px",
                      float: "right",
                      // position: "absolute",
                      // right: 0,
                    }}
                    value={item?.barcode_number}
                    viewBox={`0 0 256 256`}
                  />
                  <h5>STUDDED JEWELLERY ANALYSIS</h5>
                </div>

                {/* Content Body */}

                <div className="content-body">
                  <div
                    className="row"
                    // style={{ marginLeft: "0px", marginRight: "0px" }}
                  >
                    <div className="col-3">
                      <h6>CERTIFICATE NO</h6>
                    </div>
                    <div className="col-1">
                      <h6>:</h6>
                    </div>
                    <div className="col-8">
                      <h6>{item?.certificate_number}</h6>
                    </div>
                    <div className="col-3">
                      <h6>DESCRIPTION</h6>
                    </div>
                    <div className="col-1">
                      <h6>:</h6>
                    </div>
                    <div className="col-8">
                      <h6>{item?.description}</h6>
                    </div>
                    <div className="col-3">
                      <h6>SHAPE/CUT</h6>
                    </div>
                    <div className="col-1">
                      <h6>:</h6>
                    </div>
                    <div className="col-8">
                      <h6>{item?.shape_title}</h6>
                    </div>
                    <div className="col-3">
                      <h6>DIAMOND WEIGHT</h6>
                    </div>
                    <div className="col-1">
                      <h6>:</h6>
                    </div>
                    <div className="col-8">
                      <h6>{item?.dia_weight}</h6>
                    </div>
                    <div className="col-3">
                      <h6>MIN. COLOR</h6>
                    </div>
                    <div className="col-1">
                      <h6>:</h6>
                    </div>
                    <div className="col-8">
                      <h6>{item?.color}</h6>
                    </div>
                    <div className="col-3">
                      <h6>MIN. CLARITY</h6>
                    </div>
                    <div className="col-1">
                      <h6>:</h6>
                    </div>
                    <div className="col-8">
                      <h6>{item?.clarity}</h6>
                    </div>
                    <div className="col-3">
                      <h6>FINISH</h6>
                    </div>
                    <div className="col-1">
                      <h6>:</h6>
                    </div>
                    <div className="col-8">
                      <h6>{item?.finish}</h6>
                    </div>
                    <div className="col-3">
                      <h6>COMMENTS</h6>
                    </div>
                    <div className="col-1">
                      <h6>:</h6>
                    </div>
                    <div className="col-8">
                      <h6>{item?.comments}</h6>
                    </div>
                  </div>
                  <img src={item?.article_photo} />
                </div>

                {/* Content Footer */}

                <div className="content-footer">
                  {/* <p>This is an example of an A4 size sheet in HTML and CSS.</p> */}
                </div>
              </div>
            </div>
          </>
        ))}
      </section>
    </>
  );
}

export default EightBlock;
