import React from "react";
import { Link, useLocation } from "react-router-dom";
import Table from "../../common/table";
import Delete from "./../../common/delete";
import Status from "../../common/status";
import { BranchProps } from "../../types/branchProps";

type Props = {
  branches: BranchProps[];
  onDelete: (branch: BranchProps) => void;
  onStatus: (branch: BranchProps) => void;
};

function BranchTable({ branches, onDelete, onStatus }: Props) {
  const location = useLocation();

  const columns = [
    {
      path: "branch_name",
      label: "Branch Name",
    },
    {
      path: "contact_name",
      label: "Contact Name",
    },
    {
      path: "address",
      label: "Address",
    },
    {
      path: "status",
      label: "Status",
      content: (branch: BranchProps) => (
        <>
          <Status status={branch.status} onClick={() => onStatus(branch)} />
        </>
      ),
    },
    {
      key: "Action",
      label: "Action",
      content: (branch: BranchProps) => (
        <>
          <Link className="btn-edt" to={branch.id}>
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </Link>
          <Delete onClick={() => onDelete(branch)} />
        </>
      ),
    },
  ];

  return <Table data={branches} columns={columns} />;
}

export default BranchTable;
