import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { paginate } from "../../utils/paginate";
import Pagination from "../../common/pagination";
import AuthorizedTable from "./AuthorizedTable";
import { toast } from "react-toastify";
import SearchBox from "../../common/searchBox";
import PageSize from "../../common/pageSize";
import { AuthorizedProps } from "../../types/authorizedProps";
import {
  deleteAuthorized,
  getAuthorizedPersons,
  statusAuthorized,
} from "../../services/authorizedService";

type Props = {};

function AuthorizedList({}: Props) {
  const [authorizeds, setAuthorizeds] = useState<AuthorizedProps[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const params = useParams();

  const fetchData = async (id: string): Promise<void> => {
    try {
      const response = await getAuthorizedPersons(id);
      setAuthorizeds(response.data.customer_authorize_list ?? []);
    } catch (error: any) {
      console.error("Error fetching authorized list:", error);
    }
  };

  useEffect(() => {
    if (params.id) {
      fetchData(params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleDelete = async (authorized: AuthorizedProps) => {
    const originalDdata = authorizeds;
    const data = originalDdata.filter((u) => u.id !== authorized.id);
    setAuthorizeds(data);
    setCurrentPage(1);

    try {
      const response = await deleteAuthorized(authorized.id);
      toast.success(response.data.message);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleStatus = async (authorized: AuthorizedProps) => {
    let status: string = authorized.status === "Active" ? "Inactive" : "Active";

    try {
      const response = await statusAuthorized(authorized.id, status);
      toast.success(response.data.message);
      setCurrentPage(1);
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    let filtered = authorizeds;
    if (searchQuery)
      filtered = authorizeds.filter((m) =>
        m.auth_name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const authorized = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: authorized };
  };

  const { length: count } = authorizeds ?? [];

  if (count === 0) {
    return <p>There is no data in the database.</p>;
  }

  const { totalCount, data: authorized } = getPagedData();

  return (
    <>
      <div className="row">
        <div className="col-md-3 entries">
          <div
            style={{
              display: "grid",
              justifyContent: "space-between",
              alignItems: "center",
              gridTemplateColumns: "auto auto auto",
            }}
          >
            Showing
            <PageSize pageSize={pageSize} setPageSize={setPageSize} /> entries
          </div>
        </div>
        <div className="col-md-6 offset-md-3 search">
          <SearchBox value={searchQuery} onChange={handleSearch} />
        </div>
      </div>

      <AuthorizedTable
        authorizeds={authorized}
        onDelete={handleDelete}
        onStatus={handleStatus}
      />
      <div className="row">
        <div className="col-md-9">
          <h3 className="page-title">
            Showing {totalCount} entries in the Database
          </h3>
        </div>
        <div className="col-md-3">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default AuthorizedList;
