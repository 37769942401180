import http from "./httpService";
import { apiUrl } from "../config.js";
import { LotProps } from "./../types/lotProps";
import { StatusLotProps } from "../types/statusLotType";

const lotEndpoint = apiUrl + "/create_update_lot_articles";

// Get the Lots Data

export async function getLots(lot: { [key: string]: any }): Promise<any> {
  try {
    return await http.post(apiUrl + "/lots_list", {
      employee_id: lot.employee_id,
      branch_id: lot.branch_id,
      customer_id: lot.customer_id,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving lots data:", error);
    // throw error;
  }
}

export async function getLot(id: string): Promise<any> {
  try {
    return await http.post(apiUrl + "/get_lot_details", { id: id });
  } catch (error) {
    // Handle error
    console.error("Error retrieving lot data:", error);
    // throw error;
  }
}

// //  Create and Update Lot

export function saveLot(lot: LotProps) {
  return http.post(lotEndpoint, lot);
}

// Update the status of the Lot Data

export async function statusLot(lot: any) {
  try {
    return await http.post(apiUrl + "/update_status", lot);
  } catch (error) {
    // Handle error
    console.error("Error retrieving article data:", error);
    // throw error;
  }
}
export async function statusAccept(lot: any) {
  try {
    return await http.post(apiUrl + "/manager_update_status", lot);
  } catch (error) {
    // Handle error
    console.error("Error retrieving manager update status data:", error);
    // throw error;
  }
}
export async function statusEngraved(lot: any) {
  try {
    return await http.post(apiUrl + "/update_engraving_status", lot);
  } catch (error) {
    // Handle error
    console.error("Error retrieving update engraving status data:", error);
    // throw error;
  }
}
// Update the track status of the Lot Data

export async function trackLot(lot: StatusLotProps) {
  try {
    return await http.post(apiUrl + "/update_bin", lot);
  } catch (error) {
    // Handle error
    console.error("Error retrieving article data:", error);
    // throw error;
  }
}

// // Delete the Article Data

// export async function deleteArticle(id: string): Promise<any> {
//   try {
//     return await http.post(apiUrl + "/delete_article", { id: id });
//   } catch (error) {
//     // Handle error
//     console.error("Error retrieving article data:", error);
//     // throw error;
//   }
// }
