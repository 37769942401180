import React from "react";
import Header from "./Header";
import Body from "./Body";
import Customer from "./Customer";

type Props = {};

function Panel({}: Props) {
  return (
    <>
      <div className="content-wrapper">
        <Header />
        <Body />
        <Customer />
      </div>
    </>
  );
}

export default Panel;
