import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "../../common/table";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";
import { InvoiceProps } from "../../types/invoiceProps";

type Props = {
  invoices: InvoiceProps[];
};

function InvoiceTable({ invoices }: Props) {
  const user = useSelector((state: UserState) => state.userReducer);
  const [data, setData] = useState<any>();

  let columns = [
    { path: "invoice_id", label: "Invoice ID" },
    { path: "lots_number", label: "Lot Number" },
    {
      path: "customerDetails",
      label: "Customer Details",
      content: (invoice: InvoiceProps) => (
        <>
          <p style={{ whiteSpace: "break-spaces", width: "240px" }}>
            GST No.: {invoice.bill_gst} <br />
            Name: {invoice.bill_to}
            <br />
            Mobile: {invoice.bill_phone}
          </p>
        </>
      ),
    },
    {
      path: "invoice_date",
      label: "Invoice Date",
      content: (invoice: InvoiceProps) => (
        <>{invoice?.invoice_date.split(" ")[0]}</>
      ),
    },
    {
      key: "Action",
      label: "Actions",
      content: (invoice: InvoiceProps) => (
        <>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ flexWrap: "wrap", width: "120px", height: "100px" }}
          >
            {((user.department_name !== "Back Office" &&
              user.department_name !== "Engraving") ||
              (user.department_name === "Admin" &&
                (invoice.status === "Approved" ||
                  invoice.status === "Invoice Generated"))) && (
              <>
                {/* Statusinvoice component */}

                {/* View Invoice link */}
                <Link
                  style={{
                    display:
                      invoice.status === "Invoice Generated" ? "block" : "none",
                  }}
                  className="btn-edt"
                  to={`/accounts_panel/lot/all/invoice/${invoice.lots_number}`}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="View Invoice"
                  state={{ type: "after" }}
                >
                  <i className="fa fa-info" aria-hidden="true"></i>
                </Link>
              </>
            )}
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (user.role_name === "Excel Executive") {
      const labelToRemove = "Customer Details";
      columns = columns.filter((column) => column.label !== labelToRemove);
      setData(columns);
    } else {
      setData(columns);
    }
  }, [user]);

  if (!data) return null;

  return <Table data={invoices} columns={data} />;
}

export default InvoiceTable;
