import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Error from "./../pages/Error/Error";
import {
  Account,
  Panel,
  Invoice as InvoiceData,
} from "../dashboard/AccountsDashboard/pages/";
import AccountsDashboard from "./../dashboard/AccountsDashboard/AccountsDashboard";
import Logout from "../common/logout";
import { Invoice } from "../components";
import { InvoiceDetailList } from "../tables";
import { InvoiceDetailForm } from "../forms";

type Props = {};

function AccountsRoute({}: Props) {
  return (
    <>
      <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/accounts_panel" element={<AccountsDashboard />}>
          <Route index element={<Panel />} />
          <Route path="lot/:id" element={<Account />} />
          <Route path="lot/:id/invoice/:lots_number" element={<Invoice />} />
          <Route path="invoices/:id" element={<InvoiceData />} />
          <Route path="invoices/:id/:id" element={<InvoiceDetailList />} />

          {/* <Route path="invoices/:id/:lots_number" element={<Invoice />} /> */}
          <Route
            path="invoices/:id/:id/create/:lots_number"
            element={<InvoiceDetailForm />}
          />
          <Route path="*" element={<Error />} />
        </Route>
        <Route path="/" element={<Navigate to="/accounts_panel" replace />} />
        <Route
          path="/admin_login"
          element={<Navigate to="/accounts_panel" replace />}
        />
        <Route
          path="/employee_login"
          element={<Navigate to="/accounts_panel" replace />}
        />

        <Route path="/logout" element={<Logout />} />
      </Routes>
    </>
  );
}

export default AccountsRoute;
