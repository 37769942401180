import React, { useState, useEffect, useRef } from "react";
import "./Invoice.css";
import { useReactToPrint } from "react-to-print";
import logo from "../../assets/images/logo.png";
import { InvoiceProps } from "../../types/invoiceProps";
import { useLocation, useParams } from "react-router-dom";
import { ToastContent, toast } from "react-toastify";
import { getInvoice } from "../../services/invoiceService";
import HTMLTableToExcel from "react-html-table-to-excel";

type Props = {};
type InvoiceDocumentRef = React.MutableRefObject<HTMLTableElement | null>;
type HandlePrintFunction = () => void;

function Invoice({}: Props) {
  const [data, setData] = useState<InvoiceProps>();
  const params = useParams();
  const location = useLocation();

  const formatter: Intl.NumberFormat = new Intl.NumberFormat("hi", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const invoiceDocument: InvoiceDocumentRef = useRef<HTMLTableElement | null>(
    null
  );

  const handlePrint: HandlePrintFunction = useReactToPrint({
    content: () => invoiceDocument.current,
  });

  const PopulateData = async (lots_number: string, type: string) => {
    try {
      const response = await getInvoice(lots_number, type);
      setData(response.data);
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    if (params.lots_number && location.state) {
      PopulateData(params.lots_number, location.state.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.lots_number, location]);

  console.log("data", data);

  return (
    <>
      <section
        className="main-invoice"
        id="table-to-export"
        ref={invoiceDocument}
      >
        <div className="invoice-top"></div>
        <div className="invoice-header">
          <img src={logo} />
          <h6>idtworldwide.com</h6>
        </div>
        <div className="invoice-table-1">
          <h3>TAX INVOICE</h3>
          <table
            className="table table-bordered"
            style={{ marginBottom: "0px" }}
            id="table-to-export"
            ref={invoiceDocument}
          >
            <thead>
              <tr>
                <th colSpan={2}>Invoice No : {data?.invoice_id}</th>
                <th colSpan={2}>Dated: {data?.invoice_date}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "120px" }}>Billed From </td>
                <td style={{ width: "384px" }}>
                  <strong>{data?.billed_from}</strong>
                </td>
                <td style={{ width: "120px" }}>Bill To</td>
                <td style={{ width: "384px" }}>
                  <strong>{data?.bill_to}</strong>
                </td>
              </tr>
              <tr>
                <td style={{ width: "120px" }}>Address</td>
                <td style={{ width: "384px" }}>
                  <strong>{data?.billed_address}</strong>
                </td>
                <td style={{ width: "120px" }}>Address</td>
                <td style={{ width: "384px" }}>
                  <strong>{data?.bill_address}</strong>
                </td>
              </tr>
              <tr>
                <td style={{ width: "120px" }}>Telephone</td>
                <td style={{ width: "384px" }}>
                  <strong>{data?.billed_phone}</strong>
                </td>
                <td style={{ width: "120px" }}>Tel</td>
                <td style={{ width: "384px" }}>
                  <strong>{data?.bill_phone}</strong>
                </td>
              </tr>
              <tr>
                <td style={{ width: "120px" }}>GST</td>
                <td style={{ width: "384px" }}>
                  <strong>{data?.billed_gst}</strong>
                </td>
                <td style={{ width: "120px" }}>GST</td>
                <td style={{ width: "384px" }}>
                  <strong>{data?.bill_gst}</strong>
                </td>
              </tr>
            </tbody>
          </table>

          <table
            className="table table-bordered invoice-table-2"
            style={{ marginBottom: "0px" }}
            id="table-to-export"
            ref={invoiceDocument}
          >
            <thead>
              <tr>
                <th>Service </th>
                <th>Article Name</th>
                <th style={{ width: "30px" }}>Quantity</th>
                <th>Unit</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              {data?.item_list?.map((item: any, index) => (
                <>
                  <tr>
                    <td>{item.description}</td>
                    <td>{item.article_name}</td>
                    <td>{item.quantity}</td>
                    <td>{item.unit}</td>
                    <td>{item.sub_total}</td>
                  </tr>
                </>
              ))}

              <tr>
                <td colSpan={4}> </td>
                <td>
                  <strong>
                    SGST(9%) {formatter.format(Number(data?.sgst))}
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan={4}> </td>
                <td>
                  <strong>
                    CGST(9%) {formatter.format(Number(data?.cgst))}
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan={4}> </td>
                <td>
                  <strong>
                    IGST(18%) {formatter.format(Number(data?.igst))}
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan={4}> </td>
                <td>
                  <strong>
                    Total GST {formatter.format(Number(data?.totalgst))}
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan={4}> </td>
                <td>
                  <strong>
                    Total {formatter.format(Number(data?.total_amount))}
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan={4}> </td>
                <td>
                  <strong>
                    Advance Amount{" "}
                    {formatter.format(Number(data?.advance_amount))}
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan={4}> </td>
                <td>
                  <strong>
                    Discount Amount{" "}
                    {formatter.format(Number(data?.discount_amount))}
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan={4}> </td>
                <td>
                  <strong>
                    Grand Total(9%){" "}
                    {formatter.format(Number(data?.grand_total))}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="term-box">
          <div className="term-box-left">
            <h6>Terms &amp; Conditions -</h6>
            <p>Please make payment immediately on receiving the invoice.</p>
            <h6>Declaration -</h6>
            <p>
              We declare that invoice shows actual price of services.Described
              and that particulars are true and correct to our knowledge
            </p>
            <hr />
            <h6>Please Make Payment to</h6>
            <p>
              Beneficiary - IDT Gemological Laboratories Worldwide Private
              Limited.
              <br />
              Account No - 920020052323017
              <br />
              Bank Name - Axis Bank Limited
              <br />
              IFSC - UTIB0000589
            </p>
          </div>
          <div className="term-box-right">
            <h6>For IDT Gemological Laboratories Worldwide Pvt. Ltd.</h6>
            <div className="signtur">
              <label>Signature &amp; Stamp</label>
              <label></label>
            </div>
          </div>
        </div>
        <div className="signtr-req">
          <p>This is computer generated invoice no signature required.</p>
        </div>

        <div className="invoic-footer">
          <h4>IDT GEMOLOGICAL LABORATORIES WORLDWIDE PRIVATE</h4>
          <p>
            Regd.off.2396/11 | karol Bagh | New Delhi | 110005 | India |
            T.+91114561555 | Helpline+919877199999 CIN No.U7499DL2020PTC363943 |
            PAN No.AAFC16447R{" "}
          </p>
        </div>
      </section>
      <div className="d-flex justify-content-center">
        <button className="btn btn-outline-primary" onClick={handlePrint}>
          idtworldwide.com
        </button>
        <HTMLTableToExcel
          id="export-to-excel-btn"
          className="btn btn-outline-primary"
          table="table-to-export"
          filename="invoice_data"
          sheet="sheet1"
          buttonText="Export to Excel"
        />
      </div>
    </>
  );
}

export default Invoice;
