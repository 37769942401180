import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Joi from "joi-browser";
import "./AuthorizedForm.css";
import { ToastContent, toast } from "react-toastify";
import { CountryProps } from "../../types/countryProps";
import { StateProps } from "../../types/stateProps";
import { getStates } from "../../services/stateService";
import { getCountries } from "../../services/countryService";
import Form from "../../common/form";
import findItemByProperty from "../../utils/findItem";
import { getBranches } from "../../services/branchService";
import Header from "./Header";
import { AuthorizedProps } from "./../../types/authorizedProps";
import {
  getAuthorizedPerson,
  saveAuthorized,
} from "../../services/authorizedService";
import { getCustomers } from "../../services/customerService";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";

type Props = {};

interface Errors {
  [key: string]: string | undefined;
}

const AuthorizedForm = ({}: Props) => {
  const initialData: AuthorizedProps = {
    id: "",
    customer_id: "",
    customer_name: "",
    auth_name: "",
    auth_email: "",
    auth_mobile: "",
    auth_kyc: "",
    auth_profile_pic: "",
    designation: "",
    country_id: "",
    country_name: "",
    state_id: "",
    state_name: "",
    city_name: "",
    address: "",
    pincode: "",
    status: "",
    created_date: "",
    updated_date: "",
  };
  const [data, setData] = useState<AuthorizedProps>(initialData);
  const [errors, setErrors] = useState<Errors>({});
  const [countries, setCountries] = useState<CountryProps[]>([]);
  const [states, setStates] = useState<StateProps[]>([]);
  const [base64Image, setBase64Image] = useState<string>("");
  const location = useLocation();
  const params = useParams();
  const user = useSelector((state: UserState) => state.userReducer);

  const fetchCountry = async () => {
    try {
      const response = await getCountries();
      const newCountries = response.data.country_list.map(
        ({
          country_name: name,
          ...rest
        }: {
          country_name: string;
          [key: string]: any;
        }) => ({
          name,
          ...rest,
        })
      );
      setCountries(newCountries);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  const fetchStates = async (country_name: string) => {
    try {
      const response = await getStates();
      const newStates = response.data.state_list.map(
        ({
          state_name: name,
          ...rest
        }: {
          state_name: string;
          [key: string]: any;
        }) => ({
          name,
          ...rest,
        })
      );
      const filtered = newStates.filter(
        (item: StateProps) => item.country_name === country_name
      );

      setStates(filtered);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  const PopulateData = async (id: string) => {
    try {
      const dataId = id;
      if (dataId === "new") {
        let emptyData: AuthorizedProps = Object.keys(data).reduce(
          (obj, key) => {
            obj[key as keyof AuthorizedProps] = "";
            return obj;
          },
          {} as AuthorizedProps
        );

        setData(emptyData);
      } else {
        const response = await getAuthorizedPerson(dataId);
        setData(mapToViewModel(response.data));
      }
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  const mapToViewModel = ({ ...rest }: any): any => ({ ...rest });

  useEffect(() => {
    if (params.id) {
      PopulateData(params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handlePrev = () => {
    // Programmatically navigate to a different route
    const lastSlashIndex = location.pathname.lastIndexOf("/");
    const newPathname = location.pathname.substring(0, lastSlashIndex + 1);

    let redirectUrl = (process.env.REACT_APP_liveURL + newPathname) as string;
    if (process.env.NODE_ENV === "development") {
      redirectUrl = (process.env.REACT_APP_localURL + newPathname) as string;
    }
    window.location.href = redirectUrl; // No need for type assertion
  };

  const doSubmit = async (): Promise<void> => {
    // Call the server
    console.log("Submitted", data);
    try {
      // get Country Data
      const country = await getCountries();
      const foundCountry = findItemByProperty(
        country.data.country_list,
        "country_name",
        data.country_name
      );
      const countryData = {
        country_id: foundCountry?.id,
        country_name: foundCountry?.country_name,
      };

      // Get State Data
      const state = await getStates();
      const foundState = findItemByProperty(
        state.data.state_list,
        "state_name",
        data.state_name
      );
      const stateData = {
        state_id: foundState?.id,
        state_name: foundState?.state_name,
      };

      let customer_data = {
        employee_id: "",
        branch_id: "",
      };

      // Get Customer Data
      const customer = await getCustomers(customer_data);
      const foundCustomer = findItemByProperty(
        customer.data.customer_list,
        "id",
        location.pathname.split("/").slice(-2, -1)[0]
      );
      const customerData = {
        customer_id: foundCustomer?.id,
        customer_name: foundCustomer?.name,
      };

      let resultedData = {
        ...data,
        ...countryData,
        ...stateData,
        ...customerData,
      };

      const response = await saveAuthorized(resultedData);
      toast.success(response.data.message);
      handlePrev();
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    id: Joi.optional().allow(""),
    customer_id: Joi.optional().allow(""),
    customer_name: Joi.optional().allow(""),
    auth_name: Joi.string().required().label("Authorize Person Name"),
    auth_email: Joi.string().required().label("Authorize Person Email"),
    auth_mobile: Joi.string().required().label("Authorize Mobile No."),
    auth_kyc: Joi.string().required().label("Authorize KYC"),
    auth_profile_pic: Joi.string().required().label("Authorize Person Pic"),
    designation: Joi.optional().allow(""),
    country_id: Joi.optional().allow(""),
    country_name: Joi.string().required().label("Country Name"),
    state_id: Joi.optional().allow(""),
    state_name: Joi.string().required().label("State Name"),
    city_name: Joi.string().required().label("City Name"),
    address: Joi.string().required().label("Address"),
    pincode: Joi.string().required().label("Pincode"),
    status: Joi.optional().allow(""),
    created_date: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
  };

  const { renderInput, renderSelect, renderFile, renderButton, handleSubmit } =
    Form<AuthorizedProps>({
      data,
      setData,
      errors,
      setErrors,
      schema,
      base64Image,
      setBase64Image,
      onSubmit: doSubmit,
    });

  useEffect(() => {
    const buttonElement = document.querySelector(".form-horizontal .btn");
    if (buttonElement) {
      buttonElement.classList.add("btn-svs");
    }
  }, []);

  useEffect(() => {
    fetchCountry();
  }, []);

  useEffect(() => {
    if (data.country_name) fetchStates(data.country_name);
  }, [data.country_name]);

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper ">
          <div className="main-panel ">
            <div className="content-wrapper ">
              <Header />

              <form onSubmit={handleSubmit} className="form-horizontal">
                <div className="row ">
                  <div className="col-12 grid-margin ">
                    <div className="card ">
                      <div className="card-body">
                        <div className="form-sample">
                          <p className="card-description">
                            {" "}
                            Authorize Person Details
                          </p>

                          <div className="row">
                            <div className="col-md-6">
                              {renderInput(
                                "auth_name",
                                'Name <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-6">
                              {renderInput(
                                "designation",
                                'Designation <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-6">
                              {renderFile(
                                "auth_profile_pic",
                                'Profile Image <span class="text-danger">*</span>',
                                "file"
                              )}
                            </div>
                            <div className="col-md-6">
                              {renderFile(
                                "auth_kyc",
                                'Upload KYC <span class="text-danger">*</span>',
                                "file"
                              )}
                            </div>
                            <div className="col-md-6">
                              {renderInput(
                                "auth_email",
                                'Email <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-6">
                              {renderInput(
                                "auth_mobile",
                                'Phone <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderSelect(
                                "country_name",
                                'Country Name <span class="text-danger">*</span>',
                                countries
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderSelect(
                                "state_name",
                                'State Name <span class="text-danger">*</span>',
                                states
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderInput(
                                "city_name",
                                'City Name <span class="text-danger">*</span>'
                              )}
                            </div>

                            <div className="col-md-9">
                              {renderInput(
                                "address",
                                'Address <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-3">
                              {renderInput(
                                "pincode",
                                'Pincode <span class="text-danger">*</span>'
                              )}
                            </div>
                          </div>

                          <p>&nbsp;</p>
                        </div>
                        <div
                          className="row "
                          style={{
                            width: "271px",
                            margin: "21px auto",
                            display:
                              params.id === "new" ||
                              (user && user.department_name === "Admin")
                                ? "block"
                                : "none",
                          }}
                        >
                          {renderButton("Save")}
                          <button
                            type="button"
                            onClick={handlePrev}
                            className="btn btn-cnc"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorizedForm;
