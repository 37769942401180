import React, { useState, ChangeEvent } from "react";
import { convertExcelToArray } from "../../utils/convertExcelToArray";
import { CSVLink } from "react-csv";
import convertFilesToBase64 from "../../utils/convertFilesToBase64";
import handleMultipleUpload from "../../utils/handleMultipleUpload";

type Props = {
  detailsFields: any[];
  setDetailsFields: React.Dispatch<React.SetStateAction<any>>;
};

function BulkModal({ detailsFields, setDetailsFields }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [image, setImage] = useState<Record<string, any>[]>([]);
  const [imageError, setImageError] = useState<string>("");

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  // Handle Image Upload

  const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      let uploaded = handleMultipleUpload(files, detailsFields.length);

      if (uploaded[1].status) {
        let imageFiles = uploaded[0];
        try {
          let result = await convertFilesToBase64(imageFiles);

          const updated = imageFiles.map((item, index) => ({
            name: item.name,
            pic: result[index],
          }));
          setImage(updated);
          setImageError("");
        } catch (error) {
          throw error;
        }
      } else {
        setImageError(
          `You can only add a maximum of ${detailsFields.length} files`
        );
      }
    }
  };

  //  Handle Upload

  const handleExcelUpload = () => {
    // setDetailsFields(array);
    if (image.length === 0) {
      setImageError("Bulk upload file is required");
    } else {
      let detailsImagefinal = detailsFields.map((detail) => {
        const img = image.find((img) => img.name === detail.article_image_name);
        if (img) {
          return {
            ...detail,
            article_pic: [img],
          };
        }
        return detail;
      });
      setDetailsFields(detailsImagefinal);
      setOpen(!open);
    }
  };

  return (
    <>
      <span className="add-btns" onClick={handleOpen}>
        Bulk Upload
      </span>
      <div
        className={open ? "modal fade show" : "modal fade"}
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Bulk Upload
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="page-header">
                <h3 className="page-title">
                  <input
                    className="form-control"
                    type="file"
                    accept=".jpeg, .jpg, .png"
                    multiple
                    onChange={handleImageUpload}
                  />
                </h3>
                <span className="add-btns">Upload Bulk Image</span>
              </div>
              <div className="page-header">
                {imageError && <p className="text-danger">{imageError}</p>}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="button"
                onClick={handleExcelUpload}
                className="btn btn-primary"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={open ? "modal-backdrop " : ""}
        style={{ display: open ? "block" : "none" }}
      ></div>
    </>
  );
}

export default BulkModal;
