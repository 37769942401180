import http from "./httpService";
import { apiUrl } from "../config.js";

// Get the Invoice data

export async function getInvoices(invoice: {
  [key: string]: any;
}): Promise<any> {
  try {
    return await http.post(apiUrl + "/invoice_list", {
      employee_id: invoice.employee_id,
      branch_id: invoice.branch_id,
      // customer_id: invoice.customer_id,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving lots data:", error);
    // throw error;
  }
}

export async function getInvoice(
  lots_number: string,
  type: string
): Promise<any> {
  try {
    return await http.post(apiUrl + "/view_invoice", {
      lots_number,
      type,
    });
  } catch (error) {
    // Handle error
    console.error("Error retrieving invoice data:", error);
    // throw error;
  }
}
