import React, { useState } from "react";
import Header from "./Header";
import List from "./List";
import "./Laboratory.css";
type Props = {};

function Laboratory({}: Props) {
  return (
    <>
      <div className="content-wrapper">
        <Header />
        <List />
      </div>
    </>
  );
}

export default Laboratory;
