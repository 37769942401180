import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

type Props = { department: string | null };

function Home({ department }: Props) {
  const navigate = useNavigate();
  useEffect(() => {
    if (department) {
      if (department === "Admin") {
        navigate("/admin_login", { replace: true });
      } else {
        navigate("/employee_login", { replace: true });
      }
    }
  }, [department, navigate]);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <h1>Welcome you on IDT GEMOLOGICAL LABORATORIES WORLDWIDE</h1>
      </div>
    </>
  );
}

export default Home;
