import { HiOutlineClipboardList } from "react-icons/hi";
import { SiNintendogamecube, SiWebpack } from "react-icons/si";

export const MenuItem = [
  {
    icon: <SiWebpack className="menu-icon" size={25} />,
    title: "Reports",
    submenuItems: [
      { to: "lot/all", text: "All Lots" },
      { to: "invoices/all", text: "Invoices" },
    ],
  },
  {
    icon: <SiNintendogamecube className="menu-icon" size={25} />,
    title: "Article",
  },
  {
    icon: <HiOutlineClipboardList className="menu-icon" size={25} />,
    title: "Reports",
  },
];
