import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../../../../common/form";
import "./ServiceForm.css";
import { ToastContent, toast } from "react-toastify";
import { ServiceTypeProps } from "../../../../types/serviceTypeProps";
import {
  getServiceType,
  saveServiceType,
} from "../../../../services/serviceTypeService";

type Props = {
  id: string;
  showSideNav: boolean;
  setShowSideNav: (show: boolean) => void;
};

interface Errors {
  [key: string]: string | undefined;
}

function ServiceForm({ id, showSideNav, setShowSideNav }: Props) {
  const initialData: ServiceTypeProps = {
    id: "",
    price: "",
    title: "",
    created_date: "",
    status: "",
    updated_date: "",
  };
  const [data, setData] = useState<ServiceTypeProps>(initialData);
  const [errors, setErrors] = useState<Errors>({});

  const PopulateData = async (id: string) => {
    try {
      const dataId = id;
      if (dataId === "new") {
        let resultedData: ServiceTypeProps = Object.keys(data).reduce(
          (obj, key) => {
            obj[key as keyof ServiceTypeProps] = "";
            return obj;
          },
          {} as ServiceTypeProps
        );
        setData(resultedData);
      } else {
        const response = await getServiceType(dataId);
        setData(mapToViewModel(response.data));
      }
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  const mapToViewModel = ({ ...rest }: any): any => ({ ...rest });

  useEffect(() => {
    if (id) {
      PopulateData(id);
    }
  }, [id]);

  const doSubmit = async (): Promise<void> => {
    // Call the server
    console.log("Submitted", data);
    try {
      const response = await saveServiceType(data);
      if (response) {
        let resultedData: ServiceTypeProps = Object.keys(data).reduce(
          (obj, key) => {
            obj[key as keyof ServiceTypeProps] = "";
            return obj;
          },
          {} as ServiceTypeProps
        );
        setData(resultedData);
        toast.success(response.data.message);
        setShowSideNav(!showSideNav);
      }
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    id: Joi.optional().allow(""),
    created_date: Joi.optional().allow(""),
    title: Joi.string().required().label("Title"),
    price: Joi.string().required().label("Price"),
    status: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
  };

  const { renderInput, renderButton, handleSubmit } = Form<ServiceTypeProps>({
    data,
    setData,
    errors,
    setErrors,
    schema,
    onSubmit: doSubmit,
  });

  useEffect(() => {
    const buttonElement = document.querySelector(".forms-sample .btn");
    if (buttonElement) {
      buttonElement.classList.add("btn-gradient-primary");
    }
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit} className="forms-sample">
        {renderInput("title", 'Title <span class="text-danger">*</span>')}
        {renderInput("price", 'Price <span class="text-danger">*</span>')}

        {renderButton("Save")}
        <p>&nbsp;</p>
      </form>
    </>
  );
}

export default ServiceForm;
