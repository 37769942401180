import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Joi from "joi-browser";
import "./CustomerForm.css";
import { ToastContent, toast } from "react-toastify";
import { CustomerProps } from "../../types/customerProps";
import { CountryProps } from "../../types/countryProps";
import { StateProps } from "../../types/stateProps";
import { getStates } from "../../services/stateService";
import { getCountries } from "../../services/countryService";
import Form from "../../common/form";
import { getCustomer, saveCustomer } from "../../services/customerService";
import auth from "../../services/authServices";
import findItemByProperty from "../../utils/findItem";
import { getBranches } from "../../services/branchService";
import Header from "./Header";
import { UserState } from "../../types/userTypes";
import { useSelector } from "react-redux";

type Props = {};

interface Errors {
  [key: string]: string | undefined;
}

type ArrayProps = {
  id: number;
  name: string;
};

const CustomerTypeArray: ArrayProps[] = [
  {
    id: 0,
    name: "Jeweller",
  },
  {
    id: 1,
    name: "Manufacture",
  },
  {
    id: 1,
    name: "Whole Seller",
  },
];

const DealerTypeArray: ArrayProps[] = [
  {
    id: 0,
    name: "B2B",
  },
  {
    id: 1,
    name: "B2C",
  },
  {
    id: 1,
    name: "Co Brands",
  },
];

const CustomerForm = ({}: Props) => {
  const initialData: CustomerProps = {
    id: "",
    branch_id: "",
    branch_name: "",
    employee_id: "",
    employee_name: "",
    customer_type: "",
    customer_address: "",
    name: "",
    email: "",
    mobile: "",
    dealer_type: "",
    contact_person: "",
    country_name: "",
    state_name: "",
    city_name: "",
    pincode: "",
    address: "",
    gst_no: "",
    status: "",
    auth_name: "",
    auth_email: "",
    auth_mobile: "",
    auth_profile_pic: "",
    auth_kyc: "",
    designation: "",
    created_date: "",
    updated_date: "",
  };
  const [data, setData] = useState<CustomerProps>(initialData);
  const [errors, setErrors] = useState<Errors>({});
  const [base64Image, setBase64Image] = useState<string>("");
  const [countries, setCountries] = useState<CountryProps[]>([]);
  const [states, setStates] = useState<StateProps[]>([]);
  const user = useSelector((state: UserState) => state.userReducer);
  const branch = useSelector((state: any) => state.branchReducer);

  const location = useLocation();
  const params = useParams();

  const fetchCountry = async () => {
    try {
      const response = await getCountries();
      const newCountries = response.data.country_list.map(
        ({
          country_name: name,
          ...rest
        }: {
          country_name: string;
          [key: string]: any;
        }) => ({
          name,
          ...rest,
        })
      );
      let activeCountries = newCountries.filter(
        (item: any) => item.status === "Active"
      );
      setCountries(activeCountries);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  const fetchStates = async (country_name: string) => {
    try {
      const response = await getStates();
      const newStates = response.data.state_list.map(
        ({
          state_name: name,
          ...rest
        }: {
          state_name: string;
          [key: string]: any;
        }) => ({
          name,
          ...rest,
        })
      );
      const filtered = newStates.filter(
        (item: StateProps) => item.country_name === country_name
      );

      let activeStates = filtered.filter(
        (item: any) => item.status === "Active"
      );
      setStates(activeStates);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  const PopulateData = async (id: string) => {
    try {
      const dataId = id;
      if (dataId === "new") {
        let emptyData: CustomerProps = Object.keys(data).reduce((obj, key) => {
          obj[key as keyof CustomerProps] = "";
          return obj;
        }, {} as CustomerProps);

        let redirecData = {
          employee_id: user?.department_name === "Admin" ? "" : user?.id || "",
          branch_name: user?.department_name === "Admin" ? "" : branch?.name,
          employee_name: user?.department_name === "Admin" ? "" : user?.name,
        };
        let resultedData = { ...emptyData, ...redirecData };
        setData(resultedData);
      } else {
        const response = await getCustomer(dataId);
        if (response) {
          setData({
            ...data,
            ...response.data,
          });
        }
      }
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    if (user) {
      if (params.id) {
        PopulateData(params.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [params.id, user]);

  const handlePrev = () => {
    // Programmatically navigate to a different route
    const lastSlashIndex = location.pathname.lastIndexOf("/");
    const newPathname = location.pathname.substring(0, lastSlashIndex + 1);

    let redirectUrl = (process.env.REACT_APP_liveURL + newPathname) as string;
    if (process.env.NODE_ENV === "development") {
      redirectUrl = (process.env.REACT_APP_localURL + newPathname) as string;
    }
    window.location.href = redirectUrl; // No need for type assertion
  };

  const doSubmit = async (): Promise<void> => {
    // Call the server
    console.log("Submitted", data);
    try {
      // get Country Data
      const country = await getCountries();
      const foundCountry = findItemByProperty(
        country.data.country_list,
        "country_name",
        data.country_name
      );
      const countryData = {
        country_id: foundCountry?.id,
        country_name: foundCountry?.country_name,
      };

      // Get State Data
      const state = await getStates();
      const foundState = findItemByProperty(
        state.data.state_list,
        "state_name",
        data.state_name
      );
      const stateData = {
        state_id: foundState?.id,
        state_name: foundState?.state_name,
      };

      const branches = await getBranches();
      const foundBranch = findItemByProperty(
        branches.data.branch_list,
        "branch_name",
        data.branch_name
      );
      const branchData = {
        branch_id: branch?.id,
        branch_name: branch?.name,
        contact_person: foundBranch?.contact_name || "",
      };

      let resultedData = {
        ...data,
        ...countryData,
        ...stateData,
        ...branchData,
      };

      const response = await saveCustomer(resultedData);
      toast.success(response.data.message);
      // Employee redirecting to add lot to customer
      if (params.id === "new") {
        let redirectUrl = `${process.env.REACT_APP_liveURL}/${
          user.department_name === "Admin" ? "admin_panel" : "frontdesk_panel"
        }/lot/all/new`;
        if (process.env.NODE_ENV === "development") {
          redirectUrl = `${process.env.REACT_APP_localURL}/${
            user.department_name === "Admin" ? "admin_panel" : "frontdesk_panel"
          }/lot/all/new`;
        }
        window.location.href = redirectUrl; // No need for type assertion
      } else {
        handlePrev();
      }
    } catch (ex: any) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const schema = {
    id: Joi.optional().allow(""),
    branch_id: Joi.optional().allow(""),
    branch_name: Joi.optional().allow(""),
    employee_id: Joi.optional().allow(""),
    employee_name: Joi.optional().allow(""),
    customer_type: Joi.string().required().label("Customer Type"),
    customer_address: Joi.string().required().label("Customer Address"),
    name: Joi.string().required().label("Name"),
    email: Joi.string().required().label("Email"),
    mobile: Joi.string().required().label("Mobile"),
    dealer_type: Joi.string().required().label("Dealer Type"),
    contact_person: Joi.optional().allow(""),
    country_name: Joi.string().required().label("Country Name"),
    country_id: Joi.optional().allow(""),
    state_name: Joi.string().required().label("State Name"),
    state_id: Joi.optional().allow(""),
    city_name: Joi.string().required().label("City Name"),
    pincode: Joi.string().required().label("Pincode"),
    address: Joi.string().required().label("Address"),
    gst_no: Joi.optional().allow(""),
    auth_name: Joi.optional().allow(""),
    auth_email: Joi.optional().allow(""),
    auth_mobile: Joi.optional().allow(""),
    auth_kyc: Joi.optional().allow(""),
    auth_profile_pic: Joi.optional().allow(""),
    designation: Joi.optional().allow(""),
    status: Joi.optional().allow(""),
    created_date: Joi.optional().allow(""),
    updated_date: Joi.optional().allow(""),
  };

  const {
    renderInput,
    renderInputArea,
    renderSelect,
    renderButton,
    renderFile,
    handleSubmit,
  } = Form<CustomerProps>({
    data,
    setData,
    errors,
    setErrors,
    base64Image,
    setBase64Image,
    schema,
    onSubmit: doSubmit,
  });

  useEffect(() => {
    const buttonElement = document.querySelector(".form-horizontal .btn");
    if (buttonElement) {
      buttonElement.classList.add("btn-svs");
    }
  }, []);

  useEffect(() => {
    fetchCountry();
  }, []);

  useEffect(() => {
    if (data.country_name) fetchStates(data.country_name);
  }, [data.country_name]);

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper ">
          <div className="main-panel ">
            <div className="content-wrapper ">
              <Header />

              <form onSubmit={handleSubmit} className="form-horizontal">
                <div className="row ">
                  <div className="col-12 grid-margin ">
                    <div className="card ">
                      <div className="card-body">
                        <div className="form-sample">
                          <p className="card-description"> Customer Details</p>

                          <div className="row">
                            <div className="col-md-4">
                              {renderInput(
                                "name",
                                'Name <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderInput(
                                "email",
                                'Email <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderInput(
                                "mobile",
                                'Phone <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderSelect(
                                "customer_type",
                                'Customer Type <span class="text-danger">*</span>',
                                CustomerTypeArray
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderSelect(
                                "dealer_type",
                                'Dealer Type <span class="text-danger">*</span>',
                                DealerTypeArray
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderInput("gst_no", "GST No.")}
                            </div>
                            <div className="col-md-12">
                              {renderInput(
                                "customer_address",
                                'Customer Address. <span class="text-danger">*</span>'
                              )}
                            </div>
                          </div>

                          <p
                            className="card-description"
                            style={{
                              display: params.id === "new" ? "flex" : "none",
                            }}
                          >
                            {" "}
                            Authorize Person Details
                          </p>
                          <div className="row"></div>
                          <div
                            className="row"
                            style={{
                              display: params.id === "new" ? "flex" : "none",
                            }}
                          >
                            <div className="col-md-6">
                              {renderInput(
                                "auth_name",
                                'Authorize Person Name <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-6">
                              {renderInput(
                                "designation",
                                'Designation <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-6">
                              {renderFile(
                                "auth_profile_pic",
                                'Profile Image <span class="text-danger">*</span>',
                                "file"
                              )}
                            </div>
                            <div className="col-md-6">
                              {renderFile(
                                "auth_kyc",
                                'Upload KYC <span class="text-danger">*</span>',
                                "file"
                              )}
                            </div>
                            <div className="col-md-6">
                              {renderInput(
                                "auth_email",
                                'Authorize Person Email <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-6">
                              {renderInput(
                                "auth_mobile",
                                'Authorize Person Phone No. <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderSelect(
                                "country_name",
                                'Country Name <span class="text-danger">*</span>',
                                countries
                              )}
                            </div>

                            <div className="col-md-4">
                              {renderSelect(
                                "state_name",
                                'State Name <span class="text-danger">*</span>',
                                states
                              )}
                            </div>
                            <div className="col-md-4">
                              {renderInput(
                                "city_name",
                                'City Name <span class="text-danger">*</span>'
                              )}
                            </div>
                            <div className="col-md-9">
                              {renderInput(
                                "address",
                                'Address <span class="text-danger">*</span>'
                              )}
                            </div>

                            <div className="col-md-3">
                              {renderInput(
                                "pincode",
                                'Pincode <span class="text-danger">*</span>'
                              )}
                            </div>
                          </div>

                          <p>&nbsp;</p>
                        </div>
                        <div
                          className="row "
                          style={{
                            width: "271px",
                            margin: "21px auto",
                            display:
                              params.id === "new" ||
                              (user && user.department_name === "Admin")
                                ? "block"
                                : "none",
                          }}
                        >
                          {renderButton("Save")}

                          <button
                            type="button"
                            onClick={handlePrev}
                            className="btn btn-cnc"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerForm;
