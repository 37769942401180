import React from "react";
import { Link, useLocation } from "react-router-dom";
import Table from "../../common/table";
import Delete from "../../common/delete";
import Status from "../../common/status";
import { ServiceTypeProps } from "../../types/serviceTypeProps";

type Props = {
  services: ServiceTypeProps[];
  onDelete: (service: ServiceTypeProps) => void;
  onForm: (service: ServiceTypeProps) => void;
  onStatus: (service: ServiceTypeProps) => void;
};

function ServiceTable({ services, onDelete, onForm, onStatus }: Props) {
  const location = useLocation();
  const columns = [
    {
      path: "title",
      label: "Title",
    },
    {
      path: "price",
      label: "Price",
    },
    {
      path: "created_date",
      label: "Created Date",
    },
    {
      path: "status",
      label: "Status",
      content: (service: ServiceTypeProps) => (
        <>
          <Status status={service.status} onClick={() => onStatus(service)} />
        </>
      ),
    },
    {
      key: "Action",
      label: "Action",
      content: (service: ServiceTypeProps) => (
        <>
          <Link className="btn-edt" to="#" onClick={() => onForm(service)}>
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </Link>
          <Delete onClick={() => onDelete(service)} />
        </>
      ),
    },
  ];

  return <Table data={services} columns={columns} />;
}

export default ServiceTable;
