import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContent, toast } from "react-toastify";
import "./JobCard.css";
import { useReactToPrint } from "react-to-print";
import logo2 from "../../assets/images/logo-2.png";
import Barcode from "react-barcode";
import { LotProps } from "./../../types/lotProps";
import { getLot } from "../../services/lotService";

type Props = {};
type InvoiceDocumentRef = React.MutableRefObject<HTMLDivElement | null>;
type HandlePrintFunction = () => void;

function JobCard({}: Props) {
  const [data, setData] = useState<LotProps>();
  const params = useParams();

  const PopulateData = async (id: string) => {
    try {
      const response = await getLot(id);
      setData(response.data);
    } catch (error) {
      const errorMessage: ToastContent<unknown> =
        error as ToastContent<unknown>;
      toast.error(errorMessage);
    }
  };
  const invoiceDocument: InvoiceDocumentRef = useRef<HTMLDivElement>(null);

  const handlePrintImvoice: HandlePrintFunction = useReactToPrint({
    content: () => invoiceDocument.current,
  });

  const handlePrint = async () => {
    handlePrintImvoice();
  };

  useEffect(() => {
    if (params.lots_number) {
      PopulateData(params.lots_number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.lots_number]);

  return (
    <>
      <div className="job-card" ref={invoiceDocument}>
        <div className="container-fluid page-body-wrapper">
          <header className="head-section">
            <div className="container">
              <div className="row">
                {/* Header */}

                <div className="top-pp-header d-flex">
                  <img src={logo2} alt="" />
                  <h3>Job Card</h3>
                  <h5>
                    Date: <span>{new Date().toLocaleDateString()}</span>
                  </h5>
                </div>
                <div className="job-barcode">
                  <Barcode value={String(params.lots_number)} />
                </div>

                <div className="top-pp-middle d-flex">
                  <h4>Lot Number: {params.lots_number}</h4>
                </div>

                <div className="top-pp-bottom d-flex">
                  <div className="top-pp-bottom-left d-flex">
                    <h4>Criteria</h4>
                    <label>{data?.criteria}</label>
                    <h4>Assortment</h4>
                    <label></label>
                    <h4>Lab IN</h4>
                    <label></label>
                    <h4>Back Office</h4>
                    <label></label>
                    <h4>Grader No</h4>
                    <label></label>
                    <h4>Screening</h4>
                    <label></label>
                    <h4>Engraving</h4>
                    <label></label>
                  </div>
                  <div className="top-pp-bottom-left d-flex">
                    <h4>Jewellery</h4>
                    <label>
                      {data?.article_name.includes("Jewelry")
                        ? data?.no_of_articles
                        : ""}
                    </label>
                    <h4>Gemstone</h4>
                    <label>
                      {data?.article_name.includes("Gemstone")
                        ? data?.no_of_articles
                        : ""}
                    </label>
                    <h4>Lab OUT</h4>
                    <label></label>
                    <h4>Photography</h4>
                    <label></label>
                    <h4>Cuttiing</h4>
                    <label></label>
                    <h4>Billing</h4>
                    <label></label>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-outline-primary"
                    onClick={handlePrint}
                  >
                    idtworldwide.com
                  </button>
                </div>

                {/* Customer Info */}
              </div>
            </div>
          </header>
        </div>
      </div>
    </>
  );
}

export default JobCard;
