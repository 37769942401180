import React from "react";
import { Link, useLocation } from "react-router-dom";
import Table from "../../common/table";
import Delete from "../../common/delete";
import Status from "../../common/status";
import { ArticleSubTypeProps } from "../../types/articleSubTypeProps";

type Props = {
  articleSubtypes: ArticleSubTypeProps[];
  onDelete: (article_subtype: ArticleSubTypeProps) => void;
  onForm: (article_subtype: ArticleSubTypeProps) => void;
  onStatus: (article_subtype: ArticleSubTypeProps) => void;
};

function ArticleSubtypeTable({
  articleSubtypes,
  onDelete,
  onForm,
  onStatus,
}: Props) {
  const location = useLocation();
  const columns = [
    {
      path: "article_name",
      label: "Article Name",
    },
    {
      path: "article_subtype_name",
      label: "Article Subtype Name",
    },
    {
      path: "created_date",
      label: "Created Date",
    },
    {
      path: "status",
      label: "Status",
      content: (article_subtype: ArticleSubTypeProps) => (
        <>
          <Status
            status={article_subtype.status}
            onClick={() => onStatus(article_subtype)}
          />
        </>
      ),
    },
    {
      key: "Action",
      label: "Action",
      content: (article_subtype: ArticleSubTypeProps) => (
        <>
          <Link
            className="btn-edt"
            to="#"
            onClick={() => onForm(article_subtype)}
          >
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </Link>
          <Delete onClick={() => onDelete(article_subtype)} />
        </>
      ),
    },
  ];

  return <Table data={articleSubtypes} columns={columns} />;
}

export default ArticleSubtypeTable;
